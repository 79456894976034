<template>
  <div class="container container--forums">
    <div class="f group-article">
      <div class="group-article__wrapper">
        <h6 class="semibold gray-1-text">Bộ lọc</h6>
        <h6>Ngày khởi tạo bài viết: Mới nhất</h6>
        <h4 class="group-article__heading">Danh sách chuyên mục bài viết</h4>
        <table>
          <tr>
            <th>Tiêu đề bài viết</th>
            <th>Điểm đánh giá</th>
            <th>Lượt xem</th>
            <th>Lượt phản hồi</th>
          </tr>
          <tr
            v-for="item in groupArticle"
            :key="item.id"
          >
            <td>
              <router-link :to="{name: 'DetailArticle', params: {url: item.slug}}">
                <img class="group-article__image" :src="item.thumbnail_url" alt="image">
              </router-link>
              <router-link :to="{name: 'DetailArticle', params: {url: item.slug}}">
                <h5>{{item.title_post}}</h5>
              </router-link>
              <p class="h6">Bài viết: 1231231241</p>
            </td>
            <td>*****</td>
            <td>10 Luot</td>
            <td>10 Luot</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '../../http-default'
const ENDPOINT_CATEGORY_POST = 'v1/api/categorypost/'
const ENDPOINT_LIST_POST = 'v1/api/post/'

export default {
  name: 'GroupArticle',
  data () {
    return {
      categoryId: null,
      groupArticle: []
    }
  },
  computed: {
    handleCategoryId () {
      return this.$router.currentRoute.params.id
    }
  },
  methods: {
    getCategoryPost () {
      HTTP.get(ENDPOINT_CATEGORY_POST)
      .then((res) => {
        console.log('res', res);
        this.groupArticle = res.data.results
      })
    },
    getPosts () {
      HTTP.get(ENDPOINT_LIST_POST)
      .then((res) => {
        console.log('res...', res);
        this.groupArticle = res.data.results.filter(post => post.category.includes(this.handleCategoryId))
      })
    }
  },
  async created () {
    this.handleCategoryId
    this.categoryId = this.$router.currentRoute.params.id
    console.log(this.$router.currentRoute.params.id);
    this.getPosts()
  }
}
</script>

<style scoped lang="scss">
@import 'GroupArticle.scss';
</style>
