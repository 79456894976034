@<template>
    <div class="tabbar__container f jcc aic">
        <div class="tabbar f aic px1 border-radius bg-white-1 shadow z10">
            <div class="p6">
                User đã chọn <span class="p5 brown">{{dataSelect.length}}</span>
            </div>
            <div 
                @click="deleteItem"
                class="tabbar__item f fdc aic jcc cursor">
                <div>
                    <svg width="29" height="32" viewBox="0 0 29 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.9394 26H18.4394C18.8144 26 19.1894 25.6875 19.1894 25.25V11.75C19.1894 11.375 18.8144 11 18.4394 11H16.9394C16.5019 11 16.1894 11.375 16.1894 11.75V25.25C16.1894 25.6875 16.5019 26 16.9394 26ZM27.1894 5H22.0019L19.8769 1.5C19.3769 0.625 18.3769 0 17.3144 0H11.0019C9.93945 0 8.93945 0.625 8.43945 1.5L6.31445 5H1.18945C0.626947 5 0.189447 5.5 0.189447 6V7C0.189447 7.5625 0.626947 8 1.18945 8H2.18945V29C2.18945 30.6875 3.50195 32 5.18945 32H23.1894C24.8144 32 26.1894 30.6875 26.1894 29V8H27.1894C27.6894 8 28.1894 7.5625 28.1894 7V6C28.1894 5.5 27.6894 5 27.1894 5ZM10.8769 3.1875C10.9394 3.125 11.0644 3 11.1894 3C11.1894 3 11.1894 3 11.2519 3H17.1269C17.2519 3 17.3769 3.125 17.4394 3.1875L18.5019 5H9.81445L10.8769 3.1875ZM23.1894 29H5.18945V8H23.1894V29ZM9.93945 26H11.4394C11.8144 26 12.1894 25.6875 12.1894 25.25V11.75C12.1894 11.375 11.8144 11 11.4394 11H9.93945C9.50195 11 9.18945 11.375 9.18945 11.75V25.25C9.18945 25.6875 9.50195 26 9.93945 26Z" fill="#6F382B"/>
                    </svg>
                </div>
                <div class="p4 gray-1-text mt025">
                    Xóa
                </div>
            </div>
        </div>

        <NotiView 
            v-if="notiView"
            :title="title"
            :logo="logo"
            @closePopup='closeNoti'/>
    </div>
</template>

<script>
import NotiView from '@/components/NotiView/NotiView.vue'

import { HTTP, getToken } from '@/http-default'
export default {
    components : {
        NotiView
    },

    props : {
        dataSelect : {
            type : Array,
            default () {
                return []
            }
        }
    },

    data () {
        return {
            notiView : false,
            title : '',
            logo : '',
        }
    },

    methods : {
        async deleteItem () {
            const user = getToken();
            var config = {
                headers: {
                "Content-Type": "application/json",
                Authorization: "Token " + user,
                },
            };
            this.dataSelect.forEach(item => {
                HTTP.delete('v1/api/user/' + item.id + '/', config)
            })

            this.title = 'Xoá người dùng thành công'
            this.logo = 'noti-success'
            this.notiView = true
        },

        closeNoti () {
            this.notiView = false
            this.$emit('getData')
        },
    }
}
</script>

<style scoped lang='scss'>
    @import 'TabBarView.scss';
</style>