<template>
  <div class="tree-category__container f fdc gap1">
    <Expanded
      v-for="(item, index) in dataTree"
      :key="index"
      :defaultOpen="(item.children && !!item.children.length) || (item.listPost && !!item.listPost.length)"
      style="user-select: none"
    >
      <template #title>
        <div class="f aic pt075 pb075 pl1 pr1 border border-radius bg-white-1">
          <svg width="16" height="12" viewBox="0 0 16 12">
            <use
              xlink="http://www.w3.org/2000/svg"
              href="#folderBrown"
              x="0"
              y="0"
            ></use>
          </svg>
          <router-link
            :to="{
              name: 'detail-category',
              params: { id: item.id, type: 'category' },
            }"
            class="ml05 h7 item-title"
          >
            <!-- {{ item.category_name }} -->
            <p class="h5">{{ item.category_name }}</p>
          </router-link>
        </div>
      </template>

      <template #subfixIcon>
        <div class="f aic gap1">
          <div class="cursor icon-file" @click.stop="addPost(item.id)">
            <img src="@/assets/icons/add-file.svg" alt="Thêm bài viết" style="width: 20px; aspect-ratio: 1;"/>
          </div>
          <div class="cursor icon-file" @click.stop="addCategoryPost(item.id)">
            <img src="@/assets/icons/add-folder.svg" alt="Thêm danh mục" style="width: 20px; aspect-ratio: 1;"/>
          </div>
        </div>
      </template>

      <div class="pv1 pl2 f fdc gap05" style="border-left: 0.5px solid #6F382B;">
        <template v-if="item.children && item.children.length">
          <TreeCategory :dataTree="item.children"/>
        </template>
        <template v-if="subDataPost">
          <div class="f gap05">
            <img src="@/assets/icons/file.svg" style="width: 8px; aspect-ratio: 1;"/>
            <router-link :to="{ name: 'body-detail', params: { id: subDataPost.id } }" class="item-title brown">{{ subDataPost.title_post }} <span class="gray-1-text italic p6">({{ convertTime(subDataPost.created_at) }})</span></router-link>
          </div>
        </template>
        <template v-if="item.listPost && item.listPost.length">
          <div class="f fdc gap05">
            <div v-for="(post, postIndex) in item.listPost" :key="postIndex" class="f gap05">
              <img src="@/assets/icons/file.svg" style="width: 8px; aspect-ratio: 1;"/>
              <router-link :to="{ name: 'body-detail', params: { id: post.id } }" class="item-title brown">{{ post.title_post }} <span class="gray-1-text italic p6">({{ convertTime(post.created_at) }})</span></router-link>
            </div>
          </div>
        </template>
        <template v-else>
          <p class="gray-text-1 h7 italic">Danh mục rỗng</p>
        </template>
      </div>
    </Expanded>

    <Popup :isShow="isAddNewPost" @close="closePopup">
      <AddNewPost
        class="mv05"
        :dataInput="dataAddNewPost"
        :isUserCreate="true"
        :isMobile="isMobile"
        @createSuccess="onCreateSuccess"
      />
    </Popup>
  </div>
</template>

<script>
import Expanded from "@/components/Expanded/Expanded.vue";
import AddNewPost from "@/components/AddNew/AddNewPost/AddNewPost.vue";
import Popup from "@/components/Popup/index.vue";

export default {
  name: "TreeCategory",

  components: {
    Expanded,
    AddNewPost,
    Popup,
  },

  props: {
    dataTree: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isAddNewPost: false,
      dataAddNewPost: {
        id: "",
        title_post: "",
        slug: "",
        permalink: "",
        time_published: "",
        content: "",
        user_created: "",
        short_description: "",
        thumbnail: "",
        thumbnail_url: "",
        status_show_post: "",
        status_post: "",
        total_click: "",
        total_share: "",
        title_seo: "",
        description_seo: "",
        keyword: "",
        media: "",
        category: "",
        tag: "",
        system_show: "",
        avatar: [],
      },

      subDataPost: null,
    }
  },

  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    addCategoryPost(category_parent) {
      this.$router.replace({query: {category_post: category_parent}})
      var popup = document.getElementsByClassName("pop-up-add-folder")[0];
      popup.classList.add("popup-active");
    },

    addPost(category_parent) {
      this.$router.replace({query: {category_post: category_parent}})
      this.isAddNewPost = true
    },

    onCreateSuccess({data}) {
      console.log(data);
      this.subDataPost = data
      this.closePopup()
    },

    closePopup() {
      this.isAddNewPost = false
      this.$router.replace({query: {}})
    },

    convertTime(value) {
      var time = value.split("T");
      return time[0];
    },
  }
};
</script>

<style scoped lang="scss">
@import "AllCategory.scss";
</style>