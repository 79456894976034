@<template>
    <div class="detail-cart__container">
        <div class="detail-cart__confirm">
            <div class="detail-cart__info">
                <div class="detail-cart__product mb5">
                    <div class="detail-cart__product-title aic p1">
                        <input 
                            @change="handleProductSelectAll"
                            id="checkbox-all"
                            type="checkbox">
                        <div class="p6">Sản phẩm</div>
                        <div class="p6 align-c">Số lượng</div>
                        <div class="p6 align-c">Đơn giá</div>
                        <div class="p6 align-c">Thành tiền</div>
                    </div>
                    <div class="detail-cart__product-list f fdc">
                        <div
                            v-for="(item, index) in productCart"
                            :key="index">
                            <div class="detail-cart__product-item p1 border-radius aic bg-gray-2">
                                <input 
                                    @change="handleProductSelect(item)"
                                    :id="'checkbox-' + item.id"
                                    type="checkbox">
                                <div class="p6 f aic">
                                    <img :src="item.media[0].file_media" alt="">
                                    <div class="ml05">
                                        <div class="p4 blue">{{item.title_product}}</div>
                                    </div>
                                </div>
                                <div class="p6 align-c">
                                    <input 
                                        class="w50 align-c"
                                        type="number"
                                        v-model="item.quantity">
                                </div>
                                <div class="p6 align-c">
                                    <div class="h6 gray-1-text mb025">{{converMoney(item.price_sale)}}</div>
                                    <div class="h6 gray-1-text text-line-through">{{converMoney(item.price_product)}}</div>
                                </div>
                                <div class="p6 align-c">
                                    {{totalProduct(item)}}
                                </div>
                                <div 
                                    @click="deleteProduct(item)"
                                    class="f jce aic cursor">
                                    <div class="icon--close"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="detail-cart__info-order">
                    <div class="info-order__header mb1">
                        <div class="h2">Thông tin nhận hàng</div>
                    </div>
                    <div class="info-order__body">

                        <!-- Họ tên số điện thoại -->
                        <div class="f jcb ais mb1">
                            <div class="f fdc w50">
                                <label class="h7 mb025" for="">Họ và tên <span class="red">*</span></label>
                                <input
                                    v-if="!dataErr.full_name"
                                    class="w100 mt025 pt075 pb075 pl075 pr075" 
                                    type="text" 
                                    placeholder="Nhập vào đây"
                                    v-model="dataInput.full_name">
                                <div 
                                    class="w100"
                                    v-else-if="dataErr.full_name">
                                    <input
                                    class="w100 mt025 border-error pt075 pb075 pl075 pr075" 
                                    type="text" 
                                    placeholder="Nhập vào đây"
                                    v-model="dataInput.full_name">
                                    <div class="h7 mt025 red">Bạn cần hoàn thiện thông tin này</div>
                                </div>
                            </div>
                            <div class="f fdc w50 ml1">
                                <label class="h7 mb025" for="">Số điện thoại khách hàng <span class="red">*</span></label>
                                <input  
                                    v-if="!dataErr.phone"
                                    class="pt075 pb075 pr075 pl075" 
                                    type="text" 
                                    placeholder="Nhập vào đây"
                                    v-model="dataInput.phone">
                                <div 
                                    class="w100"
                                    v-else-if="dataErr.phone === true">
                                    <input
                                    class="w100 mt025 border-error pt075 pb075 pl075 pr075" 
                                    type="text" 
                                    placeholder="Nhập vào đây"
                                    v-model="dataInput.phone">
                                    <div class="h7 mt025 red">Bạn cần hoàn thiện thông tin này</div>
                                </div>
                                <div 
                                    class="w100"
                                    v-else-if="dataErr.phone === 'error format'">
                                    <input
                                    class="w100 mt025 border-error pt075 pb075 pl075 pr075" 
                                    type="text" 
                                    placeholder="Nhập vào đây"
                                    v-model="dataInput.phone">
                                    <div class="h7 mt025 red">Bạn cần điền đúng định dạng số điện thoại</div>
                                </div>
                            </div>
                        </div>

                        <!-- Địa chỉ và email -->
                        <div class="f jcb ais mb1">
                            <div class="info-address w50">
                                <label class="h7 mb025">Địa chỉ nhận hàng <span class="red">*</span></label>
                                <input 
                                    v-if="!dataErr.address"
                                    class=" mt025 w100 pt075 pb075 pr075 pl075" 
                                    type="text" 
                                    placeholder="Nhập địa chỉ"
                                    v-model="dataInput.address">
                                <div 
                                    class="w100"
                                    v-else-if="dataErr.address">
                                    <input
                                    class="w100 border-error pt075 pb075 pl075 pr075" 
                                    type="text" 
                                    placeholder="Nhập vào đây"
                                    v-model="dataInput.address">
                                    <div class="h7 mt025 red">Bạn cần điền đúng định dạng số điện thoại</div>
                                </div>
                            </div>
                            <div class="f fdc w50 ml1">
                                <label class="h7 mb025" for="">Email khách hàng</label>
                                <input 
                                    class="pt075 pb075 pr075 pl075" 
                                    type="text" 
                                    placeholder="Nhập vào đây"
                                    v-model="dataInput.email">
                            </div>
                        </div>

                        <!-- ghi chú -->
                        <div class="f fdc ais">
                            <label class="h7 mb025">Ghi chú</label>
                            <textarea 
                                v-model="dataInput.note"
                                class="border-radius p6 w100 p1"
                                placeholder="VD: Giao hàng giờ hành chính"
                                name="" id="" cols="30" rows="5"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail-cart__payment p1 border-radius border">
                <div class="detail-payment__header mb1">
                    <div class="p5">Phương thức thanh toán</div>
                </div>
                <div class="detail-payment__type-ship f fdc pb1 mb1 border-bottom">
                    <div
                        v-for="(item, index) in typeShip"
                        :key="index"
                        class="f jcb aic">
                        <div
                            class="f aic"
                            v-if="!item.update">
                            <input
                                type="radio">
                            <div class="h6 ml05">{{item.title}}</div>
                        </div>
                        <div
                            class="f aic"
                            v-if="item.update">
                            <input type="radio">
                            <div class="h6 ml05 gray-1-text">{{item.title}}</div>
                        </div>
                        <div
                            class="h6 gray-1-text"
                            v-if="item.update">
                            Đang cập nhật
                        </div>
                    </div>
                </div>

                <div class="detail-payment__total pb1 mb1 border-bottom">
                    <div class="f jcb aic mb1">
                        <div class="h5">Tổng giỏ hàng</div>
                        <div class="h5 orange">{{productSelect.length}} Sản phẩm</div>
                    </div>
                    <div class="f jcb aic mb05">
                        <div class="h7">Tổng tiền hàng</div>
                        <div class="p4">{{totalPriceOrder}}</div>
                    </div>
                    <div class="f jcb aic mb05">
                        <div class="h7">Giảm giá</div>
                        <div class="p4 gray-1-text text-line-through">{{totalDiscount}}</div>
                    </div>
                    <div class="f jcb aic mb05">
                        <div class="h7">Phí vận chuyển</div>
                        <div class="p4">35.000Đ</div>
                    </div>
                </div>

                <div>
                    <div class="f jcb aic mb1">
                        <div class="h5">Tổng đơn hàng</div>
                        <div class="h5 orange">{{totalMoneyOrder}}</div>
                    </div>
                </div>

                <div 
                    v-if="productSelect.length === 0"
                    class="f jcc aic pt075 pb075 border-radius bg-brown-1 cursor">
                    <div class="h5 white">Đặt hàng</div>
                </div>

                <div 
                    v-else
                    @click="createOrder"
                    class="f jcc aic pt075 pb075 border-radius bg-brown cursor">
                    <div class="h5 white">Đặt hàng</div>
                </div>
            </div>
        </div>

        <NotiView 
            v-if="notiPoup"
            :title="'Tạo đơn hàng thành công'"
            @closePopup='closePopup'/>
    </div>
</template>

<script>
import NotiView from '@/components/NotiView/NotiView.vue'

import { HTTP } from '@/http-default'
export default {
    data () {
        return {
            myAccount : {},
            productCart : [],
            dataInput : {
                full_name: '',
                phone: '',
                address: '',
                email: '',
                note: '',
            },
            dataErr : {
                full_name: false,
                phone: false,
                address: false,
            },
            typeShip : [
                {
                    title : 'Ship COD',
                    update: false
                },
                {
                    title : 'Chuyển khoản',
                    update: true
                },
                {
                    title : 'Qua ngân hàng',
                    update: true
                }
            ],

            productSelect : [],
            notiPoup : false
        }
    },
    
    components : {
        NotiView
    },

    computed : {
        totalPriceOrder () {
            var total = 0
            this.productSelect.forEach(item => {
                total += item.price_sale*item.quantity
            })

            return this.converMoney(total)
        },

        totalDiscount () {
            var total = 0
            this.productSelect.forEach(item => {
                total += (item.price_product - item.price_sale)*item.quantity
            })

            return this.converMoney(total)
        },

        totalMoneyOrder () {
            var total = 0
            this.productSelect.forEach(item => {
                total += item.price_sale*item.quantity
            })

            total += 35000

            return this.converMoney(total)
        },
    },

    methods : {
        closePopup () {
            this.notiPoup = false
        },

        converMoney (value) {
            var money = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
            return money
        },

        totalProduct(item) {
            var total = item.price_sale*item.quantity
            return this.converMoney(total)
        },

        handleProductSelectAll () {
            var inputAll = document.getElementById('checkbox-all').checked

            this.productCart.forEach(item => {
                document.getElementById('checkbox-' + item.id).checked = inputAll

                this.handleProductSelect(item)
            })
        },

        handleProductSelect (item) {
            var input = document.getElementById('checkbox-' + item.id).checked
            
            if (input && !this.productSelect.includes(item)) {
                this.productSelect.push(item)
            } else if (!input) {
                for (let i = 0; i < this.productSelect.length; i++) {
                    if (this.productSelect[i].id === item.id) {
                        this.productSelect.splice(i, 1)
                    }
                }
            }
        },

        deleteProduct(item) {
            let data = localStorage.getItem("cartProudct")
            let cart = JSON.parse(data)

            for (let i = 0; i < cart.length; i++) {
                if (cart[i].id === item.id) {
                    cart.splice(i, 1)
                }
            }

            this.productCart = cart

            localStorage.setItem('cartProudct', JSON.stringify(cart))
            this.$emit('updateProductCart')
        },

        getData () {
            let data = localStorage.getItem("cartProudct")
            this.productCart = JSON.parse(data)

            let account = localStorage.getItem('myAccount')
            this.myAccount = JSON.parse(account)

            this.dataInput.full_name = this.myAccount.full_name
            this.dataInput.phone = this.myAccount.phone
            this.dataInput.email = this.myAccount.email
            this.dataInput.address = this.myAccount.address
        },

        handleCheckFilter() {
            for (let item in this.dataInput) {
                if (item === 'full_name' && this.dataInput[item] === '') {
                    this.dataErr[item] = true
                } else if (item === 'phone' && (this.dataInput[item] === '' || this.dataInput[item].length !== 10))  {
                    this.dataErr[item] = true
                } else if (item === 'address' && this.dataInput[item] === '') {
                    this.dataErr[item] = true
                } else {
                    this.dataErr[item] = false
                }
            }
        },

        createOrder () {
            this.handleCheckFilter()

            for (let item in this.dataErr) {
                if (this.dataErr[item]) {
                    return false
                }
            }

            var total = 0
            this.productSelect.forEach(item => {
                total += item.price_sale*item.quantity
            })

            total += 35000

            var data = JSON.stringify({
                "customer" : this.dataInput,
                "order_key" : Math.floor(Math.random() * 1000000000000),
                "status_order" : 1,
                "total_price" : total,
                "total_quantity" : this.productSelect.length,
                "user_create" : parseInt(localStorage.getItem('idUser'))
            })

            HTTP.post('v1/api/order/', data)
            .then((res) => {

                var keyAffiliate = this.$cookies.get('code_affiliate')

                console.log(keyAffiliate);

                if (keyAffiliate !== null) {
                    HTTP.get('affiliate/' + keyAffiliate + '/o/' + res.data.id + '/')
                }

                this.notiPoup = true

                this.productSelect.forEach(item => {

                    var totalPriceProduct = item.price_sale*item.quantity

                    var dataSubOrder = JSON.stringify({
                        "key_sub_order" : Math.floor(Math.random() * 1000000000000),
                        "order" : res.data.id,
                        "price_product": item.price_sale,
                        "product": item.id,
                        "quantity_order": item.quantity,
                        "title_sub_order": Math.floor(Math.random() * 1000000000000),
                        "total_price": totalPriceProduct,
                        "user_create": parseInt(localStorage.getItem('idUser'))
                    })

                    HTTP.post('v1/api/suborder/', dataSubOrder)
                })
            })
        }
    },

    created () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'DetailCart.scss';
</style>