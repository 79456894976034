@<template>
    <div class='popup popup-noti jcc aic'>
        <div class="noti__conainer f fdc aic pt15 pb15 border-radius bg-white-1">
            <div class="icon--noti-success mb1"></div>
            <div class="h2 mb05 brown">Thông báo</div>
            <div class="p6 mb15 gray">Bạn đã xóa thành công hồ sơ bệnh lý</div>
            <div class="noti__action f jcc aic w100">
                <div
                    @click="handleClose" 
                    class="w50 pt1 pb1 border-brown border-radius align-c">Đóng</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods : {
        handleClose(event) {
            var button = event.target.parentElement.parentElement.parentElement
            button.classList.remove('f')
        }
    }
}
</script>

<style scoped lang='scss'>
    @import 'PopupNoti.scss';
</style>