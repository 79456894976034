@<template>
    <div class="popup popup-feddback f jcc aic">
        <div class="history-feedback__container px1 border-radius bg-white-1">
            <div class="history-feedback__header f jcb aic mb1">
                <div class="p5 brown">
                    Lịch sử phản hồi
                </div>
                <div 
                    @click="handleClose"
                    class="icon--close cursor"></div>
            </div>
            <div class="history-feedback__select mb1">
                <div class="p4">
                    Chọn phản hồi <span class="orange">({{dataAnswer.length}})</span>
                </div>
                <div>
                    <vSelect 
                        class="mt025 h7"
                        :options="dataAnswer"
                        label="email"
                        placeholder="Chọn câu trả lời"
                        v-model="dataAnswerSelect">
                    </vSelect>
                </div>
            </div>
            <div class="history-feedback__body f fdc ais">
                <div class="p5">Danh sách câu trả lời </div>
                <div 
                    class="w100 f jcb aic">
                    <div class="p6 gray-1-text">Tên của bạn</div>
                    <div class="p5">{{dataAnswerSelect.name}}</div>
                </div>
                <div 
                    class="w100 f jcb aic">
                    <div class="p6 gray-1-text">Số điện thoại</div>
                    <div class="p5">{{dataAnswerSelect.phone}}</div>
                </div>
                <div 
                    class="w100 f jcb aic">
                    <div class="p6 gray-1-text">Email của bạn</div>
                    <div class="p5">{{dataAnswerSelect.email}}</div>
                </div>
                <div 
                    v-for="(item, index) in dataAnswerSelect.question"
                    :key="index"
                    class="w100 f jcb aic">
                    <div class="p6 gray-1-text">{{item.key}}</div>
                    <div class="p5">{{item.value}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
import vSelect from 'vue-select'

export default {
    components : {
        vSelect
    },

    data () {
        return {
            dataAnswer : [],
            dataAnswerSelect : null,
        }
    },

    methods : {
        handleClose () {
            this.$emit('closePopup')
        },

        getData () {
            HTTP.get('v1/api/answeruser/?landing=' + this.$route.params.id)
            .then((res) => {
                this.dataAnswer = res.data.results
                this.dataAnswerSelect = this.dataAnswer[0]
                this.dataAnswer.forEach(item => {
                    item.question = JSON.parse(item.question)
                })
            })
        }
    },

    mounted () {
        this.getData()
    }
}
</script>

<style scoped lang="scss">
    @import 'historyFeedback.scss';
</style>