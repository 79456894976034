@<template>
  <div class="relative f jcb aic">
    <div class="w100 my-affiliate__container">
      <div class="my-affiliate__header f jcb aic mb1">
        <div class="h3">
          Điểm thành viên
          <span class="orange">({{ dataPointLog.length }})</span>
        </div>
        <div class="f aic gap1">
          <div>
            <input v-model="timeFilterPointLog" type="month" />
          </div>
          <div
            class="pt075 pb075 f jcc aic border-radius bg-brown white cursor p5"
            style="width: 200px"
            @click="isShowPopupResetPoint = true"
          >
            Reset điểm thành viên
          </div>
        </div>
      </div>
      <div class="my-affiliate__body">
        <div class="my-affiliate__title mb1">
          <div class="p6 gray-1-text">STT</div>
          <div class="p6 gray-1-text align-c">Loại thao tác</div>
          <div class="p6 gray-1-text align-c">Điểm cộng</div>
          <div class="p6 gray-1-text align-c">Thời gian tạo</div>
          <div class="p6 gray-1-text align-c">Thông tin</div>
        </div>

        <div class="my-affiliate__list f fdc ais">
          <div v-if="!dataPointLog.length" class="pv05 h6 gray-1-text">
            Không có dữ liệu
          </div>
          <div
            v-for="(item, index) in dataPointLog"
            :key="index"
            class="w100 my-affiliate__item"
          >
            <div class="p6 gray-1-text">{{ index + 1 }}</div>
            <div class="p5 blue align-c">{{ dataType[item.type_data] }}</div>
            <div class="p5 gray-1-text align-c">+ {{ item.point }} điểm</div>
            <div class="p5 gray-1-text align-c">
              {{ convertTime(item.created_at) }}
            </div>
            <div class="p5 black align-c">
              <!-- {{ convertMoney(item.money) }} -->
              <template v-if="item.type_data === 'post'">
                <p>
                  <span class="p6">Bài viết: </span>
                  <span class="p5 gray-1-text">{{
                    item.post_id.title_post
                  }}</span>
                </p>
                <p class="blue">
                  <!-- {{ window.location.origin }} -->
                </p>
              </template>

              <template v-if="item.type_data === 'comment'">
                <p v-if="item.comment_id">
                  <span class="p6">Bình luận: </span>
                  <span class="p5 gray-1-text">{{
                    item.comment_id.content
                  }}</span>
                </p>
                <!-- <p class="blue">
									Bài viết: <span>{{ item.post_id.title_post }}</span>
								</p> -->
              </template>
              <template v-if="item.type_data === 'share'">
                <p>
                  <span class="p6">Chia sẻ bài viết: </span>
                  <span class="p5 gray-1-text">{{
                    item.post_id.title_post
                  }}</span>
                </p>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div
        @click="closePopup"
        class="w100 pt05 pb05 f jcc aic border-radius bg-brown white cursor mt1"
      >
        <div class="p5 white">Đóng</div>
      </div>
    </div>

    <!-- <div class="my-affiliate__total px1 border border-radius absolute">
      <div class="p5 brown mb1">Kết quả</div>
      <div class="f jcb aic pb05 border-bottom">
        <div class="h7 gray-1-text">Tổng điểm thành viên</div>
        <div class="h7 blue">
          {{ totalPoint }}
        </div>
      </div>
    </div> -->

    <Popup
      :isShow="isShowPopupResetPoint"
      width="25vw"
      @close="isShowPopupResetPoint = fasle"
    >
      <div class="px1 f aic fdc">
        <div :class="'icon--noti-warning'" class="mb1"></div>
        <p class="h4">Thông báo</p>
        <div style="height: 20vh" class="f aic jcc h4">
          <p class="align-c p5">
            Bạn có chắc chắn muốn xoá hết điểm thành viên của người dùng này?
          </p>
        </div>
        <div class="w100 f aic gap1">
          <div
            class="w100 pt075 pb075 f jcc aic border-radius bg-white brown cursor p5 border"
            @click="isShowPopupResetPoint = false"
          >
            Huỷ
          </div>
          <div
            class="w100 pt075 pb075 f jcc aic border-radius bg-brown white cursor p5"
            @click="handleResetPoint"
          >
            Đồng ý
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>

<script>
import { HTTP } from "@/http-default";
import Popup from "@/components/Popup/index.vue";

export default {
  components: {
    Popup,
  },

  props: {
    dataUser: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      dataPointLog: [],
      timeFilterPointLog: "",
      dataType: {
        post: "Đăng bài",
        share: "Chia sẻ",
        comment: "Bình luận",
      },

      isShowPopupResetPoint: false,
    };
  },
  watch: {
    timeFilterPointLog: function () {
      if (this.timeFilterPointLog !== "") {
        var time = this.timeFilterPointLog.split("-");
        HTTP.get(
          "v1/api/customerpointlog/?depth=1&customer=" +
            this.dataUser.id +
            "&month=" +
            time[1] +
            "&year=" +
            time[0]
        ).then((res) => {
          this.dataPointLog = res.data.results;
        });
      } else {
        HTTP.get(
          "v1/api/customerpointlog/?depth=1&customer=" + this.dataUser.id
        ).then((res) => {
          this.dataPointLog = res.data.results;
        });
      }
    },
  },

  computed: {
    totalMoney() {
      const totalMoney = this.dataPointLog.reduce((total, item) => {
        return total + item.money;
      }, 0);

      return this.convertMoney(totalMoney);
    },

    totalPoint() {
      const totalPoint = this.dataPointLog.reduce((total, item) => {
        return total + item.point;
      }, 0);

      return totalPoint;
    },
  },

  methods: {
    convertMoney(value) {
      var x = value;
      x = x.toLocaleString("it-IT", { style: "currency", currency: "VND" });
      return x;
    },

    convertTime(value) {
      var time = value.split("T");
      return time[0];
    },

    getData() {
      HTTP.get("v1/api/customerpointlog/?depth=1&customer=" + this.dataUser.id).then(
        (res) => {
          this.dataPointLog = res.data.results;
        }
      );
    },

    closePopup() {
      this.$emit("closePopup");
    },

    handleResetPoint() {
      const payload = {
        point: 0,
      };
      HTTP.patch(`v1/api/customer/${this.dataUser.id}/`, payload).then(() => {
        this.isShowPopupResetPoint = false;
        this.getData();
      });
    },
  },

  created() {
    this.getData();
  },
};
</script>

<style scoped lang='scss'>
.my-affiliate {
  gap: 1em;
  width: 50%;
}

.my-affiliate__container {
  width: 50vw;
  background: #ffffff;
  padding: 1em;
  border-radius: 0.5em;
}

.my-affiliate__title {
  display: grid;
  grid-template-columns: 5% 15% 20% 20% 20% 20%;
}

.my-affiliate__item {
  display: grid;
  grid-template-columns: 5% 15% 20% 20% 20% 20%;
}

.my-affiliate__list {
  gap: 1em;
  max-height: 30vw;
  overflow-y: scroll;
}

.my-affiliate__total {
  top: 0;
  left: calc(100% + 2em);
  background: #ffffff;
  padding: 1em;
  border-radius: 0.5em;
  width: 20vw;
}
</style>