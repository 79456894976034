@<template>
    <div class="popup popup-customerRelate f jcc aic">
        <div class="customer-relate__container pt1 pb1 pl1 pr1 border-radius bg-white-1">
            <div class="customer-relate__header f jcb aic mb1">
                <div class="p5 brown">Thông tin người liên quan</div>
                <div
                    v-if="!editCustomerRelate.key_customer"
                    @click="addCustomerSystem = true"
                    class="p4 blue cursor">Chọn từ danh sách khách hàng trên hệ thống </div>
            </div>
            <div class="customer-relate__body mb15">
                <div class="f aic mb1">
                    <div 
                        v-if="!editTypeFormUserSystem && !$route.params.menu"
                        class="w100 f fdc ais">
                        <label class="h7">Tên khách hàng<span class="red">*</span></label>
                        <input
                            v-if="!dataInputError.full_name"
                            class="w100 pt075 pb075 pl075 pr075" 
                            type="text" 
                            placeholder="Nhập vào đây"
                            v-model="dataInput.full_name">
                        <div 
                            class="w100"
                            v-if="dataInputError.full_name">
                            <input
                            class="w100 border-error pt075 pb075 pl075 pr075" 
                            type="text" 
                            placeholder="Nhập vào đây"
                            v-model="dataInput.full_name">
                            <div class="h7 mt025 red">Bạn cần hoàn thiện thông tin này</div>
                        </div>
                    </div>

                    <div 
                        v-if="editTypeFormUserSystem && !$route.params.menu"
                        class="w50 f fdc ais">
                        <label class="h7">Tên khách hàng<span class="red">*</span></label>
                        <input
                            v-if="!dataInputError.full_name"
                            class="w100 pt075 pb075 pl075 pr075" 
                            type="text" 
                            placeholder="Nhập vào đây"
                            v-model="dataInput.full_name">
                        <div 
                            class="w100"
                            v-if="dataInputError.full_name">
                            <input
                            class="w100 border-error pt075 pb075 pl075 pr075" 
                            type="text" 
                            placeholder="Nhập vào đây"
                            v-model="dataInput.full_name">
                            <div class="h7 mt025 red">Bạn cần hoàn thiện thông tin này</div>
                        </div>
                    </div> 

                    <div 
                        v-if="editTypeFormUserSystem && $route.params.menu"
                        class="w100 f fdc ais">
                        <label class="h7">Tên khách hàng<span class="red">*</span></label>
                        <input
                            v-if="!dataInputError.name"
                            class="w100 pt075 pb075 pl075 pr075" 
                            type="text" 
                            placeholder="Nhập vào đây"
                            v-model="dataInput.name">
                        <div 
                            class="w100"
                            v-if="dataInputError.name">
                            <input
                            class="w100 border-error pt075 pb075 pl075 pr075" 
                            type="text" 
                            placeholder="Nhập vào đây"
                            v-model="dataInput.name">
                            <div class="h7 mt025 red">Bạn cần hoàn thiện thông tin này</div>
                        </div>
                    </div> 

                    <div 
                        v-if="!editTypeFormUserSystem && $route.params.menu"
                        class="w100 f fdc ais">
                        <label class="h7">Tên khách hàng<span class="red">*</span></label>
                        <input
                            v-if="!dataInputError.name"
                            class="w100 pt075 pb075 pl075 pr075" 
                            type="text" 
                            placeholder="Nhập vào đây"
                            v-model="dataInput.name">
                        <div 
                            class="w100"
                            v-if="dataInputError.name">
                            <input
                            class="w100 border-error pt075 pb075 pl075 pr075" 
                            type="text" 
                            placeholder="Nhập vào đây"
                            v-model="dataInput.name">
                            <div class="h7 mt025 red">Bạn cần hoàn thiện thông tin này</div>
                        </div>
                    </div> 

                    <div
                        class="w50 ml1"
                        v-if="editTypeFormUserSystem">
                        <label class="h7">Mã user</label>
                        <div class="f jcb aic w100 pt075 pb075 pl075 pr075 border-radius border bg-gray-2">
                            <div class="p4 blue">#{{dataInput.key_customer}}</div>
                            <div class="icon--coppy cursor"></div>
                        </div>
                    </div>
                </div>
                <div class="medical__img mb1">
                    <div class="h7">Ảnh</div>
                    <div class="w100 f mt05 aic pt1 pb1 pl1 pr1 border-radius bg-gray-3" style="border: 1px dashed #D9DDE8;">
                        <div
                            @click="uploadImg" 
                            class="h7 pt05 pb05 pr1 pl1 mr1 border-radius border cursor">
                            <input 
                                class="hide"
                                type="file" 
                                accept="image/*" 
                                ref="file"
                                @change="uploadImage($event)" 
                                id="avatar-relate">
                            <i>Tải ảnh lên</i> 
                        </div>
                        <div>
                            <div class="p4">Thêm ảnh đại diện của bạn</div>
                            <div class="h6 gray">Dung lượng ảnh tối đa: 2MB</div>
                        </div>
                    </div>
                    <div id="list_avatar-relate" class="list__avatar f aic mt05">
                        <div
                            class="avatar-upload__item relative"
                            v-for="(item, index) in dataListAvatar"
                            :key="index">
                            <div 
                                @click="handleDeleteImg(item)"
                                class="avatar-upload__delete absolute jcc aic white cursor">x</div>
                            <img class="avatar-upload__img border-radius mr05" :src="item" alt="">
                        </div>
                    </div>
                </div>
                <div class="f jcb ais w100 mb1">
                    <div class="w50 relative">
                        <label class="h7">Email khách hàng</label>
                        <!-- <div class="gmail-pattern__container h7 absolute gray pt05 pb05 pl05 pr05 bg-gray-2 border-radius">@gmail.com</div> -->
                        <input
                            v-if="!dataInputError.email"
                            class="w100 mt025 pt075 pb075 pl075 pr075"  
                            type="email" 
                            pattern=".+@gmail\.com"
                            placeholder="Nhập vào đây"
                            v-model="dataInput.email">
                        <div
                            v-if="dataInputError.email === true">
                            <input
                                class="w100 mt025 border-error pt075 pb075 pl075 pr075"  
                                type="email" 
                                pattern=".+@gmail\.com"
                                placeholder="Nhập vào đây"
                                v-model="dataInput.email">
                            <div class="h7 mt025 red">Bạn cần email để tạo mới người dùng!</div>
                        </div>
                        <!-- <div
                            v-if="dataInputError.email === 'error format'">
                            <input
                                class="w100 border-error pt075 pb075 pl075 pr075"  
                                type="email" 
                                placeholder="Nhập vào đây"
                                v-model="dataInput.email">
                            <div class="h7 mt025 red">Bạn cần điền đúng định dạng Email</div>
                        </div> -->
                    </div>
                    <div class="w50 ml1"> 
                        <label class="h7">Số điện thoại <span class="red">*</span></label>
                        <input
                            v-if="!dataInputError.phone"
                            class="w100 mt025 pt075 pb075 pl075 pr075" 
                            type="number" 
                            placeholder="Nhập vào đây"
                            v-model="dataInput.phone">
                        <div 
                            class="w100"
                            v-else-if="dataInputError.phone === true">
                            <input
                            class="w100 mt025 border-error pt075 pb075 pl075 pr075" 
                            type="text" 
                            placeholder="Nhập vào đây"
                            v-model="dataInput.phone">
                            <div class="h7 mt025 red">Bạn cần hoàn thiện thông tin này</div>
                        </div>
                        <div 
                            class="w100"
                            v-else-if="dataInputError.phone === 'error format'">
                            <input
                            class="w100 mt025 border-error pt075 pb075 pl075 pr075" 
                            type="text" 
                            placeholder="Nhập vào đây"
                            v-model="dataInput.phone">
                            <div class="h7 mt025 red">Bạn cần điền đúng định dạng số điện thoại</div>
                        </div>
                    </div>
                </div>
                <div class="f jcb ais w100 mb1">
                    <div class="w50">
                        <label class="h7">Quan hệ với khách hàng <span class="red">*</span></label>
                        <v-select 
                            v-if="!dataInputError.role"
                            class="mt025" 
                            :options="rolefamily" 
                            v-model="dataInput.role" 
                            :reduce="title_role => title_role.id" 
                            label="title_role"
                            placeholder="Chọn mối quan hệ"></v-select>
                        <div v-if="dataInputError.role">
                            <v-select 
                                class="border-error mt025" 
                                :options="rolefamily" 
                                v-model="dataInput.role" 
                                :reduce="title_role => title_role.id" 
                                label="title_role"
                                placeholder="Chọn mối quan hệ"></v-select>
                            <div class="h7 mt025 red">Bạn cần hoàn thiện thông tin này</div>
                        </div>
                    </div> 
                    <div class="w50 ml1">
                        <label class="h7">Vai trò <span class="red">*</span></label>
                        <v-select 
                            v-if="!dataInputError.role"
                            class="mt025"
                            :options="converMyrole(dataInput.role)" 
                            v-model="dataInput.myrole"  
                            placeholder="Chọn vai trò" ></v-select>
                        <div v-if="dataInputError.role">
                            <v-select 
                                class="border-error mt025"
                                :options="converMyrole(dataInput.role)" 
                                v-model="dataInput.myrole"  
                                placeholder="Chọn vai trò" ></v-select>
                            <div class="h7 mt025 red">Bạn cần hoàn thiện thông tin này</div>
                        </div>
                    </div>
                </div>
                <div class="w100 f fdc ais mb1">
                    <label class="h7">Địa chỉ </label>
                    <input
                        class="w100 pt075 pb075 pl075 pr075" 
                        type="text" 
                        placeholder="Nhập vào đây"
                        v-model="dataInput.address">
                </div>
                <div class="w100 f fdc ais mb1">
                    <div class="h7 mb05">Bổ sung hồ sơ</div>
                    <textarea
                        class="border border-radius w100 h7 pt075 pb 075 pr075 pl075" 
                        cols="30" 
                        rows="5" 
                        placeholder="Giúp bạn chăm sóc khách hàng tốt hơn"
                        v-model="dataInput.note"
                        ></textarea>
                </div>
                <div 
                    v-if="!editTypeFormUserSystem"
                    class="w100 f aic">
                    <input 
                        v-model="saveLikeUserSystem"
                        :value="saveLikeUserSystem"
                        type="checkbox">
                    <div 
                        v-if="!saveLikeUserSystem"
                        class="h7 gray ml05">Lưu mới đối tượng này là người dùng trên hệ thống</div>
                    <div 
                        v-if="saveLikeUserSystem"
                        class="h7 ml05">Lưu mới đối tượng này là người dùng trên hệ thống</div>
                </div>
            </div>
            <div class="customer-relate__action"> 
                <div class="f jce">
                    <button
                        @click="handleClose" 
                        class="pt075 pb075 pl1 pr1 mr1 border-radius brown cursor" style="border: 1px solid #6F382B">Hủy bỏ</button>
                    <button 
                        v-if="!editCustomerRelate.key_customer && !editCustomerRelate.id"
                        class="pt075 pb075 pl1 pr1 border-radius white bg-brown cursor"
                        @click="handleAdd">Thêm mới</button>
                    <button 
                        v-if="editCustomerRelate.key_customer || editCustomerRelate.id"
                        class="pt075 pb075 pl1 pr1 border-radius white bg-brown cursor"
                        @click="handleUpdate">Cập nhật</button>
                </div>
            </div>
        </div> 

        <AddCustomerOrder 
            :addCustomerSystem='addCustomerSystem'
            @updateAddCustomerSystem='updateAddCustomerSystem'
            @updateCustomerSelect='updateCustomerSelect'/>

        <NotiCreate 
            @resetSaveLikeUser='resetSaveLikeUser'
            @resetFormAddCustomerRelate='resetFormAddCustomerRelate'
            @updateCustomerRelate='updateCustomerRelate'
            @closePopup="closePopup"
            :dataInput='dataInput'
            :saveLikeUserSystem='saveLikeUserSystem'
            v-if="statusPopupCreate"/>

        <AddRole 
            v-if="statusPopupAddRole"
            :dataRole='rolefamily'
            @updateListRole='updateListRole'
            @closePopup='closePopup'/>
    </div>
</template>
 
<script>
import AddRole from './AddRole/AddRole.vue';
import AddCustomerOrder from '@/components/AddNew/AddCustomerOrder/AddCustomerOrder.vue'
import NotiCreate from './NotiCraete/NotiCreate.vue'

import { HTTP } from '@/http-default'
import vSelect from 'vue-select'
export default {
    components : {
        "v-select" : vSelect,
        AddRole,
        AddCustomerOrder,
        NotiCreate
    },

    props : {
        editCustomerRelate : {
            type : Object,
            default() {
                return {}
            }
        }
    },
 
    data () {
        return {
            dataInput : {
                full_name : '',
                phone: '',
                email: '',
                address: '',
                note : '',
                avatar : [],
                myrole : '',
                key_customer : Math.floor(Math.random() * 1000000000000),
                user : false,
                role : null
            },
            dataInputError : {
                full_name : false,
                phone: false,
                email: false,
                note : false,
                myrole : false,
                role : false,
            },
            saveLikeUserSystem : false,
            rolefamily : [],
            dataListAvatar : [],

            editTypeFormUserSystem : false,

            // popup add new role (Thêm quan hệ mới)
            statusPopupAddRole : false,

            // noti popup create
            statusPopupCreate : false,

            // popup add customer system (Thêm người dùng hệ thống)
            addCustomerSystem : false
        }
    },

    watch : {
        'dataInput.role' : function () {
            if (this.dataInput.role === 0) {
                this.statusPopupAddRole = true
                this.dataInput.role = null
            }
        },

        'saveLikeUserSystem' : function () {
            if (this.saveLikeUserSystem && this.dataInput.email === '') {
                this.dataInputError['email'] = true
            } 
            else {
                this.dataInputError['email'] = false
            }

            this.dataInput.user = (this.saveLikeUserSystem) ? true : false
        },

        'editCustomerRelate' : function () {
            console.log('helo');
            this.dataInput = {...this.editCustomerRelate}
        }
    },

    methods : {
        handleClose () {
            this.$emit('closePopupRelate')
        },

        updateCustomerSelect (value) {
            this.dataInput.full_name = value.full_name
            this.dataInput.phone = value.phone
            this.dataInput.email = value.email
            this.dataInput.address = value.address
            this.dataInput.avatar[0] = value.avatar
            this.dataListAvatar[0] = value.avatar
        },

        resetFormAddCustomerRelate () {
            this.dataInput = {
                full_name : '',
                phone: '',
                email: '',
                address: '',
                note : '',
                avatar : [],
                key_customer : Math.floor(Math.random() * 1000000000000),
                user : false,
                myrole : '',
                role : null
            }
        },

        resetSaveLikeUser () {
            this.saveLikeUserSystem = false
        },

        updateAddCustomerSystem () {
            this.addCustomerSystem = false
        },

        closePopup (value) {
            if (value === 'add-role__container') {
                this.statusPopupAddRole = false
            } else if (value === 'noti-create__container-cancel') {
                this.statusPopupCreate = false
            } else if (value === 'noti-create__container-close') {
                this.statusPopupCreate = false
                this.handleClose()
            }
        },

        converMyrole (value) {
            var data = []
            if (this.dataInput.role) {
                this.rolefamily.forEach(item => {
                    if (item.id === value) {
                        data = item.title_role.split('/')
                    }
                })
            } 

            return data
        },

        updateListRole (value) {
            value.forEach(item => {
                this.rolefamily.push(item)
            })
        },

        handleDeleteImg (item) {
            console.log(item);
        },

        uploadImg () {
            var input = document.getElementById('avatar-relate')
            input.click()
        },

        uploadImage(event) {
            this.dataInput.avatar.push(event.target.files[0])
            var url = URL.createObjectURL(event.target.files[0]);
            this.dataListAvatar.push(url)
            // var list = document.getElementById('list_avatar-relate');
            // list.innerHTML+="<img style=\"width: 64px; height: 64px\" class=\"output border-radius mr05\" src=\"" + url + "\" alt=\"img\" >";

        },

        getData () {
            HTTP.get('v1/api/rolefamily/')
            .then((res) => {
                this.rolefamily = res.data.results
                this.rolefamily.push({
                    title_role : 'Thêm quan hệ mới',
                    id : 0,
                })
            })
        },

        validateInput () {
            // full_name
            if (this.dataInput.full_name === '') {
                this.dataInputError.full_name = true
            } 
            
            // phone
            if (this.dataInput.phone === '') {
                this.dataInputError.phone = true
            } else if (this.dataInput.phone.length !== 10 || this.dataInput.phone[0] !== '0') {
                this.dataInputError.phone = "error format"
            }

            // email
            if (this.saveLikeUserSystem && this.dataInput.email === '') {
                this.dataInputError['email'] = true
            } 
            
            // Quan hệ với khách hàng
            if (this.dataInput.myrole === '') {
                this.dataInputError.myrole = true
            }

            // Vai trò
            if (this.dataInput.role === null) {
                this.dataInputError.role = true
            } 
        },

        updateCustomerRelate1 (value) {
            this.$emit('updateCustomerRelate', value)
        },

        async handleAdd (event) {
            event.preventDefault()

            for (let item in this.dataInputError) {
                this.dataInputError[item] = false
            }

            this.validateInput()

            for (let item in this.dataInputError) {
                if (this.dataInputError[item] === true || this.dataInputError[item] === 'error format') {
                    return false
                }
            }

            if (!this.saveLikeUserSystem) {
                this.updateCustomerRelate1()
            }
            // if (!saveLikeUserSystem) {
            //     var data = new FormData()
            //     data.append("name", this.dataInput.full_name)
            //     data.append("avatar", this.dataInput.avatar)
            //     data.append("email", this.dataInput.email)
            //     data.append("phone", this.dataInput.phone)
            //     data.append("address", this.dataInput.address)
            //     data.append("note", this.dataInput.note)
            //     data.append("myrole", this.dataInput.myrole)
            // }

            this.statusPopupCreate = true
            // var value = {}
            // for (let item in this.dataInput) {
            //     value[item] = this.dataInput[item]
            // }
            
            // this.$emit( await 'updateCustomerRelate', value)
            // this.handleClose()
        },

        handleUpdate () {
            var data = JSON.stringify(this.dataInput)
            HTTP.patch('v1/api/peoplerelate/' + this.dataInput.id + '/', data)
            .then((res) => {
                var data = res.data
                this.updateCustomerRelate1(data)
                this.handleClose()
            })
        }
    },
    
    mounted () {
        this.getData()
        if (this.editCustomerRelate.key_customer || this.editCustomerRelate.id) {
            if (this.editCustomerRelate.user) {
                this.editTypeFormUserSystem = true
            }

            this.dataInput = {...this.editCustomerRelate}
            if (this.dataInput.avatar.length !== 0) {
                this.dataInput.avatar.forEach(item => {
                    this.dataListAvatar.push(URL.createObjectURL(item))
                })
            }

            // this.dataInput.full_name = this.editCustomerRelate.name
            // this.dataListAvatar = {...this.dataInput.avatar}
            // console.log(this.dataInput.avatar)
        }
    }
}
</script>

<style scoped lang='scss'>
    @import 'CustomerRelate.scss';
</style>