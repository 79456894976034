export const Websocket = require('ws')

export const url_ws = 'wss://api.drmom.store/ws/message/'

class WebSocketMessage {
  constructor() {
    this.socket = null
  }

  connect() {
    this.socket = new WebSocket(url_ws);

    this.socket.onopen = () => {
      console.log('WebSocket connection established');
    };

    this.socket.onmessage = (message) => {
      console.log('Received message:', message.data);
      // Handle incoming messages
    };

    this.socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  send(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('WebSocket is not open. Unable to send message.');
    }
  }
}

export default new WebSocketMessage()