import { render, staticRenderFns } from "./ForumsView.vue?vue&type=template&id=2e257e43&scoped=true"
import script from "./ForumsView.vue?vue&type=script&lang=js"
export * from "./ForumsView.vue?vue&type=script&lang=js"
import style0 from "./ForumsView.vue?vue&type=style&index=0&id=2e257e43&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e257e43",
  null
  
)

export default component.exports