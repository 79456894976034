@<template>
    <div class="product-web__container">
        <HeaderType2
            v-if="!isMobile"
            class="z10"
            @updateProductCart='updateProductCart'
            :dataProductCart='dataProductCart'></HeaderType2>
        <HeaderLanding v-else></HeaderLanding>
        <div class="banner">
            <img class="w100" src="../../assets/bg-list-website.jpg" alt="">
        </div>

        <!-- danh sach san pham -->
        <div 
            v-if="!$route.params.id"
            class="list-product__container">
            <div class="list-product__header f aic mb1">
                <div class="p4 pr05 gray-1-text">Bộ lọc</div>
                <div class="h6 pl05 border-left">Danh mục:</div>
            </div>
            <div class="list-product__body">
                <div 
                    v-for="(item, index) in dataProduct"
                    :key="index"
                    class="list-product__cart">
                    <div class="cart-img__item relative mb1 cursor">
                        <img 
                            class="border border-radius"
                            :src="item.media[0].file_media" alt="">
                        <router-link
                            :to="{name : 'body-detail' , params : { id : item.id } }">
                            <div class="cart-bg__item absolute border-radius"></div>
                        </router-link>
                        <div class="cart-bg__item absolute border-radius"></div>
                        <div 
                            @click="handleBuyNow(item)"
                            class="cart-btn__item absolute pl1 pr1 cursor">
                            <div class="f jcc aic pt05 pb05 border-radius bg-brown">
                                <div class="p4 white">
                                    Mua ngay
                                </div>
                            </div>
                        </div>
                        <div 
                            @click="handleAddToCart(item)"
                            class="add-to-cart__container absolute f jcc aic border border-radius bg-white-1">
                            <div class="icon--cart-black">
                                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.0664 2.5H3.9375L3.69141 1.29688C3.63672 0.996094 3.36328 0.75 3.0625 0.75H0.328125C0.136719 0.75 0 0.914062 0 1.07812V1.73438C0 1.92578 0.136719 2.0625 0.328125 2.0625H2.51562L4.40234 11.7969C4.10156 12.125 3.9375 12.5352 3.9375 13C3.9375 13.9844 4.70312 14.75 5.6875 14.75C6.64453 14.75 7.4375 13.9844 7.4375 13C7.4375 12.6992 7.32812 12.3984 7.19141 12.125H11.1562C11.0195 12.3984 10.9375 12.6992 10.9375 13C10.9375 13.9844 11.7031 14.75 12.6875 14.75C13.6445 14.75 14.4375 13.9844 14.4375 13C14.4375 12.5078 14.2188 12.0703 13.8906 11.7422L13.918 11.6328C14 11.2227 13.6992 10.8125 13.2617 10.8125H5.55078L5.30469 9.5H13.8359C14.1641 9.5 14.4102 9.30859 14.4922 9.00781L15.7227 3.32031C15.8047 2.91016 15.5039 2.5 15.0664 2.5ZM12.6875 12.3438C13.043 12.3438 13.3438 12.6445 13.3438 13C13.3438 13.3828 13.043 13.6562 12.6875 13.6562C12.3047 13.6562 12.0312 13.3828 12.0312 13C12.0312 12.6445 12.3047 12.3438 12.6875 12.3438ZM5.6875 12.3438C6.04297 12.3438 6.34375 12.6445 6.34375 13C6.34375 13.3828 6.04297 13.6562 5.6875 13.6562C5.30469 13.6562 5.03125 13.3828 5.03125 13C5.03125 12.6445 5.30469 12.3438 5.6875 12.3438ZM13.3164 8.1875H5.03125L4.18359 3.8125H14.2734L13.3164 8.1875ZM7.4375 6.21875C7.4375 6.41016 7.57422 6.54688 7.76562 6.54688H8.64062V7.42188C8.64062 7.61328 8.77734 7.75 8.96875 7.75H9.40625C9.57031 7.75 9.73438 7.61328 9.73438 7.42188V6.54688H10.6094C10.7734 6.54688 10.9375 6.41016 10.9375 6.21875V5.78125C10.9375 5.61719 10.7734 5.45312 10.6094 5.45312H9.73438V4.57812C9.73438 4.41406 9.57031 4.25 9.40625 4.25H8.96875C8.77734 4.25 8.64062 4.41406 8.64062 4.57812V5.45312H7.76562C7.57422 5.45312 7.4375 5.61719 7.4375 5.78125V6.21875Z" fill="#1A1A1A"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <router-link 
                        :to="{name : 'body-detail' , params : { id : item.id } }"
                        class="cart-title__item">
                        <div class="cart-rating__item">
                            <star-rating 
                                :rating="5"
                                :increment="0.5"
                                :star-size="15"
                                :read-only="true"
                                :border-width="2" 
                                border-color="#FFCC00" 
                                active-color="#FFCC00"
                                :rounded-corners="true" 
                                inactive-color="#fff"
                                :star-points="[108, 0.0, 141, 70, 218, 78.3, 162, 131, 175, 205, 108, 170, 41.2, 205, 55, 131, 1, 78, 75, 68]"
                                :show-rating="false"
                            ></star-rating>
                        </div>
                        <div class="p5 mb05">
                            {{item.title_product}}
                        </div>
                        <div class="f aic">
                            <span class="h3 orange">{{converMoney(item.price_sale)}}</span>
                            <span class="ml1 h6 gray-1-text" style="text-decoration: line-through">{{converMoney(item.price_product)}}</span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <!-- detail cart -->
        <DetailCart 
            v-else-if="$route.params.id === 'detail-cart'"
            @updateProductCart='updateProductCart' />

        <!-- detail product -->
        <ProductDetailWeb 
            v-else 
            @updateCart='updateCart'
            :dataAllProduct='dataProduct'/>

        <!-- footer -->
        <FooterSection></FooterSection>
    </div>
</template>

<script>
import HeaderType2 from '@/components/HeaderType2/HeaderType2.vue'
import FooterSection from '@/components/FooterSection/FooterSection.vue'
import StarRating from 'vue-star-rating'
import DetailCart from '@/components/DetailCart/DetailCart.vue'
import ProductDetailWeb from '@/components/ProductDetailWeb/ProductDetailWeb.vue'
import HeaderLanding from '@/components/Landingpage/HeaderLanding/HeaderLanding.vue'

import { HTTP } from '@/http-default'

export default {
    components: {
        HeaderType2,
        FooterSection,
        DetailCart,
        ProductDetailWeb,
        StarRating,
        HeaderLanding
    },
    
    data () {
        return {
            dataProduct : [],
            dataProductCart : [],
            cartHover: false
        }
    },

    methods : {
        converMoney (value) {
            var money = value.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
            return money
        },

        updateProductCart () {
            let data = localStorage.getItem("cartProudct")
            let cart = JSON.parse(data)
            this.dataProductCart = cart
        },

        updateCart (value) {
            this.dataProductCart = value
        },

        async handleAddToCart(item) {
            let product = item
            let data = localStorage.getItem("cartProudct")
            let cart = JSON.parse(data)

            if (cart === null) {
                cart = []
            }

            let checkProduct = await cart.filter(value => value.slug === product.slug)

            if (checkProduct.length === 0) {
                product.quantity = 1
                cart.push(product)
                this.dataProductCart = cart
                const cookie = JSON.stringify(cart)
                localStorage.setItem('cartProudct', cookie)
            } else {
                cart.forEach(i => {
                    if (i.id === product.id) {
                        i.quantity++
                    }
                })
                this.dataProductCart = cart
                cart = JSON.stringify(cart)
                localStorage.setItem('cartProudct', cart)
            }

        },

        handleBuyNow (item) {
            this.handleAddToCart(item)

            this.$router.push({name : 'body-detail' , params : { id : 'detail-cart'} })
        },

        getData () {
            HTTP.get('v1/api/product/?depth=1')
            .then((res) => {
                this.dataProduct = res.data.results
            })
        }
    },

    created () {
        this.getData()
        this.updateProductCart()
    },

    computed: {
        isMobile() {
        if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
            )
        ) {
            return true;
        } else {
            return false;
        }
        },
    },
}
</script>

<style scoped lang='scss'>
    @import 'ProductWebSite.scss';
</style>