<template>
  <div class="footer" ref="footer">
    <div class="container container--forums">
      <div class="f jcb grid grid--4 grid--footer footer__inner">
        <div class="grid__item footer__item">
          <div class="footer__logo">
            <a href="/" title="logo">
              <picture class="footer__image">
                <img src="../../assets/LogoDrMom.png" alt="logo">
              </picture>
            </a>
          </div>
          <div class="footer__title">
            Copyright © 2022 Dr.Mom
          </div>
          <div class="footer__description">
            <p class="p5">All rights reserved</p>
          </div>
          <div v-if="showSocial" class="footer__social">
            <div class="footer__social-list">
              <a class="link footer__social-link" href="#">
                <svg
                  class="icon icon-instagram footer__social-icon"
                  viewBox="0 0 14 14"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="#icon-instagram"
                    x="0"
                    y="0"
                  />
                </svg>
              </a>
              <a class="link footer__social-link" href="#">
                <svg
                  class="icon icon-wiki footer__social-icon"
                  viewBox="0 0 14 14"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="#icon-wiki"
                    x="0"
                    y="0"
                  />
                </svg>
              </a>
              <a class="link footer__social-link" href="#">
                <svg
                  class="icon icon-twitter footer__social-icon"
                  viewBox="0 0 12 10"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="#icon-twitter"
                    x="0"
                    y="0"
                  />
                </svg>
              </a>
              <a class="link footer__social-link" href="#">
                <svg
                  class="icon icon-youtube footer__social-icon"
                  viewBox="0 0 14 10"
                >
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="#icon-youtube"
                    x="0"
                    y="0"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="grid__item footer__item">
          <div class="footer__menu">
            <h3 class="footer__menu-title">Company</h3>
            <a class="footer__menu-link" href="#">About us</a>
            <a class="footer__menu-link" href="#">Blog</a>
            <a class="footer__menu-link" href="#">Contact us</a>
            <a class="footer__menu-link" href="#">Pricing</a>
            <a class="footer__menu-link">Testimonials</a>
          </div>
        </div>
        <div class="grid__item footer__item">
          <div class="footer__menu">
            <h3 class="footer__menu-title">Support</h3>
            <a class="footer__menu-link">Help center</a>
            <a class="footer__menu-link" href="#">Terms of service</a>
            <a class="footer__menu-link" href="#">Legal</a>
            <a class="footer__menu-link" href="#">Privacy policy</a>
            <a class="footer__menu-link" href="#">Status</a>
          </div>
        </div>
        <div class="grid__item footer__item">
          <div class="footer__menu footer__info">
            <h3 class="footer__menu-title">Nhận tin tức mới</h3>
            <div class="footer__info-item">
              <input class="input footer__info-input" type="text" placeholder="Nhập email">
              <button class="button footer__info-button">Đăng ký</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterSection',
  props: {
    showSocial: {
      type: Boolean
    }
  }
}
</script>
<style scoped lang="scss">
@import 'FooterSection.scss';
</style>
