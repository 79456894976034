<template>
  <div class="forums__container h100vh w100 bg-gray-2 page page--forums">
    <HeaderType2 v-if="!isMobile"></HeaderType2>
    <HeaderLanding v-else></HeaderLanding>
    <ForumPageView :isMobile="isMobile" />
    
    <!-- <FooterSection></FooterSection> -->
  </div>
</template>

<script>
import HeaderType2 from '@/components/HeaderType2/HeaderType2.vue'
import ForumPageView from '@/components/ForumPageView/ForumPageView.vue'
import HeaderLanding from '@/components/Landingpage/HeaderLanding/HeaderLanding.vue'

export default {
  components: {
    HeaderType2,
    // FooterSection,
    ForumPageView,
    HeaderLanding,
  },

  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
}
</script>
<style scoped lang='scss'>
// .forums__container {
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: 1000;
// }
</style>