@<template>
  <div class="container">
      <HeaderLayout1 
        @textFilterSearch='handleTextFilterSearch'
        :chatStatus='chatStatus' :data='dataField'></HeaderLayout1>
      <div class="f gap1">
            <CategotyLayout1 
                @changeChatStatus='changeChatStatus'
                :data='dataField' :dataCategory='dataCategory'></CategotyLayout1>
            <ListLayout1 
                v-if="!chatStatus" 
                :data='dataField' 
                :dataProps='personnelActive'></ListLayout1>
            <CustomerChat 
                v-if="chatStatus" 
                :chatStatus='chatStatus'></CustomerChat>
      </div>
  </div>
</template>

<script>
import HeaderLayout1 from '../Layout1/HeaderLayout1/HeaderLayout1.vue'
import CategotyLayout1 from '@/components/Layout1/CategoryLayout1/CategoryLayout1.vue'
import ListLayout1 from '../Layout1/ListLayout1/ListLayout1.vue'
import CustomerChat from './CustomerChat/CustomerChat.vue'
export default {
    props: {
        data : {
            type: Array,
            default() {
                return []
            }
        },
        dataCategory : {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        dataProps : {
            type: Array,
            default() {
                return []
            }
        },
        dataField : {
            type: Object,
            default() {
                return {}
            }
        },
    },
    components: {
        HeaderLayout1,
        CategotyLayout1,
        ListLayout1,
        CustomerChat
    },

    watch : {
        '$route' : function () {
            this.chatStatus = false
        },

        'dataProps' : function () {
            this.personnelActive = this.dataProps
        }
    },

    data () {
        return {
            chatStatus : '',
            textFilterSearch : '',
            personnelActive : []
        }
    },

    methods : {
        changeChatStatus (value) {
            this.chatStatus = value
        },

        handleTextFilterSearch (value) {
            this.textFilterSearch = value
            var filter = value.toUpperCase();
            this.personnelActive = [];
            this.personnelWait = [];
            if(!filter) {
                for(let i = 0; i < 5; i++) {
                    this.personnelActive.push(this.dataProps[i]);
                }
            }
            else {
                for (let i = 0; i < this.dataProps.length; i++) {
                    var perName = this.dataProps[i].full_name;
                    var perPhone = this.dataProps[i].phone
                    var txtValue = perName + perPhone;
                    if (txtValue.toUpperCase().indexOf(filter) > -1) {
                        this.personnelActive.push(this.dataProps[i])
                    }
                }
                // for(let i = 0; i < this.personnelWait.length ; i++) {
                // this.personnelActive.push(this.personnelWait[i]);
                // }
            }
        }
    }
}
</script>

<style scoped lang='scss'>

</style>