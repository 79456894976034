@<template>
    <div class="popup popup-change-password f jcc aic">
        <div class="change-password__container px1 border-radius bg-white-1">
            <div class="change-password__header mb1">
                <div class="">Đổi mật khẩu</div>
            </div>
            <div class="change-password__body f fdc ais mb1">
                <div class="f fdc ais">
                    <label class="h7" for="">Nhập mật khẩu hiện tại</label>
                    <input 
                        class="w100 border-radius mt025"
                        placeholder="Nhập mật khẩu hiện tại"
                        type="text">
                </div>
                <div class="f fdc ais">
                    <label class="h7" for="">Mật khẩu mới</label>
                    <input 
                        class="w100 border-radius mt025"
                        placeholder="Nhập mật khẩu mới"
                        type="text">
                </div>
                <div class="f fdc ais">
                    <label class="h7" for="">Nhập lại mật khẩu mới</label>
                    <input 
                        class="w100 border-radius mt025"
                        placeholder="Nhập lại mật khẩu mới"
                        type="text">
                </div>
            </div>
            <div class="change-password__action f jce aic">
                <div 
                    @click="closePopup"
                    class="h5 px075 border border-radius cursor">
                    Đóng
                </div>
                <div class="h5 px075 ml1 border-radius bg-brown white cursor">
                    Xác nhận
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods : {
        closePopup () {
            this.$emit('closePopup', 'changePassword')
        }
    }
}
</script>

<style scoped lang='scss'>
    @import 'ChangePassword.scss';
</style>