@<template>
    <div class="detail-category__container container">
        <ListLayout2 
            :systemShow='systemShow'
            :data='dataField' 
            :dataProps='data'
            :isMobile="isMobile"
            ></ListLayout2>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
import ListLayout2 from '@/components/Layout2/ListLayout2/ListLayout2.vue'
export const WEBSITE = 2

export default {
    components : {
        ListLayout2
    },

    props : {
         dataProps : {
            type : Array,
            default() {
                return []
            }
        },
        dataField : {
            type : Object,
            default() {
                return {}
            }
        },

        systemShow : {
            type : [String, Number],
            default () {
                return ''
            }
        },

        isMobile: {
            type: Boolean,
            default: false,
        },
    },

    data () {
        return {
            data : [],
            // systemShow : [],
            // dataField : [],
            // dataProps : []
        }
    },

    methods : {
        getData () {
            if (this.$route.params.path === 'quan-ly-post') {
                HTTP.get(`v1/api/post/?depth=1&system_show=${WEBSITE}&category=${this.$route.params.id}`)
                .then((res) => {
                    var data = res.data.results
                    this.data = data
                })
            }
        }
    },

    created () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'DetailCategory.scss';
</style>