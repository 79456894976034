@<template>
    <div class="popup popup-auth f jcc aic">
        <div class="noti-auth__container f fdc aic pt1 pb1 pl1 pr1 border-radius bg-white-1">
            <div class="icon--noti-warning mb1"></div>
            <div class="mb05 brown h3">Thông báo</div>
            <div class="p2 gray mb15">Đang tạo tài khoản</div>
            <div class="loader-1"></div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped lang='scss'>
    @import 'NotiAuthorization.scss';
</style>