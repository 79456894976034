<template>
  <div class="course-web__container">
    <div>
      <HeaderType2
        class="z10"
        @updateProductCart="updateProductCart"
        :dataProductCart="dataProductCart"
      ></HeaderType2>

      <img
        class="img__bg-1"
        loading="lazy"
        src="@/components/Landingpage/imgLanding/intro_img_1.png"
        alt=""
      />
      <img
        class="img__bg-2"
        loading="lazy"
        src="@/components/Landingpage/imgLanding/intro_img_2.jpg"
        alt=""
      />
      <img
        class="img__bg-3"
        loading="lazy"
        src="@/components/Landingpage/imgLanding/intro_img_3.jpg"
        alt=""
      />
      <div class="container relative z10">
        <div v-if="dataCourse" class="course-detail relative">
          <div class="course-detail-media">
            <img
              v-if="dataCourse.media && dataCourse.media.file_media"
              :src="dataCourse.media.file_media"
              alt=""
            />
            <img v-else src="@/assets/imgUser.png" alt="" />
          </div>

          <div
            class="course-detail-content absolute bg-white-1 px1 f fdc gap1 shadow"
          >
            <p class="align-c h3 brown">{{ dataCourse.title }}</p>
            <div class="w100 align-c">{{ dataCourse.description }}</div>
            <div class="w100" v-html="dataCourse.content"></div>

            <div class="course-detail-unit px1 f fdc gap1">
              <div class="f aic jcb">
                <p class="h5">Chi tiết khoá học</p>
              </div>
              <template
                v-if="
                  dataCourse.course_unit_data &&
                  dataCourse.course_unit_data.length
                "
              >
                <div
                  v-for="(item, index) in dataCourse.course_unit_data"
                  :key="index"
                  class="course-detail-unit-item f gap1 relative"
                  @click="openPopupUnit(item.id)"
                >
                  <div>
                    <video
                      v-if="item.video"
                      :src="item.video.video_file"
                      alt=""
                      width="200px"
                      height="100px"
                    />
                    <video
                      v-else
                      :src="null"
                      alt=""
                      width="200px"
                      height="100px"
                    />
                  </div>
                  <div class="w100">
                    <p class="p5 brown">{{ item.title }}</p>
                    <p class="p4 gray-1-text">{{ item.description }}</p>
                    <p class="p6 gray-1-text">
                      Thời gian tạo:
                      <span class="h7 black">{{
                        formatDate(item.created_at)
                      }}</span>
                    </p>
                    <p v-if="item.user_created" class="p6 gray-1-text">
                      Người tạo:
                      <span class="h7 black">{{
                        item.user_created.username
                      }}</span>
                    </p>
                    <div class="f aic gap1">
                      <p class="p6 gray-1-text">
                        Lượt xem:
                        <span class="h7 black">{{ item.total_click }}</span>
                      </p>
                      <p class="p6 gray-1-text">
                        Lượt chia sẻ:
                        <span class="h7 black">{{ item.total_share }}</span>
                      </p>
                    </div>
                    <div class="f aic gap05 jce">
                      <template
                        v-if="
                          dataUserCourseUnit.find(
                            (elm) =>
                              elm.course_unit &&
                              elm.course_unit.id === item.id
                          )
                        "
                      >
                        <p class="h7 green align-r">Đã hoàn thành</p>
                        <img
                          src="@/assets/complate.png"
                          alt=""
                          style="width: 16px; height: 16px; object-fit: cover"
                        />
                      </template>
                    </div>
                  </div>
                  <div
                    class="absolute"
                    style="top: 50%; right: 1em; transform: translateY(-50%)"
                  >
                    <div class="f aic gap1">
                      <img src="@/assets/icons/move.svg" alt="" />
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <p class="italic gray-text-1 h7">Chưa có bài giảng nào</p>
              </template>
            </div>
          </div>
        </div>

        <Loading :is-loading="isLoading" type="page" />
      </div>
    </div>

    <Popup :is-show="isShowPopupUnit" :width="'70vw'" @close="closePopup">
      <template v-if="dataCourse && dataCourseUnit">
        <p class="water-mark">@{{ myAccount.user__username }}</p>
        <div class="bg-white-1 px1 border-radius">
          <p class="p3">
            Khoá học: <span class="h3 brown">{{ dataCourse.title }}</span>
          </p>
          <p class="p4">
            Bài giảng: <span class="h4 brown">{{ dataCourseUnit.title }}</span>
          </p>
        </div>

        <div class="px1">
          <p class="p5">Video bài giảng:</p>
          <div class="f aic jcc">
            <video
              v-if="dataCourseUnit.video"
              :src="dataCourseUnit.video.video_file"
              autoplay
              width="1200px"
              height="500px"
              controls
              controlsList="nodownload"
              oncontextmenu="return false;"
              ref="videoPlayer"
              class="video-js vjs-default-skin"
            ></video>
            <video
              v-else
              :src="null"
              autoplay
              width="100%"
              height="500px"
            ></video>
          </div>
        </div>

        <div class="course-unit-content bg-white-1 px1 border-radius">
          <p class="p5 brown">Thông tin bài giảng</p>
          <p class="p4 gray-1-text">{{ dataCourseUnit.description }}</p>
          <div v-html="dataCourseUnit.content"></div>
          <p class="p6 gray-1-text">
            Thời gian tạo:
            <span class="h7 black">{{
              formatDate(dataCourseUnit.created_at)
            }}</span>
          </p>
          <p v-if="dataCourseUnit.user_created" class="p6 gray-1-text">
            Người tạo:
            <span class="h7 black">{{
              dataCourseUnit.user_created.username
            }}</span>
          </p>
          <div class="f aic gap1">
            <p class="p6 gray-1-text">
              Lượt xem:
              <span class="h7 black">{{ dataCourseUnit.total_click }}</span>
            </p>
            <p class="p6 gray-1-text">
              Lượt chia sẻ:
              <span class="h7 black">{{ dataCourseUnit.total_share }}</span>
            </p>
          </div>
          <div class="f aic gap05 jce">
            <template
              v-if="
                dataUserCourseUnit.find(
                  (elm) =>
                    elm.course_unit && elm.course_unit.id === dataCourseUnit.id
                )
              "
            >
              <p class="h7 green align-r">Đã hoàn thành</p>
              <img
                src="@/assets/complate.png"
                alt=""
                style="width: 16px; height: 16px; object-fit: cover"
              />
            </template>
          </div>
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import { HTTP } from "../../http-default";
import Loading from "@/components/Loading/Loading.vue";
import HeaderType2 from "@/components/HeaderType2/HeaderType2.vue";
import Popup from "@/components/Popup/index.vue";

import videojs from "video.js";

export default {
  name: "CourseWebsiteDetailPage",

  components: {
    Loading,
    HeaderType2,
    Popup,
  },

  data() {
    return {
      dataCourse: null,
      isLoading: true,
      isShowPopupUnit: false,
      dataCourseUnit: null,
      dataUserCourseUnit: [],

      dataProductCart: [],

      player: null,
      time: null,
    };
  },

  computed: {
    myAccount() {
      return JSON.parse(localStorage.getItem("myAccount") || {});
    },
  },

  created() {
    this.getData();
    this.getDataUserCourseUnit();
  },

  beforeDestroy() {
    this.time = null;
    this.player?.dispose();
  },

  methods: {
    async getData() {
      await HTTP.get(`course/course/${this.$route.params.id}`).then((res) => {
        this.dataCourse = res?.data?.data || [];
        const course_units = this.dataCourse.course_unit_data || [];
        if (course_units.length) {
          this.dataCourse.course_unit_data = course_units.sort(
            (a, b) => (a.index || 0) - (b.index || 0)
          );
        }
        this.isLoading = false;
      });
    },

    async getDataUserCourseUnit() {
      await HTTP.get(
        `course/user-course-unit/?course=${this.$route.params.id}`
      ).then((res) => {
        this.dataUserCourseUnit = res?.data?.data || [];
      });
    },

    async openPopupUnit(unitId) {
      this.isShowPopupUnit = true;
      await HTTP.get(`course/course-unit/${unitId}`)
        .then((res) => {
          this.dataCourseUnit = res?.data?.data;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });

      console.log(this.$refs.videoPlayer);
      this.player = videojs(this.$refs.videoPlayer);

      this.player.on("loadedmetadata", () => {
        console.log("loadedmetadata");
        this.duration = this.player.duration();
        this.time = setTimeout(() => {
          this.autoDoneUnit(unitId);
        }, (this.player.duration() * 1000) % 0.8);
      });

      this.player.on("timeupdate", () => {
        console.log("timeupdate");
        this.currentTime = this.player.currentTime();
      });
    },

    closePopup() {
      this.time = null;
      this.isShowPopupUnit = false;
    },

    formatDate(date, _type) {
      const newDate = new Date(date);
      const dformat =
        (_type
          ? [
              newDate.getHours(),
              newDate.getMinutes() < 10
                ? "0" + newDate.getMinutes()
                : newDate.getMinutes(),
            ].join(":") + " "
          : "") +
        [
          newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate(),
          newDate.getMonth() + 1 < 10
            ? "0" + (newDate.getMonth() + 1)
            : newDate.getMonth() + 1,
          newDate.getFullYear(),
        ].join("/");

      return dformat;
    },

    async autoDoneUnit(unitId) {
      const payload = {
        course_unit: unitId,
      };
      await HTTP.post(`course/user-course-unit/`, payload).then(() =>
        this.getDataUserCourseUnit()
      );
    },

    updateProductCart() {
      let data = localStorage.getItem("cartProudct");
      let cart = JSON.parse(data);
      this.dataProductCart = cart;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "video.js/dist/video-js.css";

.course-detail-media {
  & img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 16px;
  }
}

.course-detail-content {
  width: 60%;
  border-radius: 8px;
  top: calc(100% - 2em);
  left: 1em;
  min-height: 500px;
}

.course-detail-user {
  width: 35%;
  border-radius: 8px;
  top: calc(100% - 2em);
  right: 1em;
  min-height: 500px;
}

.course-detail-unit {
  border-top: 2px solid #6f382b;

  &-item {
    border: 1px solid #6f382b;
    padding: 1em;
    border-radius: 8px;
  }
}

.shadow {
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.16);
}

.course-web__container {
  background-color: #f1b49343;
  position: relative;
  min-height: 100vh;
  height: auto;
}

.img__bg-1 {
  mix-blend-mode: multiply;
  opacity: 0.2;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}

.img__bg-2 {
  mix-blend-mode: multiply;
  opacity: 0.2;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1;
}

.img__bg-3 {
  mix-blend-mode: multiply;
  opacity: 0.2;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
}

.water-mark {
  -webkit-transform: rotate(331deg);
  -moz-transform: rotate(331deg);
  -o-transform: rotate(331deg);
  transform: rotate(331deg);
  font-size: 4em;
  color: rgba(255, 5, 5, 0.17);
  position: absolute;
  font-family: "Denk One", sans-serif;
  text-transform: uppercase;
  z-index: 20020;
  top: 50%;
  left: 30%;
  opacity: 0.5;
}
</style>