import { render, staticRenderFns } from "./AllCategory.vue?vue&type=template&id=829fc142&scoped=true"
import script from "./AllCategory.vue?vue&type=script&lang=js"
export * from "./AllCategory.vue?vue&type=script&lang=js"
import style0 from "./AllCategory.vue?vue&type=style&index=0&id=829fc142&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "829fc142",
  null
  
)

export default component.exports