@<template>
    <div class="add-user__container">
        
        <!-- header -->
        <div class="add-user__header">
            <div class="container-header f aic" :class="[isMobile ? 'p1' : 'mb2']">
                <div class="qcont h2 pr1 border-right">
                    User
                </div>
                <div 
                    class="h5 pr05 pl1 gray">
                    User
                </div>
                <div class="icon--arrown-left"></div>
                <div 
                    class="h5 pr05 pl1 brown">
                    Tạo mới user
                </div>
            </div>
        </div>

        <!-- body -->
        <div class="add-user__body w100 f fdc jcc aic">
            <div class="info-account__form w100 border-radius px1 mb1 bg-white-1">
                <div class="form__header mb1">
                    <div class="p2 brown">Thông tin tài khoản</div>
                </div>
                <div class="form__body">
                    <div 
                        class="f fdc ais w100 mb1">
                        <label class="h7" for="">Loại user <span class="red">*</span> </label>
                        <v-select 
                            v-if="errorInput.role"
                            class="mt025 border-error"
                            placeholder="Chọn loại user"
                            :reduce='title => title.id'
                            label='title'
                            v-model="dataUser.role"
                            :options='dataRole'></v-select>
                        <v-select 
                            v-if="!errorInput.role"
                            class="mt025"
                            placeholder="Chọn loại user"
                            :reduce='title => title.id'
                            label='title'
                            v-model="dataUser.role"
                            :options='dataRole'></v-select>
                    </div>
                    <div class="f ais jcb">
                        <div class="user-name__input w50 f fdc ais">
                            <label class="h7" for="">Tên đăng nhập <span class="red">*</span> </label>
                            <input 
                                v-if="!errorInput.username"
                                placeholder="Nhập tên đăng nhập"
                                v-model="username"
                                class="w100 mt025"
                                type="text" name="" id="">
                            <div 
                                class="w100"
                                v-if="errorInput.username">
                                <input 
                                    placeholder="Nhập tên đăng nhập"
                                    v-model="username"
                                    class="w100 mt025 border-error"
                                    type="text" name="" id="">
                                <div class="mt05 p4 red">Bạn cần hoàn thiện trường thông tin này</div>
                            </div>
                        </div>
                        <div class="user-name__input w50 ml1 f fdc ais">
                            <label class="h7" for="">Mật khẩu <span class="red">*</span> </label>
                            <input 
                                v-if="!errorInput.password"
                                placeholder="Nhập mật khẩu"
                                v-model="password"
                                class="w100 mt025"
                                type="text" 
                                name="" 
                                id="">
                            <div 
                                class="w100"
                                v-if="errorInput.password">
                                <input 
                                    placeholder="Nhập mật khẩu"
                                    v-model="password"
                                    class="w100 mt025 border-error"
                                    type="text">
                                <div class="mt05 p4 red">Bạn cần hoàn thiện trường thông tin này</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="info-personal__form w100 border-radius px1 mb1 bg-white-1 shadow">
                <div class="form__header mb1">
                    <div class="p2 brown">Thông tin cá nhân</div>
                </div>
                <div class="form__img-user f fdc jcc aic">
                    <div class="relative">
                        <img 
                            :src="dataUser.avatar" alt="">
                        <div class="change-img__btn absolute cursor">
                            <input 
                                class="hide"
                                type="file" 
                                accept="image/*" 
                                ref="file"
                                @change="uploadImage($event)" 
                                id="file-input">
                            <div 
                                @click="uploadImg"
                                class="bg-brown px05">
                                <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24"><path fill="#FFFFFF" d="M12.29,5.21l1.5,1.5a1,1,0,0,0,1.42,0,1,1,0,0,0,.13-1.21H19a1,1,0,0,0,0-2H15.34a1,1,0,0,0-.13-1.21,1,1,0,0,0-1.42,0l-1.5,1.5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76A1,1,0,0,0,12.29,5.21ZM22.92,9.12a1,1,0,0,0-.21-.33l-1.5-1.5a1,1,0,0,0-1.42,0,1,1,0,0,0-.13,1.21H16a1,1,0,0,0,0,2h3.66a1,1,0,0,0,.13,1.21,1,1,0,0,0,1.42,0l1.5-1.5a1,1,0,0,0,.21-.33A1,1,0,0,0,22.92,9.12ZM11,10a4,4,0,1,0,4,4A4,4,0,0,0,11,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,11,16Zm9-3a1,1,0,0,0-1,1v5a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H6a1,1,0,0,0,1-.69l.54-1.62A1,1,0,0,1,8.44,7H10a1,1,0,0,0,0-2H8.44A3,3,0,0,0,5.59,7.06L5.28,8H4a3,3,0,0,0-3,3v8a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V14A1,1,0,0,0,20,13Z"/></svg>
                            </div>
                        </div>
                    </div>
                    <div class="h6 mt05">
                        Kích thước tối đa nhỏ hơn 2MB
                    </div>
                </div>

                <div class="form__body f fdc ais mb1">
                    <div class="w100 f fdc ais">
                        <label class="h7" for="">Họ và tên <span class="red">*</span>  </label>
                        <input 
                            v-if="!errorInput.full_name"
                            class="w100 mt025 border-radius"
                            placeholder="Nhập họ và tên"
                            v-model="dataUser.full_name"
                            type="text" name="" id="">
                        <div    
                            class="w100"
                            v-if="errorInput.full_name">
                            <input 
                                class="w100 mt025 border-radius border-error"
                                placeholder="Nhập họ và tên"
                                v-model="dataUser.full_name"
                                type="text" name="" id="">
                            <div class="mt05 p4 red">Bạn cần hoàn thiện trường thông tin này</div>
                        </div>
                    </div>
                    <div class="w100 f aic jcb">
                        <div class="f fdc ais w50">
                            <label class="h7" for="">Email  <span class="red">*</span> </label>
                            <input 
                                v-if="!errorInput.email"
                                class="w100 mt025 border-radius"
                                placeholder="Nhập email"
                                v-model="dataUser.email"
                                type="text" name="" id="">
                            <div 
                                class="w100"
                                v-if="errorInput.email">
                                <input 
                                    class="w100 mt025 border-radius border-error"
                                    placeholder="Nhập email"
                                    v-model="dataUser.email"
                                    type="text" name="" id="">
                                <div class="mt05 p4 red">Bạn cần điền đúng trường thông tin này</div>
                            </div>
                        </div>
                        <div class="f fdc ais w50 ml1">
                            <label class="h7" for="">Số điện thoại  <span class="red">*</span>  </label>
                            <input 
                                v-if="!errorInput.phone"
                                class="w100 mt025 border-radius"
                                placeholder="Nhập số điện thoại"
                                v-model="dataUser.phone"
                                type="text" name="" id="">
                            <div 
                                class="w100"
                                v-if="errorInput.phone">
                                <input 
                                    class="w100 mt025 border-radius border-error"
                                    placeholder="Nhập số điện thoại"
                                    v-model="dataUser.phone"
                                    type="text" name="" id="">
                                <div class="mt05 p4 red">Bạn cần điền đúng trường thông tin này</div>
                            </div>
                        </div>
                    </div>
                    <div class="w100 f ais jcb">
                        <div class="f fdc ais w50">
                            <label class="h7" for="">Giới tính</label>
                            <v-select 
                                class="mt025"
                                placeholder="Chọn"
                                v-model="dataUser.gender"
                                :options='dataGender'></v-select>
                        </div>
                        <div class="f fdc ais w50 ml1">
                            <label class="h7" for="">Ngày sinh</label>
                            <input 
                                class="w100 mt025 px075 border-radius"
                                placeholder="Nhập số điện thoại"
                                v-model="dataUser.birthday"
                                type="date" name="" id="">
                        </div>
                    </div>
                </div>

                <div class="form__action w100 f jce aic cursor">
                    <div 
                        @click="handleCreateUser"
                        class="h5 px075 border-radius align-c bg-brown white">
                        Thêm mới
                    </div>
                </div>
            </div>
        </div>

        <div style="display: none">
            <img id="avatar-default" src="./avatar.jpeg" alt="">
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default';
import vSelect from 'vue-select'

export default {
    components : {
        'v-select' : vSelect
    },

    props: {
        isMobile: {
            type: Boolean,
            default: false
        },
    },

    data () {
        return {
            dataUser : {
                avatar : null,
                full_name : '',
                email : '',
                phone : '',
                gender : '',
                birthday : '',
                role : ''
            },

            errorInput : {
                full_name : false,
                email : false,
                phone : false,
                role : false,
                username : false,
                password : false,
            },

            username : '',
            password : '',

            dataRole : [
                {
                    title : 'User Admin',
                    id : 3
                },
                {
                    title : 'User Website/Forum',
                    id : 2
                },
                {
                    title : 'User Affiliate',
                    id : 1
                },
            ],

            avatar : [],

            dataGender : [
                'Nam' , 'Nữ' , 'Giới tính khác'
            ]
        }
    },

    methods : {
        random (length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        },

        validataInput () {
            this.errorInput.full_name = (this.dataUser.full_name === '') ? true : false
            this.errorInput.email = (this.dataUser.email.length < 10) ? true : false
            this.errorInput.phone = (this.dataUser.phone.length < 10) ? true : false
            this.errorInput.role = (this.dataUser.role === '') ? true : false
            this.errorInput.username = (this.username === '') ? true : false
            this.errorInput.password = (this.password === '') ? true : false
        },

        handleCreateUser () {
            var dataUser = JSON.stringify({
                "username": this.username,
                "password": this.password,
            })

            this.validataInput()

            for (let item in this.errorInput) {
                if (this.errorInput[item]) {
                    return 'validate false'
                }
            }

            HTTP.post('register/', dataUser)
            .then((res => {
                var user = res.data

                var dataCustomer = new FormData()

                if (this.avatar.length === 0) {
                    var img = document.getElementById('avatar-default')

                    fetch(img.src)
                        .then(res => res.blob())
                        .then(blob => {
                        this.avatar = new File([blob], 'dot.png', blob)
                        dataCustomer.append('avatar', this.avatar)
                        dataCustomer.append('full_name', this.dataUser.full_name)
                        dataCustomer.append('key_customer', Math.floor(Math.random() * 1000000000000))
                        dataCustomer.append('phone', this.dataUser.phone)
                        dataCustomer.append('email', this.dataUser.email)
                        dataCustomer.append('birthday', this.dataUser.birthday)
                        dataCustomer.append('gender', this.dataUser.gender)
                        dataCustomer.append('user', user.id)
                        dataCustomer.append('role', parseInt(this.dataUser.role))

                        console.log(dataCustomer);

                        if (parseInt(this.dataUser.role) === 1) {
                            dataCustomer.append('code_affiliate', this.random(20))
                        }

                        HTTP.post('v1/api/customer/', dataCustomer)
                        .then(() => {
                            this.$router.push({name : 'body-view', params : {path : 'quan-ly-user'} })
                        })
                    })
                }
                else {
                    dataCustomer.append('avatar', this.avatar)
                    dataCustomer.append('full_name', this.dataUser.full_name)
                    dataCustomer.append('key_customer', Math.floor(Math.random() * 1000000000000))
                    dataCustomer.append('phone', this.dataUser.phone)
                    dataCustomer.append('email', this.dataUser.email)
                    dataCustomer.append('birthday', this.dataUser.birthday)
                    dataCustomer.append('gender', this.dataUser.gender)
                    dataCustomer.append('user', user.id)
                    dataCustomer.append('role', parseInt(this.dataUser.role))

                    console.log(dataCustomer);

                    if (parseInt(this.dataUser.role) === 1) {
                        dataCustomer.append('code_affiliate', this.random(20))
                    }

                    HTTP.post('v1/api/customer/', dataCustomer)
                    .then(() => {
                        this.$router.push({name : 'body-view', params : {path : 'quan-ly-user'} })
                    })
                }
            }))
        },

        uploadImg () {
            var input = document.getElementById('file-input')
            input.click()
        },

        uploadImage(event) {
            var url = URL.createObjectURL(event.target.files[0]);
            this.dataUser.avatar = url
            this.avatar = event.target.files[0]
            console.log(this.avatar);
        }, 

        // getData () {
        //     HTTP.get('v1/api/roleuser/')
        //     .then((res) => {
        //         this.dataRole = res.data.results
        //     })
        // }
    },

    // created () {
    //     var img = document.getElementById('avatar-default')

    //     fetch(img.src)
    //         .then(res => res.blob())
    //         .then(blob => {
    //         const file = new File([blob], 'dot.png', blob)
    //         console.log(file)
    //     })

    // }
}
</script>

<style scoped lang='scss'>
    @import 'AddUser.scss';
</style>