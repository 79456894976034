<template>
    <div class="loading w100 h100 border-radius">
        <div class="loading__icon absolute ps-ab-center w100 h100">  
        </div>
    </div>
</template>
<script>
export default {
    name: 'is-loading',
}
</script>

<style lang="scss" scoped>
@import "Isloading.scss"; 
.loading{
    opacity: 0.7;
    background-color: $c-gray;
}
</style>