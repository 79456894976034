@<template>
  <div class="post__container">
    <div v-if="systemShow === ''" class="container">
      <div class="post__header f aic mb15">
        <div class="h2 pr1">Bài viết</div>
        <div class="h5 pl1 border-left brown">Bài viết</div>
      </div>

      <div class="post__body f jcc aic">
        <div class="w50" v-for="(item, index) in dataSystemShow" :key="index">
          <div
            @click="handleChoseSystem(item)"
            class="system-show__item f fdc aic pt15 pb15 border-radius border bg-white-1 cursor"
          >
            <div v-bind:class="'icon--' + item.logo"></div>
            <div class="mt1">
              <p class="p3 align-c">
                {{ item.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ListPost
      v-if="systemShow !== ''"
      :data="data"
      :dataCategory="dataCategory"
      :dataProps="dataProps"
      :dataField="dataField"
      :systemShow="systemShow"
      :isMobile="isMobile"
    />
  </div>
</template>

<script>
import ListPost from "./ListPost/ListPost.vue";

export default {
  components: {
    ListPost,
  },

  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    dataCategory: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    dataProps: {
      type: Array,
      default() {
        return [];
      },
    },
    dataField: {
      type: Object,
      default() {
        return {};
      },
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dataSystemShow: [
        {
          logo: "show-website",
          title: "Bài viết trên Website",
          key: "Website",
          value: 2, // Default: tuỳ thuộc BE trả về mà fix cứng
        },
        {
          logo: "show-forum",
          title: "Bài viết trên Forum",
          key: "Forum",
          value: 1, // Default: tuỳ thuộc BE trả về mà fix cứng
        },
      ],

      systemShow: "",
    };
  },

  watch: {
    "$route.query.system": {
      handler() {
        const system = this.$route.query?.system;
        if (system) {
          const systemData = this.dataSystemShow.find((e) => e.key === system);
          this.systemShow = systemData?.value || "";
        } else {
          this.systemShow = ''
        }
      },
    },
  },

  created() {
    const system = this.$route.query?.system;
    if (system) {
      const systemData = this.dataSystemShow.find((e) => e.key === system);
      this.systemShow = systemData?.value || "";
    }
  },

  methods: {
    handleChoseSystem(item) {
      this.systemShow = item.value;
      this.$router.push({ query: { system: item.key } });
    },
  },
};
</script>

<style scoped lang='scss'>
@import "PostView.scss";
</style>