@<template>
    <div class="popup pop-up-add-folder jcc aic">
        <div class="add-folder">
            <form class="bg-white-1 pt1 pb1 pr1 pl1 border-radius" action="">
                <div class="body f">
                    <div class="body-left mr1">
                        <div class="left__header h5 brown mb1">
                            Chọn sản phẩm
                        </div>
                        <div class="text-input--search left__search f fdc">
                            <label class="mb1 h5 gray" for="">Lựa chọn sản phẩm cho người mua</label>
                            <input class="text-input__el" type="text" placeholder="Nhập sản phẩm bạn muốn tìm">
                        </div>
                        <div class="left__list">
                            <div>
                                <div class="left__list-header-product w100 p5 pt1 pb05 ">
                                    <div class="f aic jcb" style="width: 5%">
                                        <input 
                                          @click="handleSelectAll"
                                          class="form-add-folder__input" 
                                          type="checkbox" 
                                          value="" 
                                          id="flexCheckDefault">
                                    </div>
                                    <div class="align-c p5 gray">
                                        Sản phẩm
                                    </div>
                                    <div class="align-c p5 gray">
                                        Giá sản phẩm
                                    </div>
                                    <div class="align-c p5 gray">
                                        Giá khuyến mãi
                                    </div>
                                    <div class="align-c p5 gray">
                                        Giá bán
                                    </div>
                                </div>
                                <div class="left__list-body-product">
                                    <div 
                                      v-for="(item, key) in item" 
                                      :key="key" 
                                      class="body__item-list w100 f aic jcb p5 pt1 pb1 border-bottom bg-white-1">
                                        <div class=" f aic jcb" style="width: 5%">
                                            <input 
                                              @click="handleSelect"
                                              class="form-add-folder__input" 
                                              type="checkbox" 
                                              :value="item.id"
                                              >
                                        </div>
                                        <div class="align-s f aic p4 blue">
                                            <img class="product-img" :src="item.media[0].file_media" alt="">
                                            <div class="ml1">
                                              <div class="mb025 p4 blue"> {{item.title_product}} </div>
                                              <div class="h6 gray"> Tồn kho: {{item.inventory}} {{item.unit}} </div>
                                            </div>
                                        </div>
                                        <div 
                                          v-if="item.price_product"
                                          class="align-c h6">
                                            {{formatMoney(item.price_product)}}
                                        </div>
                                        <div 
                                          v-if="item.price_sale"
                                          class="align-c h6">
                                            {{formatMoney(item.price_sale)}}
                                        </div>
                                        <div 
                                          v-if="item.price_product"
                                          class="align-c p4 brown">
                                            {{formatMoney(item.price_product)}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="body-right">
                        <div class="right__header h5 mb1">Sản phẩm đã chọn</div>
                        <div class="item-select__list">
                            <div 
                                v-for="(item, index) in itemSelect"
                                :key="index">
                                <div class="item-select__info pt1 pb1 pl1 pr1 border-radius mb1 bg-gray-2" >
                                    <div class="f jcb aic mb1">
                                        <div class="f aic">
                                            <img class="mr1 product-img" :src="item.media[0].file_media" alt="">
                                            <div class="f fdc jcb">
                                                <div class="blue p4 mb025">{{item.title_product}}</div>
                                                <div class="h6">Tồn kho: {{item.inventory}} {{item.unit}}</div>
                                            </div>
                                        </div>
                                        <div class="f jce aic">
                                            <div class="change-quantity f jcc aic pt05 pb05 pl1 pr1 border border-radius bg-white-1">
                                                <!-- <div 
                                                    @click="handleMinus(item)"
                                                    class="change-quantity__minus h5 cursor">-</div> -->
                                                <input 
                                                    v-model="item.quantity"
                                                    type="number" class="input-quantity">
                                                <!-- <div 
                                                    @click="handlePlus(item)"
                                                    class="change-quantity__plus h5 cursor">+</div> -->
                                            </div>
                                            <div style="width: 0px" class="icon--close ml1"></div>
                                        </div>
                                    </div>
                                    <div class="pr2">
                                        <div 
                                            v-if="item.price_product"
                                            class="f jcb aic">
                                            <div class="h5 gray">Giá sản phẩm</div>
                                            <div class="align-c p4">{{formatMoney(item.price_product)}}</div>
                                        </div>
                                        <div 
                                            v-if="item.price_product"
                                            class="f jcb aic">
                                            <div class="h5 gray">Giá khuyến mãi</div>
                                            <div class="align-c p4">{{formatMoney(item.price_sale)}}</div>
                                        </div>
                                        <div 
                                            v-if="item.price_product"
                                            class="f jcb aic">
                                            <div class="h5 gray">Giá bán</div>
                                            <div class="align-c p4 brown">{{formatMoney(item.price_product)}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
                <div class="f aic jce pt1 pr1 pb1 pl1 bg-blue-2">
                    <div 
                      @click="handleClosePopup"
                      class="add-folder__btn btn-black pt075 pb075 pr1 border-radius pl1 cursor white bg-black">
                        Hủy
                    </div>
                    <div
                        @click="handleConfirm" 
                        class="add-folder__btn btn-brown ml1 pt075 pb075 pr1 border-radius cursor pl1 white bg-brown">
                        Xác nhận
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
data() {
    return {
      key: [],
      item: [],
      itemSelect: [],
      wid: ''
    }
  },
  watch: {
        'titleSelect': function() {
            this.itemSelect = []
            this.handleFilter()
            setTimeout(() => {
                this.function()
                this.handleInfo()
            }, 1)
        }
  },
    methods : {

        // formatTime
        formatTime(time) {
            var timeFormat = time.split('T')
            timeFormat[1] = timeFormat[1].split('')
            timeFormat[1] = timeFormat[1].splice(0, 8)
            timeFormat[1] = timeFormat[1].join("")

            return timeFormat[1] + ' ' + timeFormat[0]
        },

        // formatMoney
        formatMoney(value) {
            var x = value;
            x = x.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
            return x
        },

        handleStatus() {
            this.wid = (70/this.key.length)
        },
        handleFilter() {
            if(this.titleSelect == 'Tất cả') {
                for(let i = 0; i < this.item.length; i++) {
                    this.itemSelect.push(this.item[i])
                }
            } else {
                for(let i = 0; i < this.item.length; i++) {
                    if(this.item[i]['Trạng thái'] == this.titleSelect) {
                        this.itemSelect.push(this.item[i])
                    }
                }
            }
        },
        handleMinus(item) {
            console.log(typeof item.quantity)
            item.quantity = item.quantity - 1
        },
        handlePlus(item) {
            console.log(typeof item.quantity)
            item.quantity = item.quantity + 1
        },
        handleSelect(event) {
            var checkbox = event.target
            if (checkbox.checked == true) {
                for(let i = 0; i < this.item.length; i++) {
                    if(checkbox.value == this.item[i].id) {
                        this.itemSelect.push(this.item[i])
                        console.log(this.itemSelect)
                    }
                }
            } 
            if (checkbox.checked == false) {
                for(let i = 0; i < this.itemSelect.length; i++) {
                    if(checkbox.value == this.itemSelect[i].id) {
                        this.itemSelect.splice(i, 1)
                        console.log(this.itemSelect)
                    }
                }
            }
        },
        handleSelectAll(event) {
            var checkbox = event.target
            var input = document.getElementsByClassName('form-add-folder__input')
            if (checkbox.checked == true) {
                for (let i = 0; i < input.length; i ++) {
                input[i].checked = true
                }
                for(let i = 0; i < this.item.length; i++) {
                this.itemSelect.push(this.item[i])
                }
            } 
            if (checkbox.checked == false) {
                for (let i = 0; i < input.length; i ++) {
                input[i].checked = false
                this.itemSelect = []
                }
            }
        },
        handleClosePopup() {
            var popup = document.getElementsByClassName('pop-up-add-folder')[0];
            popup.classList.remove('f')
        },

        handleConfirm() {
            this.handleClosePopup()
            this.$emit('updateProductGift', this.itemSelect)
        },

        getProduct () {
            HTTP.get('v1/api/product/?depth=1')
            .then((res) => {
                this.item = res.data.results
                this.item.forEach(item => {
                    item.quantity = 1
                })
            })
        }
    },
    created() {
        this.getProduct()
    }
}
</script>

<style scoped lang='scss'>
    @import 'AddProductGift.scss';
</style>