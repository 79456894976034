<template>

    <!-- chi tiết khách hàng -->
    <div class="container relative w85">
        <div
        v-if="dataField.props" 
        class="f aic mb2">
            <div class="upcase pr1 h2 border-right"> {{dataField.props.titlecontent}} </div>
            <div class="upcase p5 pr05 pl1 gray">{{dataField.props.titlecontent}}</div>
            <div 
                v-if="$route.params.menu !== 'editInfo'"
                class="f aic">
                <svg width="8" height="12" viewBox="0 0 8 12" class="mr05">
                    <use xlink="http://www.w3.org/2000/svg" href="#next" x="0" y="0"></use>
                </svg>
                <h5 class="brown pl05">Chi tiết {{dataField.props.titlecontent}}</h5>
            </div>
            <div 
                v-if="$route.params.menu === 'editInfo'"
                class="f aic">
                <svg width="8" height="12" viewBox="0 0 8 12" class="mr05">
                    <use xlink="http://www.w3.org/2000/svg" href="#next" x="0" y="0"></use>
                </svg>
                <h5 class="brown pl05">Chỉnh sửa {{dataField.props.titlecontent}}</h5>
            </div>
        </div>
  
        <!-- chỉnh sửa thông tin khách hàng -->
        <AddNew 
            v-if="$route.params.menu === 'editInfo'"
            :dataField='dataField'
            :data='dataList'
            :dataEdit='data'
            @update-success="getData()"
            />

        <!-- chi tiết khách hàng -->
        <div 
            v-if="$route.params.path === 'quan-ly-customer' && $route.params.menu !== 'editInfo'"
            class="f">
            <div class="info f fdc mr2">
                <div class="category mb1 border-radius bg-white-1">
                    <div class="category__header h5">
                        Danh mục
                    </div>
                    <div v-for="(item, index) in category" :key="index">
                        <router-link :to="{ name : 'body-detail-menu', params : { menu : item.name } }">
                            <div 
                                v-if="item.name === $route.params.menu"
                                class="category__item f aic pt1 pb1 border-bottom">
                                <svg width="20" height="16" viewBox="0 0 20 16" class="mr05 f aic">
                                    <use xlink="http://www.w3.org/2000/svg" :href="(item.svg)" x="0" y="0"></use>
                                </svg>
                                <div class="h5 brown pl05">{{item.name}}</div>
                            </div>
                            <div 
                                v-else
                                class="category__item f aic pt1 pb1 border-bottom">
                                <svg width="20" height="16" viewBox="0 0 20 16" class="mr05 f aic">
                                    <use xlink="http://www.w3.org/2000/svg" :href="(item.svg)" x="0" y="0"></use>
                                </svg>
                                <div class="p4 gray pl05">{{item.name}}</div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="info-basic mb1 border-radius bg-white-1">
                    <div class="info-basic__header f aic jcb mb1">
                        <div class="h5">Thông tin khách hàng</div>
                        <router-link 
                            :to="{ name : 'body-detail-menu', params : { menu : 'editInfo' } }"
                            class="blue f aic">
                            <div class="h5">Chỉnh sửa</div>
                            <svg width="19" height="17" viewBox="0 0 19 17" class="ml05 f aic">
                                <use xlink="http://www.w3.org/2000/svg" href="#note" x="0" y="0"></use>
                            </svg>
                        </router-link>
                    </div>
                    <div class="info-basic__list mb1">
                        <div class="f jcb aic mb1">
                            <div>
                                <div class="brown p3">{{data.phone}}</div>
                                <div class="h6">{{data.full_name}}</div>
                            </div>
                            <div>
                                <a 
                                    v-if="data.link_fb_zl"
                                    :href="data.link_fb_zl[0].facebook"
                                    target="_blank">
                                    <img src="../../../assets/AddCustomerPNG/zalo.png" class="mr05" alt="">
                                </a>
                                <a 
                                    v-if="data.link_fb_zl"
                                    :href="data.link_fb_zl[0].zalo"
                                    target="_blank">
                                    <img src="../../../assets/AddCustomerPNG/facebook.png" alt="">
                                </a>
                            </div>
                        </div>
                        <div class="mb1">
                            <div class="h6 gray-1-text mb025">Email</div>
                            <div class="p4">{{data.email}}</div>
                        </div>
                        <div class="mb1">
                            <div class="h6 gray-1-text mb025">Ghi chú</div>
                            <div 
                                v-if="data.note_admin === null || data.note_admin === ''"
                                class="p4">Chưa có ghi chú</div>
                            <div 
                                v-else
                                class="p4">{{data.note_admin}}</div>
                        </div>
                        <div class="mb1">
                            <div class="h6 gray-1-text mb025">Địa chỉ khách hàng</div>
                            <div class="p4">{{data.address}}</div>
                        </div>
                    </div>
                    <div class="info-basic__involved mb1">
                        <div class="h5 mb1">Người liên quan</div>
                        <div 
                            v-for="(item, index) in data.people_relate"
                            :key="index"
                            class="f aic jcb mb1">
                            <div>
                                <span class="p4 mr05 brown">{{item.name}}</span>
                                <span class="p4 gray">{{item.myrole}}</span>
                                <div class="mt025">{{item.phone}}</div>
                            </div>
                            <div class="f aic">
                                <div @click="editCustomerRelate = item , popupCustomerRelate = true">
                                    <svg width="18" height="12" viewBox="0 0 18 12" class="ml05 f aic">
                                        <use xlink="http://www.w3.org/2000/svg" href="#eye" x="0" y="0"></use>
                                    </svg>
                                </div>
                                <div 
                                    @click="deleteRelate(item)"
                                    class="icon--delete ml05"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="level-customer border-radius bg-white-1">
                    <div class="level-customer__header f jcb aic mb1">
                        <div class="h5">Cấp độ khách hàng</div>
                            <!-- <div class="blue f aic">
                                <div class="h5">Chỉnh sửa</div>
                                <svg width="19" height="17" viewBox="0 0 19 17" class="ml05 f aic">
                                    <use xlink="http://www.w3.org/2000/svg" href="#note" x="0" y="0"></use>
                                </svg>
                            </div> -->
                    </div>
                    <div class="level-customer__body">
                        <div class="f aic jcb p4 mb075">
                            <div>Tiềm năng</div>
                            <div class="w20">Thường</div>
                            <div>VIP</div>
                        </div>
                        <div 
                            v-if="data.level_customer"
                            class="f aic jcb mb075" style="height: 12px">
                            <div class="w100 border-radius bg-gray-5" style="height: 6px; background-color: #C7C9D9;">
                                <div 
                                    v-if="data.level_customer.id === 2"
                                    class="level-bar border-radius relative">
                                    <div class="circle"></div>
                                </div>
                                <div 
                                    v-if="data.level_customer.id === 1"
                                    class="level-bar border-radius relative" style="width : 10%">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                        <div class="h5" style="color:#FF8A00;">
                            Còn {{data.point}} điểm
                        </div>
                    </div>
                </div>
        </div>
        <MedicalRecords
            style="width: 40.33%"
            v-if="$route.params.menu == 'Hồ sơ bệnh lý'"
            :data="data"
            @upateStatusPopup='upateStatusPopup'></MedicalRecords>

        <CommentHistory 
            v-if="$route.params.menu == 'Lịch sử bình luận khách hàng'"
            :data="data"/>

        <OrderHistory 
            v-if="$route.params.menu == 'Lịch sử đơn hàng'"
            :data="data"/>
        </div>

        <!-- chi tiết quà -->
        <div v-else-if="$route.params.path === 'quan-ly-gift'">
            <AddNew
                :data="dataList"
                :dataField="dataField"></AddNew>
        </div>

        <div class="hide">
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="next" d="M6.90625 6.25L2.3125 10.875C2.125 11.0625 1.94792 11.0625 1.78125 10.875L1.15625 10.2812C0.989583 10.0938 0.989583 9.91667 1.15625 9.75L4.875 6L1.15625 2.25C0.989583 2.08333 0.989583 1.90625 1.15625 1.71875L1.78125 1.125C1.94792 0.9375 2.125 0.9375 2.3125 1.125L6.90625 5.75C7.07292 5.91667 7.07292 6.08333 6.90625 6.25Z" fill="#BABABA"/>
            </svg>
            <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="bin" d="M8.375 13H9.125C9.3125 13 9.5 12.8438 9.5 12.625V5.875C9.5 5.6875 9.3125 5.5 9.125 5.5H8.375C8.15625 5.5 8 5.6875 8 5.875V12.625C8 12.8438 8.15625 13 8.375 13ZM13.5 2.5H10.9062L9.84375 0.75C9.59375 0.3125 9.09375 0 8.5625 0H5.40625C4.875 0 4.375 0.3125 4.125 0.75L3.0625 2.5H0.5C0.21875 2.5 0 2.75 0 3V3.5C0 3.78125 0.21875 4 0.5 4H1V14.5C1 15.3438 1.65625 16 2.5 16H11.5C12.3125 16 13 15.3438 13 14.5V4H13.5C13.75 4 14 3.78125 14 3.5V3C14 2.75 13.75 2.5 13.5 2.5ZM5.34375 1.59375C5.375 1.5625 5.4375 1.5 5.5 1.5C5.5 1.5 5.5 1.5 5.53125 1.5H8.46875C8.53125 1.5 8.59375 1.5625 8.625 1.59375L9.15625 2.5H4.8125L5.34375 1.59375ZM11.5 14.5H2.5V4H11.5V14.5ZM4.875 13H5.625C5.8125 13 6 12.8438 6 12.625V5.875C6 5.6875 5.8125 5.5 5.625 5.5H4.875C4.65625 5.5 4.5 5.6875 4.5 5.875V12.625C4.5 12.8438 4.65625 13 4.875 13Z" fill="#1A1A1A"/>
            </svg>
            <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="note" d="M12.5625 11.7812C12.5 11.8438 12.5 11.9062 12.5 11.9688V15.5H1.5V4.5H9.03125C9.09375 4.5 9.15625 4.5 9.21875 4.4375L10.2188 3.4375C10.375 3.28125 10.25 3 10.0312 3H1.5C0.65625 3 0 3.6875 0 4.5V15.5C0 16.3438 0.65625 17 1.5 17H12.5C13.3125 17 14 16.3438 14 15.5V10.9688C14 10.75 13.7188 10.625 13.5625 10.7812L12.5625 11.7812ZM17.4375 5.5C18.1562 4.78125 18.1562 3.625 17.4375 2.90625L16.0938 1.5625C15.375 0.84375 14.2188 0.84375 13.5 1.5625L5.3125 9.75L5 12.5938C4.90625 13.4062 5.59375 14.0938 6.40625 14L9.25 13.6875L17.4375 5.5ZM14.375 6.4375L8.5625 12.25L6.5 12.5L6.75 10.4375L12.5625 4.625L14.375 6.4375ZM16.375 3.96875C16.5312 4.09375 16.5312 4.28125 16.4062 4.4375L15.4375 5.40625L13.625 3.5625L14.5625 2.625C14.6875 2.46875 14.9062 2.46875 15.0312 2.625L16.375 3.96875Z" fill="#2577C9"/>
            </svg>
            <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="more" d="M10.5 7.3125C10.5 7.14844 10.3359 6.98438 10.1719 6.98438H7.76562V4.57812C7.76562 4.41406 7.60156 4.25 7.4375 4.25H6.5625C6.37109 4.25 6.23438 4.41406 6.23438 4.57812V6.98438H3.82812C3.63672 6.98438 3.5 7.14844 3.5 7.3125V8.1875C3.5 8.37891 3.63672 8.51562 3.82812 8.51562H6.23438V10.9219C6.23438 11.1133 6.37109 11.25 6.5625 11.25H7.4375C7.60156 11.25 7.76562 11.1133 7.76562 10.9219V8.51562H10.1719C10.3359 8.51562 10.5 8.37891 10.5 8.1875V7.3125ZM13.7812 7.75C13.7812 4.00391 10.7461 0.96875 7 0.96875C3.25391 0.96875 0.21875 4.00391 0.21875 7.75C0.21875 11.4961 3.25391 14.5312 7 14.5312C10.7461 14.5312 13.7812 11.4961 13.7812 7.75ZM12.4688 7.75C12.4688 10.7852 10.0078 13.2188 7 13.2188C3.96484 13.2188 1.53125 10.7852 1.53125 7.75C1.53125 4.74219 3.96484 2.28125 7 2.28125C10.0078 2.28125 12.4688 4.74219 12.4688 7.75Z" fill="#2577C9"/>
            </svg>
            <svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="comment" d="M17.625 11.0938C18.4688 10.25 19 9.1875 19 8C19 5.5 16.5938 3.4375 13.4688 3.09375C12.5 1.28125 10.1875 0 7.5 0C3.90625 0 1 2.25 1 5C1 6.15625 1.5 7.21875 2.375 8.09375C1.875 9.03125 1.1875 9.78125 1.1875 9.8125C1 10 0.9375 10.3125 1.03125 10.5938C1.15625 10.8438 1.40625 11.0312 1.71875 11.0312C3.375 11.0312 4.71875 10.375 5.625 9.8125C5.90625 9.875 6.21875 9.90625 6.5 9.96875C7.5 11.75 9.78125 13 12.5 13C13.125 13 13.75 12.9375 14.3438 12.8125C15.25 13.375 16.5938 14 18.2812 14C18.5625 14 18.8125 13.8438 18.9375 13.5625C19.0312 13.3125 19 13 18.7812 12.7812C18.7812 12.7812 18.0938 12.0312 17.625 11.0938ZM5.34375 8.1875L4.8125 8.53125C4.375 8.84375 3.90625 9.0625 3.46875 9.21875C3.53125 9.0625 3.625 8.90625 3.71875 8.75L4.1875 7.78125L3.40625 7C3 6.59375 2.5 5.90625 2.5 5C2.5 3.125 4.78125 1.5 7.5 1.5C10.1875 1.5 12.5 3.125 12.5 5C12.5 6.90625 10.1875 8.5 7.5 8.5C6.96875 8.5 6.46875 8.46875 5.96875 8.34375L5.34375 8.1875ZM16.5625 10L15.7812 10.7812L16.2812 11.75C16.3438 11.9062 16.4375 12.0625 16.5312 12.2188C16.0625 12.0625 15.625 11.8125 15.1875 11.5312L14.625 11.1875L14.0312 11.3438C13.5312 11.4688 13 11.5 12.5 11.5C10.8125 11.5 9.28125 10.875 8.375 9.96875C11.5625 9.625 14 7.53125 14 5C14 4.90625 13.9688 4.8125 13.9688 4.6875C15.9688 5.15625 17.5 6.46875 17.5 8C17.5 8.90625 16.9688 9.59375 16.5625 10Z" fill="#6F382B"/>
            </svg>
            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="file" d="M8.75 7.5H5.25C5.09375 7.5 5 7.625 5 7.75V8.25C5 8.40625 5.09375 8.5 5.25 8.5H8.75C8.875 8.5 9 8.40625 9 8.25V7.75C9 7.625 8.875 7.5 8.75 7.5ZM8.75 10.5H5.25C5.09375 10.5 5 10.625 5 10.75V11.25C5 11.4062 5.09375 11.5 5.25 11.5H8.75C8.875 11.5 9 11.4062 9 11.25V10.75C9 10.625 8.875 10.5 8.75 10.5ZM3.5 7.25C3.0625 7.25 2.75 7.59375 2.75 8C2.75 8.4375 3.0625 8.75 3.5 8.75C3.90625 8.75 4.25 8.4375 4.25 8C4.25 7.59375 3.90625 7.25 3.5 7.25ZM3.5 10.25C3.0625 10.25 2.75 10.5938 2.75 11C2.75 11.4375 3.0625 11.75 3.5 11.75C3.90625 11.75 4.25 11.4375 4.25 11C4.25 10.5938 3.90625 10.25 3.5 10.25ZM10.5 2H8C8 0.90625 7.09375 0 6 0C4.875 0 4 0.90625 4 2H1.5C0.65625 2 0 2.6875 0 3.5V14.5C0 15.3438 0.65625 16 1.5 16H10.5C11.3125 16 12 15.3438 12 14.5V3.5C12 2.6875 11.3125 2 10.5 2ZM6 1.5C6.25 1.5 6.5 1.75 6.5 2C6.5 2.28125 6.25 2.5 6 2.5C5.71875 2.5 5.5 2.28125 5.5 2C5.5 1.75 5.71875 1.5 6 1.5ZM10.5 14.25C10.5 14.4062 10.375 14.5 10.25 14.5H1.75C1.59375 14.5 1.5 14.4062 1.5 14.25V3.75C1.5 3.625 1.59375 3.5 1.75 3.5H3V4.5C3 4.78125 3.21875 5 3.5 5H8.5C8.75 5 9 4.78125 9 4.5V3.5H10.25C10.375 3.5 10.5 3.625 10.5 3.75V14.25Z" fill="#6F382B"/>
            </svg>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="history" d="M15.75 8C15.7188 3.71875 12.25 0.25 8 0.25C5.84375 0.25 3.875 1.15625 2.46875 2.5625L1.0625 1.15625C0.84375 0.9375 0.4375 1.09375 0.4375 1.4375V5.375C0.4375 5.59375 0.59375 5.75 0.8125 5.75H4.8125C5.15625 5.75 5.3125 5.34375 5.09375 5.125L3.5625 3.625C4.6875 2.46875 6.25 1.75 8 1.75C11.4375 1.75 14.25 4.5625 14.25 8C14.25 11.4688 11.4375 14.25 8 14.25C6.375 14.25 4.9375 13.6562 3.8125 12.6875C3.6875 12.5625 3.4375 12.5625 3.3125 12.6875L2.78125 13.2188C2.625 13.375 2.625 13.625 2.78125 13.7812C4.15625 15 6 15.7812 8 15.75C12.25 15.75 15.75 12.2812 15.75 8ZM10.5 10.8125L10.9688 10.2188C11.0625 10.0312 11.0312 9.8125 10.875 9.6875L8.75 8.125V3.625C8.75 3.4375 8.5625 3.25 8.375 3.25H7.625C7.40625 3.25 7.25 3.4375 7.25 3.625V8.90625L10 10.9062C10.1562 11.0312 10.4062 11 10.5 10.8125Z" fill="#6F382B"/>
            </svg>
            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="shopping" d="M17.25 2C17.4792 2 17.6667 2.09375 17.8125 2.28125C17.9792 2.46875 18.0312 2.67708 17.9688 2.90625L16.5625 9.40625C16.5208 9.57292 16.4271 9.71875 16.2812 9.84375C16.1562 9.94792 16.0104 10 15.8438 10H6.0625L6.375 11.5H15.1875C15.4167 11.5 15.6042 11.5938 15.75 11.7812C15.9167 11.9688 15.9688 12.1771 15.9062 12.4062L15.875 12.5625C16.2917 12.9583 16.5 13.4375 16.5 14C16.5 14.5625 16.3021 15.0312 15.9062 15.4062C15.5312 15.8021 15.0625 16 14.5 16C13.9375 16 13.4583 15.8021 13.0625 15.4062C12.6875 15.0312 12.5 14.5625 12.5 14C12.5 13.6458 12.5938 13.3125 12.7812 13H8.21875C8.40625 13.3125 8.5 13.6458 8.5 14C8.5 14.5625 8.30208 15.0312 7.90625 15.4062C7.53125 15.8021 7.0625 16 6.5 16C5.9375 16 5.45833 15.8021 5.0625 15.4062C4.6875 15.0312 4.5 14.5625 4.5 14C4.5 13.4583 4.6875 13 5.0625 12.625L2.875 1.5H0.375C0.125 1.5 0 1.375 0 1.125V0.375C0 0.125 0.125 0 0.375 0H3.5C3.6875 0 3.84375 0.0625 3.96875 0.1875C4.11458 0.291667 4.20833 0.427083 4.25 0.59375L4.5 2H17.25ZM5.96875 14.5312C6.11458 14.6771 6.29167 14.75 6.5 14.75C6.70833 14.75 6.88542 14.6771 7.03125 14.5312C7.17708 14.3854 7.25 14.2083 7.25 14C7.25 13.7917 7.17708 13.6146 7.03125 13.4688C6.88542 13.3229 6.70833 13.25 6.5 13.25C6.29167 13.25 6.11458 13.3229 5.96875 13.4688C5.82292 13.6146 5.75 13.7917 5.75 14C5.75 14.2083 5.82292 14.3854 5.96875 14.5312ZM13.9688 14.5312C14.1146 14.6771 14.2917 14.75 14.5 14.75C14.7083 14.75 14.8854 14.6771 15.0312 14.5312C15.1771 14.3854 15.25 14.2083 15.25 14C15.25 13.7917 15.1771 13.6146 15.0312 13.4688C14.8854 13.3229 14.7083 13.25 14.5 13.25C14.2917 13.25 14.1146 13.3229 13.9688 13.4688C13.8229 13.6146 13.75 13.7917 13.75 14C13.75 14.2083 13.8229 14.3854 13.9688 14.5312ZM15.2188 8.5L16.3125 3.5H4.8125L5.78125 8.5H15.2188Z" fill="#6F382B"/>
            </svg>
            <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="eye" d="M9 2.5C8.65625 2.53125 8.3125 2.5625 8 2.65625C8.15625 2.90625 8.21875 3.21875 8.25 3.5C8.25 4.46875 7.4375 5.25 6.5 5.25C6.1875 5.25 5.875 5.1875 5.65625 5.03125C5.5625 5.34375 5.5 5.65625 5.5 6C5.5 7.9375 7.0625 9.5 9 9.5C10.9375 9.5 12.5 7.9375 12.5 6C12.5 4.09375 10.9375 2.53125 9 2.53125V2.5ZM17.875 5.5625C16.1875 2.25 12.8125 0 9 0C5.15625 0 1.78125 2.25 0.09375 5.5625C0.03125 5.6875 0 5.84375 0 6C0 6.1875 0.03125 6.34375 0.09375 6.46875C1.78125 9.78125 5.15625 12 9 12C12.8125 12 16.1875 9.78125 17.875 6.46875C17.9375 6.34375 17.9688 6.1875 17.9688 6.03125C17.9688 5.84375 17.9375 5.6875 17.875 5.5625ZM9 10.5C5.90625 10.5 3.0625 8.78125 1.5625 6C3.0625 3.21875 5.90625 1.5 9 1.5C12.0625 1.5 14.9062 3.21875 16.4062 6C14.9062 8.78125 12.0625 10.5 9 10.5Z" fill="#2577C9"/>
            </svg>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="search" d="M13.8906 13.5742C14.0547 13.7201 14.0547 13.875 13.8906 14.0391L13.2891 14.6406C13.125 14.8047 12.9701 14.8047 12.8242 14.6406L9.51562 11.332C9.44271 11.2591 9.40625 11.1862 9.40625 11.1133V10.7305C8.33073 11.6602 7.09115 12.125 5.6875 12.125C4.11979 12.125 2.77995 11.569 1.66797 10.457C0.55599 9.34505 0 8.00521 0 6.4375C0 4.86979 0.55599 3.52995 1.66797 2.41797C2.77995 1.30599 4.11979 0.75 5.6875 0.75C7.25521 0.75 8.59505 1.30599 9.70703 2.41797C10.819 3.52995 11.375 4.86979 11.375 6.4375C11.375 7.84115 10.9102 9.08073 9.98047 10.1562H10.3633C10.4544 10.1562 10.5273 10.1927 10.582 10.2656L13.8906 13.5742ZM2.59766 9.52734C3.45443 10.3841 4.48438 10.8125 5.6875 10.8125C6.89062 10.8125 7.92057 10.3841 8.77734 9.52734C9.63411 8.67057 10.0625 7.64062 10.0625 6.4375C10.0625 5.23438 9.63411 4.20443 8.77734 3.34766C7.92057 2.49089 6.89062 2.0625 5.6875 2.0625C4.48438 2.0625 3.45443 2.49089 2.59766 3.34766C1.74089 4.20443 1.3125 5.23438 1.3125 6.4375C1.3125 7.64062 1.74089 8.67057 2.59766 9.52734Z" fill="#6F382B"/>
            </svg>
        </div>

        <AddMedicalView 
            v-if="popupAddMedical"
            @updateMedical='updateMedical'
            @changeStatusPopup='changeStatusPopup'
            @closePopup='closePopup'
            :statusPopup='statusPopup'
            :dataPopup='dataPopup'></AddMedicalView>

        <PopupNoti></PopupNoti>

        <CustomerRelate 
            v-if="popupCustomerRelate"
            :editCustomerRelate="editCustomerRelate"
            @closePopupRelate='closePopupRelate'
            @updateCustomerRelate='updateCustomerRelate'/>
  </div>
</template>

<script>
import PopupNoti from '@/components/PopupNoti/PopupNoti.vue'
import AddMedicalView from "@/components/CustomerView/DetailCustomer/AddMedicalPopup/AddMedicalView.vue"
import MedicalRecords from '@/components/CustomerView/DetailCustomer/MedicalRecords/MedicalRecords.vue'
import AddNew from '@/components/AddNew/AddNewView.vue'
import CustomerRelate from '@/components/AddNew/AddCustomerRelate/CustomerRelate.vue'
// import AddNewView from '@/components/AddNew/AddNewView.vue'

import CommentHistory from './CommentHistory/CommentHistory.vue'
import OrderHistory from './OrderHistory/OrderHistory.vue'

import { HTTP } from '../../../http-default'

export default {
    components : {
        MedicalRecords,
        AddMedicalView,
        PopupNoti,
        AddNew,
        CustomerRelate,
        CommentHistory,
        OrderHistory
    },

    props : {
        dataField : {
            type : Object,
            default() {
                return {}
            }
        }
    },

    data() {
        return {
            data : {},
            dataList : [],
            category: [
                {name: 'Hồ sơ bệnh lý', svg: '#file'},
                {name: 'Lịch sử chăm sóc', svg: '#history'},
                {name: 'Lịch sử bình luận khách hàng', svg: '#comment'},
                {name: 'Lịch sử đơn hàng', svg: '#shopping'}
            ],
            datainfo: [],
            statusPopup : '',
            dataPopup : {},

            // Trạng thái popup thêm hồ sơ bệnh lý
            popupAddMedical : false,

            // data khach hang lien quan update
            editCustomerRelate : {},
            popupCustomerRelate : false
        }
    },

    watch : {
        'dataField' : function () {
            this.getData()
        }
    },

    methods: {
        // Trạng thái popup thêm hồ sơ bệnh lý
        statusPopupAddMedical () {
            this.popupAddMedical = true
        },

        // Thông tin người liên quan
        closePopupRelate () {
            this.popupCustomerRelate = false
            this.editCustomerRelate = {}
        },

        // xoá người liên quan
        deleteRelate (value) {
            const index = this.data.people_relate.indexOf(value)
            const dataRelatePacth = []
            if (index > -1) this.data.people_relate.splice(index, 1)

            this.data.people_relate.forEach(item => {
                dataRelatePacth.push(item.id)
            })

            var dataPatch = JSON.stringify({
                'people_relate' : dataRelatePacth
            })

            HTTP.patch(this.dataField.props.urlapi + this.$route.params.id + '/', dataPatch)
        },

        updateCustomerRelate (value) {
            for (let i = 0; i < this.data.people_relate.length; i++) {
                if (this.data.people_relate[i].id === value.id) {
                    this.data.people_relate[i] = value
                }
            }

        },

        // update hồ sơ bênh lý khi tạo mới
        updateMedical () {
            this.getData()
        },

        changeStatusPopup (value) {
            this.statusPopup = value
        },

        closePopup() {
            this.popupAddMedical = false
        },

        // update trang thái popup
        upateStatusPopup (value, item) {
            this.statusPopupAddMedical()
            this.statusPopup = value
            this.dataPopup = item
        },

        getSystem() {
            const setting = localStorage.getItem("systemsetting").toLowerCase()
            const results = setting.replaceAll("'", '"')
            this.dataList = JSON.parse(results)
        },

        async getData() {
            // khách hàng
            HTTP.get(this.dataField.props.urlapi + this.$route.params.id + '/?depth=1')
            .then((res) => {
            this.data = res.data
            this.data.link_fb_zl = JSON.parse(this.data.link_fb_zl)
            
                // Hồ sơ bênh lý
                HTTP.get('v1/api/customercare/')
                .then((res) => {
                    this.data.customer_care = []
                    const data = res.data.results
                    data.forEach(item => {
                        if (item.customer == this.$route.params.id ) {
                            item.created_at = item.created_at.split('T')
                            item.created_at[1] = item.created_at[1].split('.')
                            this.data.customer_care.push(item)
                        }
                    });
                })
            })

        },

        setParams () {
            if (this.$route.params.path === 'quan-ly-customer' && !this.$route.params.menu) {
                this.$router.push({name : 'body-detail-menu', params : {menu : 'Hồ sơ bệnh lý'} })
            }
        }
    },

    created() {
        this.getData()
        this.getSystem()
        this.setParams()
    }
}
</script>

<style scoped lang="scss">
@import 'DetailCustomer'
</style>