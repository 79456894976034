<template>
  <div class="container">
    <div class="container-header f aic mb2">
      <div class="qcont h2 pr1 border-right">Khoá học</div>
      <div class="h5 pr05 pl1 brown">Quản lý khoá học</div>
    </div>
    <div class="f aic jcb">
      <div class="container-nav relative f jcb mb2">
        <div class="search-icon absolute">
          <div class="icon icon--search"></div>
        </div>
        <input
          v-model="searchCourse"
          class="pl2 pt075 pb075 pr075"
          type="text"
          style="width: 417px"
          :placeholder="'Tìm kiếm'"
        />
      </div>

      <router-link
        to="course-create/"
        class="bg-brown ph15 pv075 border-radius white p5 mb2"
      >
        Tạo mới khoá học
      </router-link>
    </div>

    <div class="list-course">
      <Loading :is-loading="isLoading" />

      <div
        v-for="(item, index) in dataCourses.filter(e => e.title.includes(searchCourse))"
        :key="index"
        class="course-item bg-white-1 px1 f gap1 cursor"
        @click="$router.push({ name: 'body-detail', params: { id: item.id } })"
      >
        <div>
          <img
            v-if="item.media"
            :src="item.media"
            alt=""
          />
          <img v-else src="@/assets/imgUser.png" alt="" />
        </div>
        <div class="f fdc">
          <p class="h4 brown">{{ item.title }}</p>
          <p class="gray-1-text p6 course-item-description">{{ item.description }}</p>
          <div class="p6 gray-1-text">
            Trạng thái:
            <span class="h7" :class="item.status ? 'green' : 'red'">{{ item.status ? 'Hoạt động' : 'Không hoạt động' }}</span>
            &nbsp; &nbsp;
            <template v-if="item.status">
              <p class="h7 cursor red" @click.stop="updateSatusCourse(false, item.id)">Ngưng hoạt động</p>
            </template>
            <template v-else>
              <p class="h7 cursor green" @click.stop="updateSatusCourse(true, item.id)">Kích hoạt</p>
            </template>
          </div>
          <p class="gray-1-text p6">
            Thời lượng: <span class="black h7">{{ item.duration }}</span>
          </p>
          <p class="gray-1-text p6">
            Giá: <span class="black h7">{{ formatMoney(item.price || 0) }}</span>
          </p>
          <p class="gray-1-text p6">
            Thời gian tạo:
            <span class="black h7">{{ formatDate(item.created_at) }}</span>
          </p>
          <p v-if="item.user_created" class="gray-1-text p6">
            Người tạo:
            <span class="black h7">{{ item.user_created.username }}</span>
          </p>
        </div>
      </div>
    </div>

    <NotiView
      v-if="notiView"
      @closePopup="notiView = false"
      :title="title"
    />
  </div>
</template>

<script>
import { HTTP } from "../../http-default";

import Loading from "@/components/Loading/Loading.vue";
import NotiView from "@/components/NotiView/NotiView.vue";

export default {
  name: "CoursePage",

  components: {
    Loading,
    NotiView,
  },

  data() {
    return {
      dataCourses: [],
      isLoading: true,

      notiView: false,
      logo: "noti-success",
      title: "",

      searchCourse: '',
    };
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      await HTTP.get("course/course/").then((res) => {
        this.dataCourses = res?.data?.data || [];
        this.isLoading = false;
      });
    },

    formatDate(date, _type) {
      const newDate = new Date(date);
      const dformat =
        (_type
          ? [
              newDate.getHours(),
              newDate.getMinutes() < 10
                ? "0" + newDate.getMinutes()
                : newDate.getMinutes(),
            ].join(":") + " "
          : "") +
        [
          newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate(),
          newDate.getMonth() + 1 < 10
            ? "0" + (newDate.getMonth() + 1)
            : newDate.getMonth() + 1,
          newDate.getFullYear(),
        ].join("/");

      return dformat;
    },

    async updateSatusCourse(status, courseId) {
      const payload = {
        status
      }

      await HTTP.post(`course/update-course-status/${courseId}`, payload).then((res) => {
        this.title = res.data.data
        this.notiView = true
        this.getData()
      }).catch((err) => {
        this.notiView = true
        this.logo =  "noti-error"
        this.title = err.message
      })
    },

    formatMoney(value) {
      var x = value;
      x = x.toLocaleString("it-IT", { style: "currency", currency: "VND" });
      return x;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-course {
  display: grid;
  gap: 2em;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
}

.search-icon {
  top: 0.85em;
  left: 0.3em;
}

.qcont:first-letter {
  text-transform: capitalize;
}

.course-item {
  border-radius: 8px;
  overflow: auto;

  & img {
    width: 200px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
  }

  &-description {
    height: 73px;
    display: -webkit-box;          
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
  }
}
</style>