@<template>
    <div>

        <!-- list data -->
        <div
            v-if="dataProps.length == 0 && $route.params.path != 'quan-ly-funnel'" 
            class="product-list-notdata mb1">
            <div @click="handleAddFolder" class="list-header p3 mb15">
                Danh sách {{data.props.titlecontent}}
            </div>
            <div
                v-if="$route.params.path != 'quan-ly-funnel'" 
                class="list-filter f aic mb15">
                <div @click="handleFilter" v-for="item in filter" :key="item" class="filter__item h7 pt05 pb05 pl075 pr075 mr05 border-radius cursor brown bg-brown-1">
                    {{item}}
                </div>
            </div>
            <div class="f fdc aic pt2 pb2 bg-white-1 border-radius">
                <div
                    v-if="$route.params.path != 'quan-ly-funnel'" 
                    class="icon--face-smile mb15"></div>
                <div class="p3">
                    Có vẻ như bạn chưa có {{data.props.titlecontent}} nào
                </div>
                <div class="mt15">
                    <router-link 
                        v-if="$route.params.path.includes('quan-ly-post')"
                        :to="{name : 'add-new-post', params : {typePost : systemShow} }">
                        <div class="f aic pt075 pb075 pr1 pl1 border-radius white bg-brown">
                            <div class="icon--add-white"></div>
                            <div class="h5">Thêm {{data.props.titlecontent}}</div>
                        </div>
                    </router-link>
                    <router-link 
                        v-else
                        :to="{name : 'add-new'}">
                        <div class="f aic pt075 pb075 pr1 pl1 border-radius white bg-brown">
                            <div class="icon--add-white"></div>
                            <div class="h5">Thêm {{data.props.titlecontent}}</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
        <div 
            v-else-if="dataProps.length != 0 && $route.params.path != 'quan-ly-funnel'" 
            class="product-list mb1">
            <div class="f jcb aic mb15">
                <div class="list-header p3">
                    Danh sách {{data.props.titlecontent}}
                </div>
                <div class="mt15">
                    <router-link 
                        v-if="$route.params.path.includes('quan-ly-post')"
                        :to="{name : 'add-new-post', params : {typePost : systemShow} }">
                        <div class="f aic pt075 pb075 pr1 pl1 border-radius white bg-brown">
                            <svg width="14" height="15" viewBox="0 0 14 15" class="mr05">
                            <use xlink="http://www.w3.org/2000/svg" href="#more" x="0" y="0"></use>
                            </svg>
                            <div class="h5">Thêm {{data.props.titlecontent}}</div>
                        </div>
                    </router-link>
                    <router-link 
                        v-else
                        :to="{name : 'add-new'}">
                        <div class="f aic pt075 pb075 pr1 pl1 border-radius white bg-brown">
                            <svg width="14" height="15" viewBox="0 0 14 15" class="mr05">
                            <use xlink="http://www.w3.org/2000/svg" href="#more" x="0" y="0"></use>
                            </svg>
                            <div class="h5">Thêm {{data.props.titlecontent}}</div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!-- <div class="list-filter f aic mb15">
                <div @click="handleFilter" v-for="item in filter" :key="item" class="filter__item h7 pt05 pb05 pl075 pr075 mr05 border-radius cursor brown bg-brown-1">
                    {{item}}
                </div>
            </div> -->
            <div class="f fdc aic w100">
                <ItemList 
                :data="data"
                :titleSelect="selectTitle"
                :function="handleStyle"
                :handleInfo="handleInfoProductFilter"
                :dataProps="dataProps"
                :isMobile="isMobile"
                ></ItemList>
            </div>
        </div>

        <!-- list landing nodata -->
        <div
            v-if="$route.params.path == 'quan-ly-funnel' && dataLanding.length == 0" 
            class="landing-list-notdata">
            <div @click="handleAddFolder" class="list-header p3 mb15">
                Danh sách Trang Landing
            </div>
            <div class="f fdc aic pt2 pb2 border-radius">
                <div class="icon--face-smile"></div>
                <div class="mt15 p3">
                    Có vẻ như bạn chưa có bài viết nào
                </div>
                <div class="mt15">
                    <router-link :to="{name : 'add-new'}">
                        <div class="f aic pt075 pb075 pr1 pl1 border-radius white bg-brown">
                            <div class="icon--add-white"></div>
                            <div class="h5">Tạo trang Landing mới</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

        <!-- list landing data -->
        <div
            v-if="$route.params.path == 'quan-ly-funnel' && dataLanding.length != 0" 
            class="landing-list-notdata">
            <div class="f jcb aic">
                <div class="list-header p3 mb15">
                    Danh sách {{data.props.titlecontent}}
                </div>
                <div class="mt15">
                    <router-link 
                        :to="{name : 'add-new'}">
                        <div class="f aic pt075 pb075 pr1 pl1 border-radius white bg-brown">
                            <svg width="14" height="15" viewBox="0 0 14 15" class="mr05">
                            <use xlink="http://www.w3.org/2000/svg" href="#more" x="0" y="0"></use>
                            </svg>
                            <div class="h5">Thêm {{data.props.titlecontent}}</div>
                        </div>
                    </router-link>
                </div>
            </div>
            <!-- <div class="list-filter f aic mb15">
                <div @click="handleFilter" v-for="item in filter" :key="item" class="filter__item h7 pt05 pb05 pl075 pr075 mr05 border-radius cursor brown bg-brown-1">
                    {{item}}
                </div>
            </div> -->
            <div class="f fdc aic w100">
                <ItemList 
                :data="data"
                :titleSelect="selectTitle"
                :function="handleStyle"
                :handleInfo="handleInfoProductFilter"
                :dataProps="dataLanding"
                :isMobile="isMobile"
                ></ItemList>
            </div>
        </div>
    </div>
</template>

<script>
import ItemList from '@/components/ItemListView/ItemList.vue'
import { HTTP } from '@/http-default'

export default {
    props: {
        data : {
            type : Object,
            default() {
                return {}
            }
        },
        dataProps : {
            type : Array,
            default() {
                return []
            }
        },
        systemShow : {
            type : [String, Number],
            default() {
                return ''
            }
        },

        isMobile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            filter: ['Tất cả', 'Đang bán', 'Dừng bán'],
            dataFolder : [],
            selectTitle: "",
            dataLanding : []
        }
    },
    components: {
        ItemList
    },
    methods: {
        formatTime(time) {
            var timeFormat = time.split('T')
            timeFormat[1] = timeFormat[1].split('')
            timeFormat[1] = timeFormat[1].splice(0, 8)
            timeFormat[1] = timeFormat[1].join("")

            return timeFormat[1] + ' ' + timeFormat[0]
        },

        handleFilter(event) {
            var select = document.getElementsByClassName('filter__item');
            for(let i = 0; i < select.length; i++) {
              select[i].classList.remove('select')
            }
            var button = event.target;
            button.classList.add('select');
            this.selectTitle = button.innerText;
        },
        handleStyle() {
            var item = document.getElementsByClassName('item-list');
            for (let i = 0; i < item.length; i++) {
                item[i].children[4].classList.add('price-style')
            }
            var status = document.getElementsByClassName('item-list');
            for (let i = 0; i < status.length; i++) {
                if (status[i].children[7].innerText == 'Đang bán') {
                   status[i].children[7].classList.add('on-sale')
                }
                else {
                    status[i].children[7].classList.add('stop-sale')
                }
            }
        },
        handleInfoProductFilter() {
            var info = document.getElementsByClassName('item-list')
            var header = document.getElementsByClassName('header')
            header[1].children[1].classList.add('width-fix')
            for(let i = 0; i < info.length; i++) {
                info[i].children[1].classList.add('width-fix')
                var title = info[i].children[1].children[0].children[1].children[0].innerText;
                var inventory = info[i].children[5].innerText;
                info[i].children[1].innerHTML = `
                    <div class="f jcc" style="width: 200px">
                        <div class"mr05 border-radius">
                            <img class="border-radius" style="width: 48px; height: 48px" src="https://ss-images.saostar.vn/pc/1594175247996/avengers-endgame-thor-feature.jpg" alt="">
                        </div>
                        <div class="f fdc jcb ml05">
                            <div class="blue p4 mb025">
                                ${title}
                            </div>
                            <div class="h6">
                                Tồn kho: ${inventory}
                            </div>
                        </div>
                    </div>
                `
            }
        },
        handleAddFolder() {
            var popup = document.getElementsByClassName('pop-up-add-folder')[0]
            var CustomerView = document.getElementsByClassName('Cus-container')[0];
            popup.classList.add('popup-active');
            CustomerView.classList.add('popup-flow');
        },

        getDataLanding () {
            if (this.$route.params.path === 'quan-ly-funnel') {
                HTTP.get('v1/api/landing/') 
                .then((res) => {
                    this.dataLanding = res.data.results
                })
            }
        }
    },

    created () {
        this.getDataLanding()
    }
}
</script>

<style>

</style> 