@<template>
    <div class='popup popup-noti f jcc aic'>
        <div class="noti__conainer f fdc aic pt15 pb15 pl1 pr1 border-radius bg-white-1">
            <div :class="'icon--' + logo" class="mb1"></div>
            <div class="h2 mb05 brown">Thông báo</div>
            <div class="p6 mb15 gray align-c pl2 pr2">{{title}}</div>
            <div class="noti__action f jcc aic w100">
                <div
                    @click="handleClose" 
                    class="w50 pt1 pb1 border-brown border-radius align-c brown cursor">Đóng</div>
                <div
                    v-if="title === 'Xóa đơn đồng thời sẽ hủy đơn. Bạn vẫn muốn tiếp tục?'
                        || title === 'Xác nhận hủy đơn?'"
                    @click="handleConfirm" 
                    class="w50 pt1 pb1 ml1 border-radius align-c bg-brown white cursor">Xác nhận</div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    props : {
        title : {
            type : String,
            default () {
                return ''
            }
        },
        logo : {
            type : String,
            default () {
                return ''
            }
        }
    },

    methods : {
        handleClose() {
            if(this.$route.params.path === 'quan-ly-product') {
                this.$router.push({name : 'body-view'})
            }
            else {
                this.$emit('closePopup')
            }
        },

        handleConfirm () {
            if (this.title === 'Xóa đơn đồng thời sẽ hủy đơn. Bạn vẫn muốn tiếp tục?') {
                HTTP.delete('v1/api/order/' + this.$route.params.id + '/')
                .then(() => {
                    this.$router.push({name : 'body-view', params : {path : 'quan-ly-order'} })
                })
            } else if (this.title === 'Xác nhận hủy đơn?') {
                var dataPacth = JSON.stringify({
                    'status_order' : 4
                })

                HTTP.patch('v1/api/order/' + this.$route.params.id + '/', dataPacth)
                .then(() => {
                    this.$emit('updateData')
                    this.handleClose()
                })
            }
        }
    }
}
</script>

<style scoped lang='scss'>
    @import 'NotiView.scss';
</style>