@<template>
    <div class="order-detail__container">
        <div class="order-detail__title px1">
            <div class="p6">
                Sản phẩm
            </div>
            <div class="p6 f jcc aic">
                Số lượng
            </div>
            <div class="p6 f jcc aic">
                Đơn giá
            </div>
            <div class="p6 f jcc aic">
                Giá
            </div>
        </div>
        <div class="order-detail__list f fdc ais" v-if="dataSubOrder.length > 0">
            <div 
                v-for="(item, index) in dataSubOrder"
                :key="index"
                class="order-detail__item w100 border-radius px1 bg-gray-2">
                <div class="p6 f jcc aic">
                    <img :src=" IMAGE_URL + item.media_product[0].file_media" alt="" width="48" height="48" class="border-radius">
                    <p class="item__name pl1 blue semibold">
                        {{item.title_product}}
                    </p>
                </div>
                <div class="p6 gray regular f jcc aic">
                    x{{item.quantity_order}}
                </div>
                <div class="p6 f jcc aic">
                    <p class="gray regular ">
                        {{formatPrice(item.total_price, 'VNĐ')}}
                    </p>
                </div>
                <div class="p6 f jcc aic">
                    <p class="blue semibold">
                        {{formatPrice(item.price_sale, 'Đ')}} <br>
                        <span class="gray regular item__name--through">{{formatPrice(item.price_product, 'Đ')}}</span>
                    </p> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    data () {
        return {
            dataOrder : {},
            dataSubOrder : [],
            IMAGE_URL: 'https://f004.backblazeb2.com/file/media-drmom/',
        }
    },

    methods : {
        async getData () {
            await HTTP.get('v1/api/order/' + this.$route.params.id + '/')
            .then((res) => {
                this.dataOrder = res.data
            })

            await HTTP.get('v1/api/suborder/')
            .then((res) => {
                const data = res.data.results

                this.dataSubOrder = data.filter(item => item.order === this.dataOrder.id)
            })
        },

        formatPrice(value, currency) {
            // currency: 'VNĐ' | 'Đ'
            let val = val > 999 ? (value/1000).toFixed(3) : value;
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' ' + currency;
        }
    },

    mounted () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'MyOrderDetail.scss';
</style>