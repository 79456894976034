@<template>
    <div class="popup popup-noti-create f jcc aic">
        <div 
            v-if="saveLikeUserSystem"
            class="noti-create__container p1 border-radius bg-white-1">
            <div class="icon-noti f fdc jcc aic mb1">
                <div class="icon--noti-warning mb1"></div>
                <div class="h2 gray align-c">Thông báo</div>
            </div>
            <div class="noti__body mb15">
                <div class="p6 gray-1-text align-c mb05">
                    Xác nhận thêm người mới liên quan và lưu mới đối tượng này là người dùng trên hệ thống?
                </div>
                <div class="p6 gray-1-text align-c">
                    Thông tin tài khoản của người dùng sẽ được gửi tới mail “{{this.dataInput.email}}”
                </div>
            </div>
            <div class="noti-create__action w100 f jcc aic">
                <div 
                    @click="handleClosePopup('cancel')"
                    class="pt075 pb075 pl1 pr1 h6 brown border-radius border cursor">
                    Huỷ bỏ
                </div>
                <div 
                    @click="handleSubmit"
                    class="pt075 pb075 pl1 pr1 ml1 h6 border-radius bg-brown white cursor">
                    Xác nhận
                </div>
            </div>
        </div>

        <div 
            v-if="!saveLikeUserSystem"
            class="noti-create__container p1 border-radius bg-white-1">
            <div class="icon-noti f fdc jcc aic mb1">
                <div class="icon--noti-success mb1"></div>
                <div class="h2 gray align-c">Thông báo</div>
            </div>
            <div class="noti__body mb15">
                <div class="p6 gray-1-text align-c mb05">
                    Thêm mới người liên quan thành công!
                </div>
            </div>
            <div class="noti-create__action w100 f jcc aic">
                <div 
                    @click="handleClosePopup('close')"
                    class="pt075 pb075 pl1 pr1 h6 brown border-radius border cursor">
                    Đóng
                </div>
                <div 
                    @click="handleContinuteAdd"
                    class="pt075 pb075 pl1 pr1 ml1 h6 border-radius bg-brown white cursor">
                    Tiếp tục thêm mới
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props : {
        dataInput : {
            type : Object,
            default() {
                return {}
            }
        },
        saveLikeUserSystem : {
            type : Boolean,
            default () {
                return false
            }
        } 
    },

    data () {
        return {
        }
    },

    methods : { 
        handleSubmit () {
            this.$emit('updateCustomerRelate')
            this.$emit('resetSaveLikeUser')
        },

        handleClosePopup (value) {
            if (value === 'cancel') {
                this.$emit('closePopup', 'noti-create__container-cancel')
            } else if (value === 'close') {
                this.$emit('closePopup', 'noti-create__container-close')
            }
        },

        handleContinuteAdd () {
            this.handleClosePopup('cancel')
            this.$emit('resetFormAddCustomerRelate')
        }
    }
}
</script>

<style scoped lang='scss'>
    @import 'NotiCreate.scss';
</style>