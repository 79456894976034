@<template>
  <div
    v-if="$route.params.path !== 'forums'"
    :class="$attrs.isMobile ? 'container-mobile' : 'container'"
  >
    <AllCategory
      ref="AllCategory"
      v-if="
        this.$route.fullPath.includes(
          '/' + this.$route.params.path + '/category/all-category'
        ) &&
        !$route.params.id &&
        $route.params.type !== 'category'
      "
      :data="dataField"
      :dataProps="dataProps"
    ></AllCategory>

    <Layout1View
      v-else-if="
        this.$route.fullPath.includes('/quan-ly-customer') ||
        this.$route.fullPath == '/quan-ly-gift' ||
        (this.$route.fullPath == '/quan-ly-order' &&
          !$route.params.id &&
          $route.params.type !== 'category')
      "
      :data="data"
      :dataCategory="dataCategory"
      :dataProps="dataProps"
      :dataField="dataField"
    ></Layout1View>

    <PostView
      v-else-if="
        $route.params.path === 'quan-ly-post' &&
        !$route.params.id &&
        $route.params.type !== 'category'
      "
      :data="data"
      :dataCategory="dataCategory"
      :dataProps="dataProps"
      :dataField="dataField"
      :isMobile="$attrs.isMobile"
    />

    <UserAdmin
      v-else-if="
        $route.params.path === 'quan-ly-user' &&
        !$route.params.id &&
        $route.params.type !== 'category'
      "
      :isMobile="$attrs.isMobile"
    />

    <DetailCategory
      v-else-if="
        $route.params.path &&
        $route.params.id &&
        $route.params.type === 'category'
      "
      :data="data"
      :dataCategory="dataCategory"
      :dataProps="dataProps"
      :dataField="dataField"
      :isMobile="$attrs.isMobile"
    />

    <CoursePage
      v-else-if="
        $route.params.path === 'course' &&
        !$route.params.id 
      "
      :isMobile="$attrs.isMobile"
    />

    <CourseCreatePage
      v-else-if="
        $route.params.path === 'course-create' &&
        !$route.params.id 
      "
      :isMobile="$attrs.isMobile"
    />

    <SchedulePage
      v-else-if="
        $route.params.path === 'schedule' &&
        !$route.params.id 
      "
      :isMobile="$attrs.isMobile"
    />

    <Layout2View
      v-else
      :data="data"
      :dataCategory="dataCategory"
      :dataProps="dataProps"
      :dataField="dataField"
    ></Layout2View>

    <AddFolderView
      @updateDataCategory="updateDataCategory"
      :dataField="dataField"
    ></AddFolderView>
  </div>
</template>

<script>
import Layout1View from "@/components/Layout1/Layout1View.vue";
import Layout2View from "@/components/Layout2/Layout2View.vue";
import AddFolderView from "@/components/ProductView/AddFolderPopup/AddFolderPopup.vue";
import AllCategory from "@/components/AllCategory/AllCategory.vue";
import PostView from "@/components/PostView/PostView.vue";
import UserAdmin from "@/components/UserAdmin/UserAdmin.vue";
import DetailCategory from "@/components/DetailCategory/DetailCategory.vue";

import CoursePage from '@/components/Course/CoursePage.vue'
import CourseCreatePage from '@/components/Course/CourseCreatePage.vue'
import SchedulePage from '@/components/Schedule/SchedulePage.vue'


import { HTTP } from "../../http-default";

export default {
  components: {
    Layout1View,
    Layout2View,
    AddFolderView,
    AllCategory,
    PostView,
    UserAdmin,
    DetailCategory,
    CoursePage,
    CourseCreatePage,
    SchedulePage,
  },
  data() {
    return {
      data: [],
      dataField: {},
      dataProps: [],
      dataCategory: {},
      path: this.$route.fullPath,
    };
  },
  watch: {
    $route: function () {
      this.fetchData();
    },
  },
  methods: {
    updateDataCategory() {
      HTTP.get(this.dataField.props?.urlapi + "?depth=1").then((res) => {
        this.dataProps = res.data.results;

        for (let i = 0; i < this.data.length; i++) {
          if (
            this.data[i].props.titlecontent ==
              "chuyên mục " + this.dataField.props.titlecontent &&
            this.data[i].subparent == this.dataField.name
          ) {
            this.dataCategory = this.data[i];
          }
        }
      });

      if (this.$route.path.includes("/category/all-category")) {
        this.$refs.AllCategory.getData();
      }
    },

    fetchData() {
      const setting = localStorage.getItem("systemsetting").toLowerCase();
      const results = setting.replaceAll("'", '"');
      this.data = JSON.parse(results);
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].path == "/" + this.$route.params.path) {
          this.dataField = this.data[i];
        }
      }
      // HTTP.get(this.dataField.props?.urlapi + '?depth=1')
      if (this.dataField.props?.urlapi) {
        HTTP.get(this.dataField.props?.urlapi + "?depth=1").then((res) => {
          this.dataProps = res.data.results;
        });
      }

      for (let i = 0; i < this.data.length; i++) {
        if (
          this.data[i].props?.titlecontent ==
            "chuyên mục " + this.dataField.props?.titlecontent &&
          this.data[i].subparent == this.dataField.name
        ) {
          this.dataCategory = this.data[i];
        }
      }
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
.container {
  padding: 2em 0px;
}
</style>