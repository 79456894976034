var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"course-web__container bg-gray-2"},[(!_vm.isMobile)?_c('HeaderType2',{staticClass:"z10",attrs:{"dataProductCart":_vm.dataProductCart},on:{"updateProductCart":_vm.updateProductCart}}):_c('HeaderLanding'),_c('img',{staticClass:"img__bg-1",attrs:{"loading":"lazy","src":require("@/components/Landingpage/imgLanding/intro_img_1.png"),"alt":""}}),_c('img',{staticClass:"img__bg-2",attrs:{"loading":"lazy","src":require("@/components/Landingpage/imgLanding/intro_img_2.jpg"),"alt":""}}),_c('img',{staticClass:"img__bg-3",attrs:{"loading":"lazy","src":require("@/components/Landingpage/imgLanding/intro_img_3.jpg"),"alt":""}}),_c('div',{staticClass:"container relative z10"},[_c('div',{staticClass:"f aic jcb"},[_c('div',{staticClass:"container-nav relative f jcb mb2 w100"},[_vm._m(0),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchCourse),expression:"searchCourse"}],staticClass:"pl2 pt075 pb075 pr075",style:(("width: " + (_vm.isMobile ? '100%' : '417px') + " ")),attrs:{"type":"text","placeholder":'Tìm kiếm'},domProps:{"value":(_vm.searchCourse)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchCourse=$event.target.value}}})])]),_c('div',{staticClass:"list-course"},[_c('Loading',{attrs:{"is-loading":_vm.isLoading}}),_vm._l((_vm.dataCourses.filter(function (e) { return e.title.includes(_vm.searchCourse); }
        )),function(item,index){return _c('div',{key:index,staticClass:"course-item bg-white-1 px1 f gap1 cursor",on:{"click":function () {
            if (
              _vm.dataUserCourses.find(function (elm) { return elm.course.id === item.id; }) &&
              _vm.dataUserCourses.find(function (elm) { return elm.course.id === item.id; })
                .is_confirm
            ) {
              _vm.$router.push(("/course-website/" + (item.id)));
            }
          }}},[_c('div',[(item.media)?_c('img',{attrs:{"src":item.media,"alt":""}}):_c('img',{attrs:{"src":require("@/assets/imgUser.png"),"alt":""}})]),_c('div',{staticClass:"f fdc w100"},[_c('p',{staticClass:"h4 brown"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"gray-1-text p6 course-item-description"},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('p',{staticClass:"gray-1-text p6"},[_vm._v(" Số lượng học viên: "),_c('span',{staticClass:"black h7"},[_vm._v(_vm._s(item.user_course_count))])]),_c('p',{staticClass:"gray-1-text p6"},[_vm._v(" Thời lượng: "),_c('span',{staticClass:"black h7"},[_vm._v(_vm._s(item.duration))])]),_c('p',{staticClass:"gray-1-text p6"},[_vm._v(" Giá: "),_c('span',{staticClass:"black h7"},[_vm._v(_vm._s(_vm.formatMoney(item.price || 0)))])]),_c('p',{staticClass:"gray-1-text p6"},[_vm._v(" Thời gian tạo: "),_c('span',{staticClass:"black h7"},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]),(item.user_created)?_c('p',{staticClass:"gray-1-text p6"},[_vm._v(" Người tạo: "),_c('span',{staticClass:"black h7"},[_vm._v(_vm._s(item.user_created.username))])]):_vm._e(),_c('p',{staticClass:"gray-1-text p6"},[_vm._v(" Thời gian tạo: "),_c('span',{staticClass:"black h7"},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]),(!_vm.dataUserCourses.find(function (elm) { return elm.course.id === item.id; }))?_c('div',{staticClass:"h7 green align-r",on:{"click":function($event){$event.stopPropagation();return _vm.registerCourse(item.id)}}},[_vm._v(" Đăng kí ")]):[_c('div',{staticClass:"f aic gap05 jce"},[(
                  _vm.dataUserCourses.find(function (elm) { return elm.course.id === item.id; }) &&
                  _vm.dataUserCourses.find(function (elm) { return elm.course.id === item.id; })
                    .is_confirm
                )?[_c('p',{staticClass:"h7 green align-r"},[_vm._v("Đã đăng kí")]),_c('img',{staticStyle:{"width":"16px","height":"16px","object-fit":"cover"},attrs:{"src":require("@/assets/complate.png"),"alt":""}})]:[_c('p',{staticClass:"h7 red"},[_vm._v("Chờ xác nhận")])]],2)]],2)])})],2)]),(_vm.notiView)?_c('NotiView',{attrs:{"title":_vm.title},on:{"closePopup":function($event){_vm.notiView = false}}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-icon absolute"},[_c('div',{staticClass:"icon icon--search"})])}]

export { render, staticRenderFns }