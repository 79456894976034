@<template>
    <div 
        v-if="addCustomerSystem"
        class="popup popup__order-customer f jcc aic">
        <div class="order-customer__container bg-white-1 border-radius">
            <div class="p1">
                <div class="h5 brown">
                    Chọn khách hàng  
                </div>
            </div>
            <div class="p1">
                <input 
                    class="icon--search w100 pt075 pb075 pl2 pr075 h6"
                    type="text"
                    placeholder="Nhập số điện thoại, họ tên khách hàng của bạn">
            </div>
            <div class="order-customer__list p1">
                <div
                    v-for="(item, index) in dataCustomer"
                    :key="index">
                    <div class="order-customer__item mb1 border-radius bg-white-1 shadow">
                        <div class="f jcb aic p1 border-bottom">
                            <div class="f aic">
                                <div class="f jcc aic">
                                    <img 
                                        class="image__product border-radius"
                                        :src="item.avatar" alt="">
                                </div>
                                <div class="f fdc ais ml05">
                                    <div class="h2 brown">
                                        {{item.full_name}}
                                    </div>
                                    <div class="h6 gray">
                                        {{item.email}}
                                    </div>
                                </div>
                            </div>
                            <div class="select-input">
                                <input 
                                    @change="handleSelect(item)"
                                    type="radio" 
                                    name="customer-order" id="">
                            </div>
                        </div>
                        <div class="f fdc p1">
                            <div class="f jcb aic mb05">
                                <h6 class="gray">Số điện thoại</h6>
                                <div class="p4 black">{{item.phone}}</div>
                            </div>
                            <div class="f jcb aic mb05">
                                <h6 class="gray">Ghi chú</h6>
                                <div
                                    v-if="item.note_add == null || item.note_add == ''" 
                                    class="p4 black">Không có ghi chú nào</div>
                                <div
                                    v-else
                                    class="p4 black">{{item.note_add}}</div>
                            </div>
                            <div class="f jcb aic mb1">
                                <h6 class="gray">Địa chỉ đặt hàng</h6>
                                <div class="p4 black">{{item.address}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="order-customer__action p1 f jce aic">
                <div 
                    @click="handleClose"
                    class="pl1 pr1 pt075 pb075 p5 border-radius bg-black white cursor">
                    Hủy bỏ
                </div>
                <div 
                    @click="handleSubmit"
                    class="ml1 pl1 pr1 pt075 pb075 p5 border-radius bg-brown white cursor">
                    Xác nhận
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    props : {
        addCustomerSystem : Boolean
    },
    
    data () {
        return {
            dataCustomer : [],
            customerSelect : {}
        }
    },

    methods : {
        handleSelect (value) {
            this.customerSelect = value
        },

        handleClose () {
            this.$emit('updateAddCustomerSystem', false)
        },

        handleSubmit() {
            this.$emit('updateAddCustomerSystem', true)
            this.$emit('updateCustomerSelect', this.customerSelect)
        },

        getData () {
            HTTP.get('v1/api/customer/')
            .then((res) => {
                this.dataCustomer = res.data.results
            })
        }
    },

    created() {
        this.getData()
    }
}
</script>

<style scoped lang="scss">
    @import 'AddCustomerOrder.scss';
</style>