@<template>
    <div class="">
        <AddLanding 
            :dataAdd="dataField"
            :dataEdit='dataLanding'/>
    </div>
</template>

<script>
import AddLanding from '@/components/AddNew/AddLanding/AddLanding.vue'
import { HTTP } from '@/http-default'
export default {
    props : {
        dataField : {
            type : Object,
            default() {
                return {}
            }
        }
    },
    data () {
        return {
            dataLanding : {}
        }
    },
    components : {
        AddLanding
    },
    methods : {
        getData () {
            HTTP.get('v1/api/landing/' + this.$route.params.id + '/')
            .then((res) => {
                this.dataLanding = res.data
            })
        }
    },

    created () {
        this.getData()
    }
    
}
</script>

<style scoped lang='scss'>
    @import 'DetailLanding.scss';
</style>