<template>
  <div class="v-expand__container">
    <div class="v-expand__label">
      <div class="relative" :class="[labelClass]" @click="expand">
        <slot name="title" :expanded="expanded" />
        <span class="cursor f aic gap2 top-center absolute">
          <slot name="subfixIcon" :expanded="expanded" />
          <div v-if="hasIcon" class="icon-expand" type="bottom" :color="iconColor" :class="expanded && 'reverse'"></div>
        </span>
      </div>
    </div>
    <div class="v-expand__content">
      <div class="v-expand__slot" :class="expanded && 'expanded'">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpandedComponent",
  props: {
    label: {
      type: String,
      default: () => ''
    },

    bgColor: {
      type: String,
      default: () => 'white'
    },

    labelClass: {
      type: String,
      default: ''
    },

    hasBorder: {
      type: Boolean,
      default: () => false
    },

    defaultOpen: {
      type: Boolean,
      default: () => false
    },

    iconColor: {
      type: String,
      default: () => '#303439'
    },

    hasIcon: {
      type: Boolean,
      default: () => true
    }
  },

  watch: {
    defaultOpen: {
      handler () {
        this.expanded = this.defaultOpen
      }
    }
  },

  data() {
    return {
      expanded: false,
    }
  },

  created() {
    this.expanded = this.defaultOpen
  },

  methods: {
    expand() {
      this.expanded = !this.expanded
    }
  }
}
</script>

<style lang="scss" scoped>
.v-expand {
  &__label {
    height: 100%;
  }

  &__content {
    overflow: hidden;
  }

  &__slot {
    margin-top: -100%;
    transition: margin-top 0.6s;
    overflow: auto;
  }
}

.border-radius-v{
  border-radius: 8px 8px 0px 0px;
}

.top-center {
  top: 50%;
  transform: translateY(-50%);
  right: 1em;
}

.expanded {
  margin-top: 0;
}

.reverse {
  transform: rotate(-45deg) !important;
  transition: 0.6s;
}

.icon-expand {
  border-top: 1px solid #292929;
  border-right: 1px solid #292929;
  width: 8px;
  aspect-ratio: 1;
  transform: rotate(135deg);
}
</style>