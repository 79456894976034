@<template>
  <div
    class="user-admin__container"
    :class="$attrs.isMobile ? 'ph1 pt1' : 'container'"
  >
    <!-- header -->
    <div>
      <div class="container-header f aic" :class="[!$attrs.isMobile && 'mb2']">
        <div class="qcont h2 pr1 border-right">User</div>
        <div class="h5 pr05 pl1 brown">User</div>
      </div>
      <div class="container-nav f jcb mb2">
        <div class="relative">
          <div style="top: 33%" class="search-icon absolute">
            <div class="icon icon--search"></div>
          </div>
          <input
            @keypress="handleFilter()"
            v-model="keyFilter"
            class="pl2 pt075 pb075 pr075"
            type="text"
            :style="`width: ${isMobile ? '45vw' : '417px'}`"
            placeholder="Nhập mã/ tên user cần tìm"
          />
        </div>
        <router-link
          :to="{ name: 'add-new' }"
          class="f jcb aic pt05 pb05 pl075 pr075 border-radius bg-brown cursor"
        >
          <div>
            <svg width="14" height="15" viewBox="0 0 14 15" class="mr05">
              <use
                xlink="http://www.w3.org/2000/svg"
                href="#more"
                x="0"
                y="0"
              ></use>
            </svg>
          </div>
          <div class="p5 white ml05">Tạo mới user</div>
        </router-link>
      </div>
    </div>

    <!-- body -->
    <div class="user-admin__body">
      <div class="h3 mb15">
        Số lượng user <span class="orange">({{ handleFilter().length }})</span>
      </div>
      <div class="f aic mb15">
        <div class="p4 gray-1-text pr05 border-right">Bộ lọc</div>
        <div class="f aic h6 gray-1-text pl05">
          Trạng thái:
          <span class="p4 brown">Tất cả</span>
          <div class="icon--arrown ml05"></div>
        </div>
      </div>
      <div v-if="!isMobile" class="user-admin">
        <!-- title -->
        <div class="user-admin__title f aic px1">
          <input
            @click="handleCheckAll"
            v-model="selectAll"
            :checked="selectAll"
            type="checkbox"
            name=""
            id=""
          />
          <div class="p6">STT</div>
          <div class="p6">Họ tên user</div>
          <div class="p6">Loại user</div>
          <div class="p6">Mã affiliate</div>
          <div class="p6">Số điện thoại</div>
          <div class="p6">Ngày sinh</div>
          <div class="p6">Điểm khách hàng</div>
          <div class="p6">Thao tác</div>
        </div>

        <!-- item -->
        <div class="user-admin__list f fdc ais">
          <div
            class="w100"
            v-for="(item, index) in handleFilter()"
            :key="index"
          >
            <!-- user co customer -->
            <div
              v-if="item.customer"
              class="user-admin__item w100 aic px1 border-radius bg-white-1 mb1"
            >
              <input
                @change="handleCheckSelect"
                v-model="item.select"
                :checked="item.select"
                type="checkbox"
                name=""
                id=""
              />
              <div style="text-align: left" class="p6">{{ index + 1 }}</div>
              <div class="f aic">
                <img class="image_user" :src="item.customer.avatar" alt="" />
                <div class="f fdc ais ml05">
                  <div class="mb025 p4 blue">{{ item.customer.full_name }}</div>
                  <div class="h6 gray-1-text">{{ item.customer.email }}</div>
                </div>
              </div>
              <div class="">
                <div
                  class="w100 h6 blue align-c"
                >
                  {{ item.customer && item.customer.role && item.customer.role.title ? item.customer.role.title : 'Chưa gán' }}
                </div>
              </div>
              <div class="h6 gray-1-text">
                {{ item.customer.code_affiliate }}
              </div>
              <div class="h6 gray-1-text">
                {{ item.customer.phone }}
              </div>
              <div class="p4 gray-1-text">
                {{ item.customer.birthday }}
              </div>
              <div class="h6 gray-1-text">{{ item.customer.point }} điểm</div>
              <router-link
                :to="{ name: 'body-detail', params: { id: item.id } }"
                class="p4 blue cursor align-c"
              >
                Xem chi tiết
              </router-link>
            </div>

            <!-- user ko cos customer -->
            <div
              v-if="!item.customer"
              class="user-admin__item w100 aic px1 border-radius bg-white-1 mb1"
            >
              <input
                @change="handleCheckSelect"
                v-model="item.select"
                :checked="item.select"
                type="checkbox"
                name=""
                id=""
              />
              <div style="text-align: left" class="p6">{{ index + 1 }}</div>
              <div class="f aic">
                <img class="image_user" src="../../assets/imgUser.png" alt="" />
                <div class="f fdc ais ml05">
                  <div class="mb025 p4 blue">{{ item.username }}</div>
                  <div class="h6 gray-1-text">{{ item.email }}</div>
                </div>
              </div>
              <div class="">
                <div
                  class="w100 h6 blue align-c"
                >
                  {{ item.customer && item.customer.role && item.customer.role.title ? item.customer.role.title : 'Chưa gán' }}
                </div>
              </div>
              <div class="h6 gray-1-text">Chưa cập nhật</div>
              <div class="h6 gray-1-text">Chưa cập nhật</div>
              <div class="p4 gray-1-text">Chưa cập nhật</div>
              <div class="h6 gray-1-text">Chưa cập nhật</div>
              <router-link
                :to="{ name: 'body-detail', params: { id: item.id } }"
                class="p4 blue cursor align-c"
              >
                Xem chi tiết
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="list-user-mobile">
        <div
          class="list-user-mobile__item border-radius bg-white-1"
          v-for="(item, index) in handleFilter()"
          :key="index"
        >
          <!-- user co customer -->
          <div
            v-if="item.customer"
            class="w100 aic px1 border-radius bg-white-1 mb1"
          >
            <input
              @change="handleCheckSelect"
              v-model="item.select"
              :checked="item.select"
              type="checkbox"
              name=""
              id=""
            />
            <div class="f aic">
              <img class="image_user" :src="item.customer.avatar" alt="" />
              <div class="f fdc ais ml05">
                <div class="mb025 p4 blue">{{ item.customer.full_name }}</div>
                <div class="h6 gray-1-text">{{ item.customer.email }}</div>
              </div>
            </div>
            <div class="f jcb aic">
							<p>
								Loại user:
							</p>
              <div
                class="h6 gray-1-text"
                v-if="item.username === 'admin'"
              >
                Admin
              </div>
              <div
                class="h6 blue"
                v-if="item.username !== 'admin' && item.customer"
              >
                User {{ item.id }}
              </div>
            </div>
            <div class="f aic jcb">
							<p>Mã affiliate</p>
							<p class="h6 gray-1-text">
								{{ item.customer.code_affiliate }}
							</p>
            </div>
						<div class="f aic jcb">
							<p>Số điện thoại</p>
							<p class="h6 gray-1-text">
								{{ item.customer.phone }}
							</p>
            </div>
						<div class="f aic jcb">
							<p>Ngày sinh</p>
							<p class="h6 gray-1-text">
								{{ item.customer.birthday }}
							</p>
            </div>
						<div class="f aic jcb">
							<p>Điểm khách hàng</p>
							<p class="h6 gray-1-text">
								{{ item.customer.point }}
							</p>
            </div>
            <router-link
              :to="{ name: 'body-detail', params: { id: item.id } }"
              class="p4 blue cursor align-c"
            >
              Xem chi tiết
            </router-link>
          </div>

          <!-- user ko co customer -->
          <div
            v-if="!item.customer"
            class="w100 px1 border-radius bg-white-1 mb1"
          >
            <input
              @change="handleCheckSelect"
              v-model="item.select"
              :checked="item.select"
              type="checkbox"
              name=""
              id=""
            />
            <div class="f aic">
              <img class="image_user" src="../../assets/imgUser.png" alt="" />
              <div class="f fdc ais ml05">
                <div class="mb025 p4 blue">{{ item.username }}</div>
                <div class="h6 gray-1-text">{{ item.email }}</div>
              </div>
            </div>
						<div class="f jcb aic">
							<p>
								Loại user:
							</p>
							<div
                class="h6 gray-1-text"
                v-if="item.username === 'admin'"
              >
                Admin
              </div>
              <div
                class="h6 blue"
                v-if="item.username !== 'admin'"
              >
                User {{ item.id }}
              </div>
            </div>
            <router-link
              :to="{ name: 'body-detail', params: { id: item.id } }"
              class="p4 blue cursor align-c"
            >
              Xem chi tiết
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <TabBarView
      v-if="dataSelect.length !== 0"
      :dataSelect="dataSelect"
      @getData="() => {
        dataSelect = []
        getData()
      }"
    />

    <div class="hide">
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="more"
          d="M10.5 7.3125C10.5 7.14844 10.3359 6.98438 10.1719 6.98438H7.76562V4.57812C7.76562 4.41406 7.60156 4.25 7.4375 4.25H6.5625C6.37109 4.25 6.23438 4.41406 6.23438 4.57812V6.98438H3.82812C3.63672 6.98438 3.5 7.14844 3.5 7.3125V8.1875C3.5 8.37891 3.63672 8.51562 3.82812 8.51562H6.23438V10.9219C6.23438 11.1133 6.37109 11.25 6.5625 11.25H7.4375C7.60156 11.25 7.76562 11.1133 7.76562 10.9219V8.51562H10.1719C10.3359 8.51562 10.5 8.37891 10.5 8.1875V7.3125ZM13.7812 7.75C13.7812 4.00391 10.7461 0.96875 7 0.96875C3.25391 0.96875 0.21875 4.00391 0.21875 7.75C0.21875 11.4961 3.25391 14.5312 7 14.5312C10.7461 14.5312 13.7812 11.4961 13.7812 7.75ZM12.4688 7.75C12.4688 10.7852 10.0078 13.2188 7 13.2188C3.96484 13.2188 1.53125 10.7852 1.53125 7.75C1.53125 4.74219 3.96484 2.28125 7 2.28125C10.0078 2.28125 12.4688 4.74219 12.4688 7.75Z"
          fill="white"
        />
      </svg>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="user"
          d="M12 10.5C10.7188 10.5 10.5 10.4688 9.71875 10.7188C9.1875 10.9062 8.59375 11 8 11C7.375 11 6.78125 10.9062 6.25 10.7188C5.46875 10.4688 5.25 10.5 4 10.5C1.78125 10.5 0 12.3125 0 14.5V15C0 15.5625 0.4375 16 1 16H15C15.5312 16 16 15.5625 16 15V14.5C16 12.3125 14.1875 10.5 12 10.5ZM14.5 14.5H1.5C1.5 13.8438 1.75 13.2188 2.21875 12.75C2.6875 12.2812 3.3125 12 4 12C5.28125 12 5.28125 11.9688 5.75 12.1562C6.46875 12.375 7.21875 12.5 8 12.5C8.75 12.5 9.5 12.375 10.2188 12.1562C10.7188 11.9688 10.6875 12 12 12C13.375 12 14.5 13.125 14.5 14.5ZM8 10C10.75 10 13 7.78125 13 5C13 2.25 10.75 0 8 0C5.21875 0 3 2.25 3 5C3 7.78125 5.21875 10 8 10ZM8 1.5C9.90625 1.5 11.5 3.09375 11.5 5C11.5 6.9375 9.90625 8.5 8 8.5C6.0625 8.5 4.5 6.9375 4.5 5C4.5 3.09375 6.0625 1.5 8 1.5Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import TabBarView from "@/components/TabBarView/TabBarView.vue";

import { HTTP, getToken } from "@/http-default";

export default {
  components: {
    TabBarView,
  },

  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dataUser: [],
      dataCustomer: [],
      dataSelect: [],

      selectAll: false,
      keyFilter: "",
    };
  },

  // watch : {
  //     handleCheckSelect : function (value) {
  //         this.dataSelect = value
  //     },
  // },

  // computed : {
  //     handleCheckSelect () {
  //         var dataSelect = this.dataUser.filter(item => item.select === true)
  //         // this.dataSelect = dataSelect
  //         return dataSelect
  //     }
  // },

  methods: {
    handleCheckAll() {
      if (!this.selectAll) {
        this.dataSelect = this.dataUser;
        this.dataUser.forEach((item) => {
          item.select = true;
        });
      } else {
        this.dataSelect = [];
        this.dataUser.forEach((item) => {
          item.select = false;
        });
      }
    },

    handleCheckSelect() {
      var dataSelect = this.dataUser.filter((item) => item.select === true);
      this.dataSelect = dataSelect;
    },

    handleFilter() {
      if (this.keyFilter === "") {
        return this.dataUser;
      }

      var data = this.dataUser.filter(
        (item) =>
          item.username.toLowerCase().indexOf(this.keyFilter.toLowerCase()) !==
          -1
      );

      return data;
    },

    async getData() {
      const user = getToken();
      var config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + user,
        },
      };
      try {
        await HTTP.get("v1/api/customer/?depth=1", config).then((res) => {
          this.dataCustomer = res.data.results;
          this.dataCustomer.forEach((item) => {
            item.select = false;
          });
        });

        await HTTP.get("v1/api/user/", config).then((res) => {
          this.dataUser = res.data.results;
          this.dataUser.forEach((item) => {
            var customer = this.dataCustomer.filter(
              (element) => element.user.id === item.id
            );

            item.customer = customer[0];
          });
        });
      } catch (e) {
        console.error(e);
      }
    },
  },

  created() {
    this.getData();
  },
};
</script>

<style scoped lang='scss'>
@import "UserAdmin.scss";
</style>