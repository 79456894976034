@<template>
  <div>
    <!-- <div
      v-if="
        $route.params.path !== 'quan-ly-funnel' &&
        $route.params.path !== 'quan-ly-post'
      "
      class="category"
    >
      <div v-if="dataCategory1.length == 0" class="category-notdata mb15">
        <div class="category-header p3 mb1">
          Danh mục {{ data.props.titlecontent }}
        </div>
        <div
          class="category-body f fdc aic mb15 pt15 pb15 border-radius w100 bg-white-1"
        >
          <div class="category-body__title p3 mb15">
            Có vẻ như bạn chưa có danh mục {{ data.props.titlecontent }} nào
          </div>
          <div
            class="category-btn f jcc aic pt075 pb075 pl1 pr1 border-radius bg-brown"
          >
            <svg width="16" height="12" viewBox="0 0 16 12">
              <use
                xlink="http://www.w3.org/2000/svg"
                href="#folder"
                x="0"
                y="0"
              ></use>
            </svg>
            <div @click="handleAddFolder" class="h5 ml05 cursor white">
              Tạo danh mục ngay
            </div>
          </div>
        </div>
      </div>
      <div v-else class="category-data mb15">
        <div class="category-header f jcb mb2">
          <div class="category-header__title p3 mb1">
            Danh mục {{ data.props.titlecontent }}
          </div>
          <div
            class="category-btn f jcc aic pt075 pb075 pl1 pr1 border-radius bg-brown"
          >
            <svg width="16" height="12" viewBox="0 0 16 12">
              <use
                xlink="http://www.w3.org/2000/svg"
                href="#folder"
                x="0"
                y="0"
              ></use>
            </svg>
            <div @click="handleAddFolder" class="h5 ml05 cursor white">
              Tạo danh mục
            </div>
          </div>
        </div>
        <div class="category-body bg-white-1">
          <div class="category-body__title f jcb aic mb1">
            <div class="brown p2">Tạo gần đây</div>
            <router-link :to="{ name: 'all-category', params: {system: 'Website'} }">
              <div class="blue cursor p4">
                Xem tất cả các mục ({{ dataCategory1.length }})
              </div>
            </router-link>
          </div>
          <div class="category-list f fw aic w100">
            <router-link
              :to="{
                name: 'detail-category',
                params: { id: item.id, type: 'product' },
              }"
              v-for="(item, index) in dataCategory1"
              :key="index"
              class="category__item f aic pt075 pb075 pl1 pr1 border border-radius"
            >
              <svg width="16" height="12" viewBox="0 0 16 12">
                <use
                  xlink="http://www.w3.org/2000/svg"
                  href="#folderBrown"
                  x="0"
                  y="0"
                ></use>
              </svg>
              <div class="ml05 h7">{{ item.category_name }}</div>
            </router-link>
          </div>
        </div>
      </div>
      <svg-view></svg-view>
    </div> -->

    <!-- Bài viết -->
    <div v-if="$route.params.path === 'quan-ly-post'" class="category">
      <div v-if="dataCategory.length == 0" class="category-notdata mb15">
        <div class="category-header p3 mb1">Danh mục bài viết</div>
        <div
          class="category-body f fdc aic mb15 pt15 pb15 border-radius w100 bg-white-1"
        >
          <div class="category-body__title p3 mb15">
            Có vẻ như bạn chưa có danh mục bài viết nào
          </div>
          <div
            class="category-btn f jcc aic pt075 pb075 pl1 pr1 border-radius bg-brown"
          >
            <svg width="16" height="12" viewBox="0 0 16 12">
              <use
                xlink="http://www.w3.org/2000/svg"
                href="#folder"
                x="0"
                y="0"
              ></use>
            </svg>
            <div @click="handleAddFolder" class="h5 ml05 cursor white">
              Tạo danh mục ngay
            </div>
          </div>
        </div>
      </div>
      <div v-else class="category-data mb15">
        <div class="category-header f jcb mb2">
          <div class="category-header__title p3 mb1">Danh mục bài viết</div>
          <div
            class="category-btn f jcc aic pt075 pb075 pl1 pr1 border-radius bg-brown"
          >
            <svg width="16" height="12" viewBox="0 0 16 12">
              <use
                xlink="http://www.w3.org/2000/svg"
                href="#folder"
                x="0"
                y="0"
              ></use>
            </svg>
            <div @click="handleAddFolder" class="h5 ml05 cursor white">
              Tạo danh mục
            </div>
          </div>
        </div>
        <div class="category-body bg-white-1">
          <div class="category-body__title f jcb aic mb1">
            <div class="brown p2">Tạo gần đây</div>
            <router-link :to="{ name: 'all-category', params: {system: 'Website'} }">
              <div class="blue cursor p4">
                Xem tất cả các mục ({{ dataProps.length }})
              </div>
            </router-link>
          </div>
          <div class="category-list w100" :class="isMobile ? 'f fdc gap1' : 'f fw aic'">
            <router-link
              :to="{
                name: 'detail-category',
                params: { id: item.id, type: 'category' },
              }"
              v-for="(item, index) in dataCategory.slice(0, 6)"
              :key="index"
              class="category__item w100 f aic pt075 pb075 pl1 pr1 border border-radius"
            >
              <svg width="16" height="12" viewBox="0 0 16 12">
                <use
                  xlink="http://www.w3.org/2000/svg"
                  href="#folderBrown"
                  x="0"
                  y="0"
                ></use>
              </svg>
              <div class="ml05 h7">{{ item.category_name }}</div>
            </router-link>
          </div>
        </div>
      </div>
      <svg-view></svg-view>
    </div>

    <!-- phễu -->
    <div v-if="$route.params.path === 'quan-ly-funnel'" class="category">
      <div v-if="dataProps.length == 0" class="category-notdata mb15">
        <div class="category-header p3 mb1">
          Danh mục {{ data.props.titlecontent }}
        </div>
        <div
          class="category-body f fdc aic mb15 pt15 pb15 border-radius w100 bg-white-1"
        >
          <div class="category-body__title p3 mb15">
            Có vẻ như bạn chưa có danh mục {{ data.props.titlecontent }} nào
          </div>
          <div
            class="category-btn f jcc aic pt075 pb075 pl1 pr1 border-radius bg-brown"
          >
            <svg width="16" height="12" viewBox="0 0 16 12">
              <use
                xlink="http://www.w3.org/2000/svg"
                href="#folder"
                x="0"
                y="0"
              ></use>
            </svg>
            <div @click="handleAddFolder" class="h5 ml05 cursor white">
              Tạo phễu
            </div>
          </div>
        </div>
      </div>
      <div v-else class="category-data mb15">
        <div class="category-header f jcb mb2">
          <div class="category-header__title p3 mb1">
            Danh mục {{ data.props.titlecontent }}
            <span class="orange">({{ dataProps.length }})</span>
          </div>
          <div
            class="category-btn f jcc aic pt075 pb075 pl1 pr1 border-radius bg-brown"
          >
            <svg width="16" height="12" viewBox="0 0 16 12">
              <use
                xlink="http://www.w3.org/2000/svg"
                href="#folder"
                x="0"
                y="0"
              ></use>
            </svg>
            <div @click="handleAddFolder" class="h5 ml05 cursor white">
              Tạo danh mục
            </div>
          </div>
        </div>
        <div class="category-body bg-white-1">
          <div class="category-body__title f jcb aic mb1">
            <div class="brown p2">Tạo gần đây</div>
            <router-link :to="{ name: 'all-category' }">
              <div class="blue cursor p4">
                Xem tất cả các mục ({{ dataProps.length }})
              </div>
            </router-link>
          </div>
          <div class="category-list f fw aic w100">
            <div
              v-for="(item, index) in dataProps.slice(0, 6)"
              :key="index"
              class="category__item f aic pt075 pb075 pl1 pr1 border border-radius"
            >
              <svg width="16" height="12" viewBox="0 0 16 12">
                <use
                  xlink="http://www.w3.org/2000/svg"
                  href="#folderBrown"
                  x="0"
                  y="0"
                ></use>
              </svg>
              <div class="ml05 h7">{{ item.title_funnel }}</div>
            </div>
          </div>
        </div>
      </div>
      <svg-view></svg-view>
    </div>
  </div>
</template>

<script>
import SvgView from "@/components/SvgView/SvgView.vue";
import { HTTP } from "@/http-default";

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    dataCategory: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    dataProps: {
      type: Array,
      default() {
        return [];
      },
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    SvgView,
  },
  data() {
    return {
      dataCategory1: [],
      dataPostCategory: [],
    };
  },
  watch: {
    dataCategory: function () {
      this.getDataCategory();
    },

    $route: function () {
      this.getDataCategory();
    },
  },

  methods: {
    handleAddFolder() {
      var popup = document.getElementsByClassName("pop-up-add-folder")[0];
      var CustomerView = document.getElementsByClassName("Cus-container")[0];
      popup.classList.add("popup-active");
      CustomerView.classList.add("popup-flow");
    },
    getDataCategory() {
      if (this.$route.params.path !== "quan-ly-post") {
        HTTP.get(this.dataCategory.props.urlapi).then((res) => {
          this.dataCategory1 = res.data.results;
        });
      }
    },
  },

  created() {
    if (this.$route.params.path !== "quan-ly-funnel") {
      this.getDataCategory();
    }
  },
};
</script>

<style scoped lang='scss'>
@import "CategoryLayout2.scss";
</style>