@<template>
    <div
        v-if="statusPopupCondition" 
        class="popup popup-condition f jcc aic">
        <div class="condition-user__contaienr pt1 pb15 pl1 pr1 border-radius bg-white-1">
            <div class="condition-user__title p5 brown">
                Thêm đối tượng
            </div>
            <div class="condition-user__text h6 mb1 gray">
                Mỗi User chỉ được xuất hiện trong một nhóm điều kiện
            </div>
            <div class="">
                <div
                    v-for="(item, index) in dataUser"
                    :key="index">
                    <div 
                        v-if="item.checked"
                        class="f jcb aic border-brown border-radius pt1 pb1 pl1 pr1 mb1">
                        <div class="p6 brown"> {{item.title}} </div>
                        <div>
                            <input 
                                type="checkbox" 
                                v-model="item.checked"
                                :checked="item.checked">
                        </div>
                    </div>
                    <div 
                        v-else
                        class="f jcb aic border border-radius pt1 pb1 pl1 pr1 mb1">
                        <div class="p6"> {{item.title}} </div>
                        <div>
                            <input 
                                type="checkbox" 
                                v-model="item.checked"
                                :checked="item.checked">
                        </div>
                    </div>
                </div>
            </div>
            <div class="condition-user__action f jce aic">
                <div 
                    @click="handleClose"
                    class="w50 pt075 pb075 p5 border border-radius align-c cursor">
                    Đóng
                </div>
                <div 
                    @click="handleSubmit"
                    class="w50 pt075 pb075 p5 ml1 border-radius align-c bg-brown white cursor">
                    Xác nhận
                </div>
            </div>  
        </div>
    </div>
</template>

<script>
export default {
    props : {
        'statusPopupCondition' : Boolean,
        listUserGift : {
            type : Array,
            default() {
                return []
            }
        }
    },

    data() {
        return {
            dataUser : [...this.listUserGift]
        }
    },

    methods : {
        handleClose() {
            this.dataUser.forEach(item => {
                item.checked = false
            })
            this.$emit('updateUserCondition', this.dataUser)
            this.$emit('updateStatusCondition')
        },

        handleSubmit () {
            var userSelect = []
            this.dataUser.forEach(item => {
                if (item.checked) {
                    userSelect.push(item)
                }
            })
            this.$emit('updateStatusCondition')
            this.$emit('updateUserCondition', this.dataUser)
        }
    },

    // created() {
    //     this.dataUser = [...this.listfieldaddnew]
    // }
}
</script>

<style scoped lang="scss">
    @import "PopupUserGift.scss";
</style>