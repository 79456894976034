<template>
  <div class="container">
    <div v-if="dataCourse" class="course-detail relative">
      <div class="course-detail-media">
        <img
          v-if="dataCourse.media && dataCourse.media.file_media"
          :src="dataCourse.media.file_media"
          alt=""
        />
        <img v-else src="@/assets/imgUser.png" alt="" />
      </div>

      <div class="course-detail-content absolute bg-white-1 px1 f fdc gap1 shadow">
        <p class="align-c h3 brown">{{ dataCourse.title }}</p>
        <div class="w100 align-c">{{ dataCourse.description }}</div>
        <div class="w100" v-html="dataCourse.content"></div>

        <div class="course-detail-unit px1 f fdc gap1">
          <div class="f aic jcb">
            <p class="h5">Chi tiết khoá học</p>
            <div class="f aic gap1">
              <span
                class="bg-white-1 ph15 pv075 border-radius white p5 cursor border brown"
                @click="$router.push(`/course-create/?course=${dataCourse.id}`)"
              >
                Chỉnh sửa
              </span>
              <span
                class="bg-white-1 ph15 pv075 border-radius white p5 cursor border brown"
                @click="isShowPopupAddUnit = true"
              >
                Thêm bài giảng
              </span>
              <span
                class="bg-brown ph15 pv075 border-radius white p5 cursor"
                @click="savePositionOfUnit"
              >
                Lưu lại
              </span>
            </div>
          </div>
          <template
            v-if="
              dataCourse.course_unit_data && dataCourse.course_unit_data.length
            "
          >
            <draggable
              :list="dataCourse.course_unit_data"
              tag="div"
              draggable=".course-detail-unit-item"
              class="f fdc gap1"
            >
              <div
                v-for="(item, index) in dataCourse.course_unit_data"
                :key="index"
                class="course-detail-unit-item f gap1 relative"
                @click="$router.push(`/course-unit/${item.id}`)"
              >
                <div class="absolute cursor h6 brown" style="top: 1em; right: 1em;" @click.stop="openPopupEditUnit(item)">
                  Chỉnh sửa
                </div>
                <div>
                  <video v-if="item.video" :src="item.video.video_file" alt="" width="200px" height="100px" />
                  <video v-else :src="null" alt="" width="200px" height="100px" />
                </div>
                <div>
                  <p class="p5 brown">{{ item.title }}</p>
                  <p class="p4 gray-1-text">{{ item.description }}</p>
                  <p class="p6 gray-1-text">
                    Trạng thái:
                    <span class="h7" :class="item.status ? 'green' : 'red'">{{ item.status ? 'Hoạt động' : 'Không hoạt động' }}</span>
                    &nbsp; &nbsp;
                    <template v-if="item.status">
                      <span class="h7 cursor red" @click.stop="updateSatusUnit(false, item.id)">Ngưng hoạt động</span>
                    </template>
                    <template v-else>
                      <span class="h7 cursor green" @click.stop="updateSatusUnit(true, item.id)">Kích hoạt</span>
                    </template>
                  </p>
                  <p class="p6 gray-1-text">
                    Thời gian tạo:
                    <span class="h7 black">{{
                      formatDate(item.created_at)
                    }}</span>
                  </p>
                  <p v-if="item.user_created" class="p6 gray-1-text">
                    Người tạo:
                    <span class="h7 black">{{ item.user_created.username }}</span>
                  </p>
                  <div class="f aic gap1">
                    <p class="p6 gray-1-text">
                      Lượt xem:
                      <span class="h7 black">{{ item.total_click }}</span>
                    </p>
                    <p class="p6 gray-1-text">
                      Lượt chia sẻ:
                      <span class="h7 black">{{ item.total_share }}</span>
                    </p>
                  </div>
                </div>
                <div
                  class="absolute"
                  style="top: 50%; right: 1em; transform: translateY(-50%)"
                >
                  <div class="f aic gap1">
                    <img src="@/assets/icons/move.svg" alt="" />
                  </div>
                </div>
              </div>
            </draggable>
          </template>
          <template v-else>
            <p class="italic gray-text-1 h7">Chưa có bài giảng nào</p>
          </template>
        </div>
      </div>

      <div class="course-detail-user absolute bg-white-1 px1 f fdc gap1 shadow">
        <div class="f aic jcb">
          <p class="h4">Danh sách học viên</p>
          <span
            class="bg-brown ph15 pv075 border-radius white p5 cursor"
            @click="() => {
              isShowPopupAddMember = true
              !dataUsers.length && getUsers()
            }"
          >
            Thêm học viên
          </span>
        </div>
        <template v-if="dataMembers.length">
          <div v-for="(item, index) in dataMembers" :key="index" class="f aic jcb gap1 border-bottom">
            <div>
              <p v-if="item.user">{{ item.user.username }}</p>
              <p class="gray-1-text h6">
                Trạng thái: <span :class="item.is_confirm ? 'green' : 'red'">{{ item.is_confirm ? 'Đang học' : 'Chưa xác nhận' }}</span>
              </p>
            </div>
            <template v-if="item.is_confirm">
              <p class="red h7 cursor" style="text-decoration: underline" @click="updateStatusMember(false, item.user.id, item.id)">Ngưng hoạt động</p>
            </template>
            <template v-else>
              <p class="green h7 cursor" style="text-decoration: underline" @click="updateStatusMember(true, item.user.id, item.id)">Xác nhận</p>
            </template>
          </div>
        </template>
        <template v-else>
          <p class="gray-1-text p4 italic">Chưa có học viên nào</p>
        </template>
      </div>
    </div>

    <Loading :is-loading="isLoading" type="page" />

    <Popup
      :is-show="isShowPopupAddUnit"
      :width="'50vw'"
      @close="isShowPopupAddUnit = false"
    >
      <div
        class="bg-white-1 px1 border-radius f fdc gap05 relative"
        style="padding-bottom: 80px; height: 80vh; overflow: auto;"
      >
        <div class="f jce w100 px1 border-top fixed bg-white-1" style="bottom: 0; left: 0; right: 0; z-index: 10;">
          <button
            class="bg-brown ph15 pv075 border-radius white p5 cursor"
            :disabled="errorFile.length || !dataCreateCourseUnit.title || !imagePreview.imageFile"
            @click="dataCreateCourseUnit.unitId ? updateCourseUnit() : createCourseUnit()"
          >
            Lưu lại
          </button>
        </div>
        <div class="medical__img">
          <div class="h7">Video bài giảng</div>
          <label
            for="file-input"
            class="w100 f mt05 aic jcb pt1 pb1 pl1 pr1 border-radius bg-gray-3"
            style="border: 1px dashed #d9dde8"
          >
            <div class="f aic">
              <div class="h7 pt05 pb05 pr1 pl1 mr1 border-radius border cursor">
                <i>Tải video lên</i>
              </div>

              <input
                style="display: none"
                type="file"
                accept="video/*"
                ref="file"
                @change="uploadImage($event)"
                id="file-input"
              />
              <div>
                <div class="p4">Thêm video</div>
                <div class="h6 gray">Dung lượng ảnh tối đa: 200MB</div>
              </div>
            </div>

            <div v-if="imagePreview">
              <video
                :src="imagePreview.image"
                alt=""
                controls
                style="
                  width: 300px;
                  height: 300px;
                  object-fit: cover;
                  border-radius: 8px;
                "
              />
            </div>
          </label>
          <p v-if="errorFile.length" class="h7 italic red">{{ errorFile.join(', ') }}</p>
        </div>

        <div class="w100">
          <p class="h7 mb025" for="">Tiêu đề bài giảng</p>
          <input
            class="pt075 pb075 pr075 pl075 w100"
            type="text"
            placeholder="Nhập tiêu đề bài giảng"
            v-model="dataCreateCourseUnit.title"
          />
        </div>
        <div class="w100">
          <p class="h7 mb025" for="">Mô tả bài giảng</p>
          <textarea
            class="pt075 pb075 pr075 pl075 w100 border-radius"
            placeholder="Nhập mô tả bài giảng"
            row="3"
            style="resize: none"
            v-model="dataCreateCourseUnit.description"
          ></textarea>
        </div>
        <div class="w100">
          <p class="h7">Mô tả chi tiết</p>
          <editor
            api-key="4wl3mwjq0h6xnbsttg7khu6kcq0xy9vi5g6xsc52q90hglep"
            v-model="dataCreateCourseUnit.content"
            :init="{
              height: 500,
              menubar: false,
              placeholder: 'Nhập mô tả chi tiết bài giảng',
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | bold italic backcolor | \
                                      alignleft aligncenter alignright alignjustify | \
                                      bullist numlist outdent indent | removeformat | help | link image tinydrive',
              powerpaste_allow_local_images: true,
              powerpaste_word_import: 'prompt',
              powerpaste_html_import: 'prompt',
            }"
          />
        </div>
      </div>
    </Popup>

    <Popup
      :is-show="isShowPopupAddMember"
      :width="'30vw'"
      @close="isShowPopupAddMember = false"
    >
    <div
        class="bg-white-1 px1 border-radius f fdc gap05 relative"
        style="padding-bottom: 80px; height: 80vh; overflow: auto;"
      >
        <div class="f jce w100 px1 border-top fixed bg-white-1" style="bottom: 0; left: 0; right: 0; z-index: 10;">
          <button
            class="bg-brown ph15 pv075 border-radius white p5 cursor"
            :disabled="!listUsersSelected.length"
            @click="addMember"
          >
            Xác nhận
            <span v-if="listUsersSelected.length">{{ listUsersSelected.length }}</span>
          </button>
        </div>

        <p class="h4">Chọn thành viên</p>
        <input v-model="searchUser" type="text" placeholder="Tìm kiếm" class="mv1" />
        <label v-for="(item, index) in dataUsers.filter(e => e.username.includes(searchUser))" :key="index" class="border px1 border-radius f aic gap1">
          <input v-model="item.isSelected" type="checkbox" />
          <p class="p5">
            {{ item.username }}
          </p>
        </label>
      </div>
    </Popup>

    <NotiView
      v-if="notiView"
      @closePopup="notiView = false"
      :title="title"
    />
  </div>
</template>

<script>
import { HTTP } from "../../http-default";
import Loading from "@/components/Loading/Loading.vue";
import Popup from "@/components/Popup/index.vue";
import Editor from "@tinymce/tinymce-vue";
import NotiView from "@/components/NotiView/NotiView.vue";

import draggable from "vuedraggable";

export default {
  name: "CourseDetailPage",

  components: {
    Loading,
    draggable,
    Popup,
    Editor,
    NotiView
  },

  data() {
    return {
      dataCourse: null,
      isLoading: true,
      dataMembers: [],

      searchUser: '',
      dataUsers: [],

      dataCreateCourseUnit: {
        title: "",
        description: "",
        content: "",
      },

      imagePreview: null,
      errorFile: [],

      isShowPopupAddUnit: false,
      isShowPopupAddMember: false,

      notiView: false,
      logo: "noti-success",
      title: "",
    };
  },

  computed: {
    listUsersSelected() {
      return this.dataUsers.filter(user => user.isSelected)
    }
  },

  created() {
    this.getData();
    this.getDataUserCourse()
  },

  methods: {
    async getData() {
      await HTTP.get(`course/course/${this.$route.params.id}`).then((res) => {
        this.dataCourse = res?.data?.data || [];
        const course_units = this.dataCourse.course_unit_data || [];
        if (course_units.length) {
          this.dataCourse.course_unit_data = course_units.sort(
            (a, b) => (a.index || 0) - (b.index || 0)
          );
        }
        this.isLoading = false;
      });
    },

    async getDataUserCourse() {
      await HTTP.get(`course/user-course/?course=${this.$route.params.id}`).then((res) => {
        this.dataMembers = res.data?.data || []
      })
    },

    async getUsers() {
      await HTTP.get(`/v1/api/user/`).then((res) => {
        this.dataUsers = res.data?.results || []
        this.dataUsers = this.dataUsers.map(e => ({...e, isSelected: false}))
        this.dataUsers = this.dataUsers.filter(e => !this.dataMembers.find(item => item.id === e.id) || !e.is_superuser)
      })
    },

    async addMember() {
      for (let i = 0; i < this.listUsersSelected.length; i ++) {
        const data = this.listUsersSelected[i]
        const payload = {
          user_id: data.id,
          is_confirm: true,
          course: this.$route.params.id,
        }
        await HTTP.post(`course/user-course/`, payload)
      }

      this.isShowPopupAddMember = false
      this.getDataUserCourse()
    },

    async updateStatusMember(status, userId, id) {
      this.isLoading = true
      const payload = {
        user_id: userId,
        is_confirm: status,
        course: this.$route.params.id,
        user_course_id: id,
      }

      await HTTP.post(`course/user-course/`, payload).then((res) => {
        this.isLoading = false
        this.title = res.data.data
        this.notiView = true
        this.getDataUserCourse()
      }).catch((err) => {
        this.notiView = true
        this.logo =  "noti-error"
        this.title = err.message
        this.isLoading = false
      })
    },

    async savePositionOfUnit() {
      const payload = this.dataCourse?.course_unit_data?.length
        ? this.dataCourse?.course_unit_data.map((e, i) => ({
            index: i,
            id: e.id,
          }))
        : {};

      this.isLoading = true;
      await HTTP.post(`course/sort-course-unit/`, payload)
        .then(() => {
          this.isLoading = false;
          // this.getData()
        })
        .catch(() => {
          this.isLoading = false;
        });
      this.isLoading = false;
    },

    formatDate(date, _type) {
      const newDate = new Date(date);
      const dformat =
        (_type
          ? [
              newDate.getHours(),
              newDate.getMinutes() < 10
                ? "0" + newDate.getMinutes()
                : newDate.getMinutes(),
            ].join(":") + " "
          : "") +
        [
          newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate(),
          newDate.getMonth() + 1 < 10
            ? "0" + (newDate.getMonth() + 1)
            : newDate.getMonth() + 1,
          newDate.getFullYear(),
        ].join("/");

      return dformat;
    },

    uploadImage(event) {
      const file = event.target.files[0]
      const error = []
      if (file.size > 200 * 1024 * 1024) error.push('Vui lòng chọn file < 200mb')
      if (!file.type.startsWith('video/')) error.push('Vui lòng chọn file là video')

      if (!error.length) {
        const fileExtension = file.name.split('.').pop();
        const newFileName = `video-course-${Date.now()}.${fileExtension}`
        console.log(newFileName, file.type);
        const newFile = new File([file], newFileName, {
          type: file.type,
        });
        const item = {
          image: URL.createObjectURL(newFile),
          name: event.target.files[0].name,
          imageFile: newFile,
        }
        this.imagePreview = item;
      }

      this.errorFile = error
    },

    openPopupEditUnit(item) {
      this.isShowPopupAddUnit = true
      this.dataCreateCourseUnit = {
        title: item.title,
        description: item.description,
        content: item.content,
        unitId: item.id,
      }

      this.imagePreview = {
        image: item?.video?.video_file
      }
    },

    async updateCourseUnit() {
      const data = {
        ...this.dataCreateCourseUnit,
        ... this.imagePreview?.imageFile ? { video: this.imagePreview?.imageFile } : null,
      };

      const payload = new FormData();
      for (let key in data) {
        payload.append(key, data[key]);
      }

      this.isLoading = true;

      const config = {
        "Content-Type": "multipart/form-data",
      };
      await HTTP.post(`/course/course-unit/${this.dataCreateCourseUnit.unitId}`, payload, config)
        .then((res) => {
          this.isShowPopupAddUnit = false;
          this.isLoading = false;
          this.notiView = true;
          this.logout = "noti-success";
          this.title = res.data.data
          this.getData()
        })
        .catch((err) => {
          this.isShowPopupAddUnit = false;
          this.isLoading = false;
          this.notiView = true;
          this.logout = "noti-error";
          this.title = err?.response?.data?.data || err?.message
        });
    },

    async createCourseUnit() {
      const data = {
        ...this.dataCreateCourseUnit,
        course: this.$route.params.id,
        video: this.imagePreview?.imageFile,
      };

      const payload = new FormData();
      for (let key in data) {
        payload.append(key, data[key]);
      }

      this.isLoading = true;

      const config = {
        "Content-Type": "multipart/form-data",
      };
      await HTTP.post(`/course/upload-course-unit/`, payload, config)
        .then((res) => {
          this.isShowPopupAddUnit = false;
          this.isLoading = false;
          this.notiView = true;
          this.logout = "noti-success";
          this.title = res.data.data
          this.getData()
        })
        .catch((err, r) => {
          console.log(err.response, r);
          this.isShowPopupAddUnit = false;
          this.isLoading = false;
          this.notiView = true;
          this.logout = "noti-error";
          this.title = err?.response?.data?.data || err?.message
        });
    },

    async updateSatusUnit(status, unitId) {
      this.isLoading = true
      const payload = {
        status
      }

      await HTTP.post(`course/update-course-unit-status/${unitId}`, payload).then((res) => {
        this.isLoading = false
        this.title = res.data.data
        this.notiView = true
        this.getData()
      }).catch((err) => {
        this.notiView = true
        this.logo =  "noti-error"
        this.title = err.message
        this.isLoading = false
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.course-detail-media {
  & img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 16px;
  }
}

.course-detail-content {
  width: 60%;
  border-radius: 8px;
  top: calc(100% - 2em);
  left: 1em;
  min-height: 500px;
}

.course-detail-user {
  width: 35%;
  border-radius: 8px;
  top: calc(100% - 2em);
  right: 1em;
  min-height: 500px;
}

.course-detail-unit {
  border-top: 2px solid #6f382b;

  &-item {
    border: 1px solid #6f382b;
    padding: 1em;
    border-radius: 8px;
  }
}

.shadow {
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.16);
}

</style>