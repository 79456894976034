<template>
  <div class="sub-menu">
    <div v-for="(menuItem, menuIndex) in dataMenu" :key="menuIndex">
      <p
        @click="$router.push(`/${path}/category-${menuItem.id}`)"
        class="sub-item-menu"
        :class="routeCategoryId == menuItem.id && 'brown h5'"
      >
      {{ menuItem.category_name }}
      </p>
      <div v-if="menuItem.children && menuItem.children.length > 0" class="f gap05">
        <div class="connect"></div>
        <div class="pt05">
          <SubMenu :dataMenu="menuItem.children" :path="path" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubMenu",
  props: {
    dataMenu: {
      type: Array,
      default: () => [],
    },

    path: {
      type: String,
      default: '',
    }
  },

  data() {
    return {
      menu: [],
    };
  },

  watch: {
    // children: {
    //   handler() {
    //     this.children.forEach(e => {
    //       const index = this.menu.findIndex(x => x.id === e.id);
    //       this.menu.splice(index, 1);
    //     })
    //   },
    //   deep: true,
    // }
  },

  computed: {
    routeCategoryId() {
      return this.$route.params.id?.split("-")?.[1]
    },
  },
};
</script>

<style lang="scss" scoped>
.item-menu {
  bottom: -0.5em; 
  left: 0;
  transform: translateY(100%);
}

.item-menu-title , .sub-item-menu {
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
      &::before {
      transform-origin: left;
      transform: scaleX(1);
      }
  }

  &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #5e2d2d;
      bottom: 0;
      left: 0;
      transform-origin: right;
      transform: scaleX(0);
      transition: transform .3s ease-in-out;
  }
}

.connect {
  width: 30px;
  height: auto;
  // border-bottom-left-radius: 5px;
  border-left: 1px solid #5e2d2d;
  // border-bottom: 1px solid #5e2d2d;
}
</style>