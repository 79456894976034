<template>
  <div class="container">
    <div class="container-header f aic mb2">
      <div class="qcont h2 pr1">{{ $route.query.course ? 'Chỉnh sửa' : 'Tạo mới' }} khoá học</div>
    </div>

    <div class="f jce">
      <button
        class="bg-brown ph15 pv075 border-radius white p5 mb2 cursor"
        @click="$route.query.course ? updateCourse() : createCourse()"
      >
        {{ $route.query.course ? 'Xác nhận' : 'Tạo mới' }}
      </button>
    </div>
    <div class="bg-white-1 px1 border-radius f fdc gap05">
      <div class="medical__img">
        <div class="h7">Ảnh bìa khoá học</div>
        <label
          for="file-input"
          class="w100 f mt05 aic jcb pt1 pb1 pl1 pr1 border-radius bg-gray-3"
          style="border: 1px dashed #d9dde8"
        >
          <div class="f aic">
            <div class="h7 pt05 pb05 pr1 pl1 mr1 border-radius border cursor">
              <i>Tải ảnh lên</i>
            </div>

            <input
              style="display: none"
              type="file"
              accept="image/*"
              ref="file"
              @change="uploadImage($event)"
              id="file-input"
            />
            <div>
              <div class="p4">Thêm ảnh đại diện của bạn</div>
              <div class="h6 gray">Dung lượng ảnh tối đa: 2MB</div>
            </div>
          </div>

          <div v-if="imagePreview">
            <img
              :src="imagePreview.image"
              alt=""
              style="
                width: 200px;
                height: 70px;
                object-fit: cover;
                border-radius: 8px;
              "
            />
          </div>
        </label>
      </div>

      <div class="w100">
        <p class="h7 mb025" for="">Tiêu đề khoá học</p>
        <input
          class="pt075 pb075 pr075 pl075 w100"
          type="text"
          placeholder="Nhập tiêu đề khoá học"
          v-model="dataCreateCourse.title"
        />
      </div>
      <div class="w100">
        <p class="h7 mb025" for="">Giá</p>
        <input-number
          v-model="dataCreateCourse.price"
          class="w100"
          placeholder="Nhập giá"
        />
      </div>
      <div class="w100">
        <p class="h7 mb025" for="">Mô tả khoá học</p>
        <textarea
          class="pt075 pb075 pr075 pl075 w100 border-radius"
          placeholder="Nhập mô tả khoá học"
          row="3"
          style="resize: none;"
          v-model="dataCreateCourse.description"
        ></textarea>
      </div>
      <div class="w100">
        <p class="h7"> Mô tả chi tiết </p>
        <editor
          api-key="4wl3mwjq0h6xnbsttg7khu6kcq0xy9vi5g6xsc52q90hglep"
          v-model="dataCreateCourse.content"
          :init="{
            height: 500,
            menubar: false,
            placeholder: 'Nhập mô tả chi tiết khoá học',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic backcolor | \
                                      alignleft aligncenter alignright alignjustify | \
                                      bullist numlist outdent indent | removeformat | help | link image tinydrive',
            powerpaste_allow_local_images: true,
            powerpaste_word_import: 'prompt',
            powerpaste_html_import: 'prompt',
          }"
        />
      </div>
    </div>

    <Loading :is-loading="isLoading" type="page" />

    <NotiView
      v-if="notiView"
      @closePopup="notiView = false"
      :title="title"
      :logo="logo"
    />
  </div>
</template>

<script>
import { HTTP } from "../../http-default";
import Loading from "@/components/Loading/Loading.vue";
import Editor from "@tinymce/tinymce-vue";
import NotiView from "@/components/NotiView/NotiView.vue";
import InputNumber from "@/components/InputNumber/InputNumber.vue";

export default {
  name: "CourseCreatePage",

  components: {
    Loading,
    Editor,
    NotiView,
    InputNumber,
  },

  data() {
    return {
      dataCreateCourse: {
        title: "",
        description: "",
        content: "",
        price: 0,
      },

      imagePreview: null,
      isLoading: false,
      notiView: false,
      logo: "noti-success",
      title: "",
    };
  },

  created() {
    if (this.$route.query.course) this.getData()
  },

  methods: {
    uploadImage(event) {
      const item = {
        image: URL.createObjectURL(event.target.files[0]),
        name: event.target.files[0].name,
        imageFile: event.target.files[0],
      };
      this.imagePreview = item;
    },

    async getData() {
      this.isLoading = true;
      await HTTP.get(`course/course/${this.$route.query.course}`).then((res) => {
        const data = res?.data?.data
        this.dataCreateCourse = {
          title: data.title,
          description: data.description,
          content: data.content,
          price: data.price,
        }
        this.imagePreview = {
          image: data.media?.file_media,
          name: null,
          imageFile: null,
        }

        this.isLoading = false;
      });
    },

    async updateCourse() {
      const data = {
        course_id: this.$route.query.course,
        ...this.dataCreateCourse,
        ... this.imagePreview?.imageFile ? { new_image: this.imagePreview.imageFile } : null,
      }

      const payload = new FormData();
      for (let key in data) {
        payload.append(key, data[key]);
      }

      this.isLoading = true

      const config = {
        'Content-Type': 'multipart/form-data'
      }
      await HTTP.post(`/course/course/`, payload, config).then(() => {
        this.$router.go(-1)
        this.isLoading = false
      }).catch((err) => {
        console.log(err);
        this.isLoading = false
        this.notiView = true
        this.logo =  "noti-error"
        this.title = err.message
      })
    },

    async createCourse() {
      const data = {
        ...this.dataCreateCourse,
        image: this.imagePreview.imageFile,
      }

      const payload = new FormData();
      for (let key in data) {
        payload.append(key, data[key]);
      }

      this.isLoading = true

      const config = {
        'Content-Type': 'multipart/form-data'
      }
      await HTTP.post(`/course/course/`, payload, config).then(() => {
        this.$router.replace('/courses/')
        this.isLoading = false
      }).catch((err) => {
        console.log(err);
        this.isLoading = false
        this.notiView = true
        this.logo =  "noti-error"
        this.title = err.message
      })
    },
  },
};
</script>

<style lang="scss" scoped>
</style>