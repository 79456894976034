var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$route.params.path !== 'forums')?_c('div',{class:_vm.$attrs.isMobile ? 'container-mobile' : 'container'},[(
      this.$route.fullPath.includes(
        '/' + this.$route.params.path + '/category/all-category'
      ) &&
      !_vm.$route.params.id &&
      _vm.$route.params.type !== 'category'
    )?_c('AllCategory',{ref:"AllCategory",attrs:{"data":_vm.dataField,"dataProps":_vm.dataProps}}):(
      this.$route.fullPath.includes('/quan-ly-customer') ||
      this.$route.fullPath == '/quan-ly-gift' ||
      (this.$route.fullPath == '/quan-ly-order' &&
        !_vm.$route.params.id &&
        _vm.$route.params.type !== 'category')
    )?_c('Layout1View',{attrs:{"data":_vm.data,"dataCategory":_vm.dataCategory,"dataProps":_vm.dataProps,"dataField":_vm.dataField}}):(
      _vm.$route.params.path === 'quan-ly-post' &&
      !_vm.$route.params.id &&
      _vm.$route.params.type !== 'category'
    )?_c('PostView',{attrs:{"data":_vm.data,"dataCategory":_vm.dataCategory,"dataProps":_vm.dataProps,"dataField":_vm.dataField,"isMobile":_vm.$attrs.isMobile}}):(
      _vm.$route.params.path === 'quan-ly-user' &&
      !_vm.$route.params.id &&
      _vm.$route.params.type !== 'category'
    )?_c('UserAdmin',{attrs:{"isMobile":_vm.$attrs.isMobile}}):(
      _vm.$route.params.path &&
      _vm.$route.params.id &&
      _vm.$route.params.type === 'category'
    )?_c('DetailCategory',{attrs:{"data":_vm.data,"dataCategory":_vm.dataCategory,"dataProps":_vm.dataProps,"dataField":_vm.dataField,"isMobile":_vm.$attrs.isMobile}}):(
      _vm.$route.params.path === 'course' &&
      !_vm.$route.params.id 
    )?_c('CoursePage',{attrs:{"isMobile":_vm.$attrs.isMobile}}):(
      _vm.$route.params.path === 'course-create' &&
      !_vm.$route.params.id 
    )?_c('CourseCreatePage',{attrs:{"isMobile":_vm.$attrs.isMobile}}):(
      _vm.$route.params.path === 'schedule' &&
      !_vm.$route.params.id 
    )?_c('SchedulePage',{attrs:{"isMobile":_vm.$attrs.isMobile}}):_c('Layout2View',{attrs:{"data":_vm.data,"dataCategory":_vm.dataCategory,"dataProps":_vm.dataProps,"dataField":_vm.dataField}}),_c('AddFolderView',{attrs:{"dataField":_vm.dataField},on:{"updateDataCategory":_vm.updateDataCategory}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }