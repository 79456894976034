@<template>
    <div class="detail-post__container" :class="isMobile ? 'container-mobile px1' : 'container'">
        <AddNewPost 
            :dataInput="data"
            :isMobile="isMobile"
        />
    </div>
</template>

<script>
import AddNewPost from '@/components/AddNew/AddNewPost/AddNewPost.vue'

import { HTTP } from '@/http-default'
export default {
    components : {
        AddNewPost
    },

    props: {
        isMobile: {
            type: Boolean,
            default: false
        },
    },

    data () {
        return {
            data : {}
        }
    },

    methods : {
        getData () {
            HTTP.get('v1/api/post/' + this.$route.params.id + '/?depth=1')
            .then((res) => {
                this.data = res.data
            })
        }
    },

    mounted () {
        this.getData()
    }
}
</script>

<style>

</style>