@<template>
  <div class="product-web__container">
    <HeaderType2
      class="z10"
      @updateProductCart="updateProductCart"
      :dataGiftsCart="dataGiftsCart"
    ></HeaderType2>
    <div class="banner">
      <img class="w100" src="../../assets/bg-list-website.jpg" alt="" />
    </div>

    <!-- danh sach san pham -->
    <div v-if="!$route.params.id" class="list-product__container">
			<div class="mb1 f aic jcb">
				<p class="h5">Hiện tại bạn có: <span class="orange">{{ formatNumberShow(myData.point || 0) }} điểm thành viên</span></p>

				<p class="h5 brown cursor" @click="isShowPopupMyGift = true">
					Xem quà tặng của tôi ({{ dataMyGifts.length }})
				</p>
			</div>
      <div class="list-product__body">
        <div
          v-for="(item, index) in dataGifts"
          :key="index"
          class="list-product__cart"
        >
          <div class="cart-img__item relative mb1 cursor">
            <img class="border border-radius" src="@/assets/gift.png" alt="" />
            <div class="cart-bg__item absolute border-radius"></div>
            <div
              @click="openPopup(item)"
              class="cart-btn__item absolute pl1 pr1 cursor"
            >
              <div class="f jcc aic pt05 pb05 border-radius bg-brown">
                <div class="p4 white">Đổi điểm</div>
              </div>
            </div>
          </div>
          <div class="cart-title__item">
						<div class="h4 mb05">
							{{ item.title_gift }}
            </div>
						<div v-if="item.product_gift && item.product_gift.length" class="list-product f aic gap05" style="flex-wrap: wrap;">
							<div>
								<span class="h6">Sản phẩm tặng: </span>
								<span class="h7 brown">{{ item.product_gift.map(e => e.title_product).join(', ') }}</span>
							</div>
						</div>
            <div class="f aic gap05">
              <div class="h6">Giá trị quà tặng:</div>
              <span class="p4 orange">{{
                converMoney(item.total_price_gift || item.discount_order)
              }}</span>
            </div>
						<div class="f aic gap05">
              <div class="h6">Số lượng:</div>
              <span class="p4 orange">{{ item.quantity_gift || 'Không giới hạn' }}</span>
            </div>
            <div class="f aic gap05">
              <div class="h6">Điểm quy đổi:</div>
              <span class="p4 orange">{{ item.exchange_point }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Loading :is-loading="isLoading" type="page" />

    <NotiView
      v-if="notiView"
      @closePopup="notiView = false"
      :title="title"
      :logo="logo"
    />

		<Popup
      :is-show="isShowPopup"
      :width="'20vw'"
      @close="closePopup"
    >	
			<div class="px1">
				<div class="f aic jcc">
					<img src="@/assets/gift.png" alt="" style="width: 150px; height: 150px; object-fit: cover;">
				</div>
				<div class="mb2 f aic jcc">
					<h4 v-if="giftChosed" class=""> Xác nhận đổi <span class="orange">{{giftChosed.exchange_point}}</span> điểm để lấy <span class="orange">{{giftChosed.title_gift}}</span> ? </h4>
				</div>
	
				<div class="w100 f aic jcb gap1">
					<div class="w100 border-radius px05 border h7 brown align-c cursor" @click="closePopup">Huỷ bỏ</div>
					<div class="w100 border-radius px05 border h7 bg-brown white align-c cursor" @click="exchangePointoGifts()">Xác nhận</div>
				</div>
			</div>
		</Popup>

		
		<Popup
      :is-show="isShowPopupMyGift"
      :width="'20vw'"
      @close="isShowPopupMyGift = false"
    >	
			<div class="px1">
				<h5 class="mb1">Danh sách quà tặng đã đổi:</h5>

				<div class="f fdc gap1">
					<div v-for="(item, index) in dataMyGifts" :key="index" class="my-gift__item border border-radius px1 f gap1">
						<div>
							<img src="@/assets/gift.png" alt="" style="width: 50px; height: 50px; object-fit: cover;">
						</div>

						<div class="h4 mb05">
              {{ item.gift.title_gift }}
            </div>
					</div>
				</div>
			</div>
		</Popup>
    <!-- footer -->
    <FooterSection></FooterSection>
  </div>
</template>

<script>
import HeaderType2 from "@/components/HeaderType2/HeaderType2.vue";
import FooterSection from "@/components/FooterSection/FooterSection.vue";
import Popup from "@/components/Popup/index.vue";
import { HTTP } from "@/http-default";
import Loading from "@/components/Loading/Loading.vue";
import NotiView from "@/components/NotiView/NotiView.vue";

export default {
  components: {
    HeaderType2,
    FooterSection,
    Loading,
    NotiView,
		Popup,
  },

  data() {
    return {
      dataGifts: [],
      dataGiftsCart: [],
			dataMyGifts: [],
      cartHover: false,

      isLoading: false,
      notiView: false,
      logo: "noti-success",
      title: "",
			isShowPopup: false,
			isShowPopupMyGift: false,
			giftChosed: null,

			myData: {},
    };
  },

  computed: {
    myAccount() {
      const myAccount = localStorage.getItem("myAccount");
      return myAccount ? JSON.parse(myAccount) : null;
    },
  },

  methods: {
    converMoney(value) {
      var money = value.toLocaleString("it-IT", {
        style: "currency",
        currency: "VND",
      });
      return money;
    },

    updateProductCart() {
      let data = localStorage.getItem("cartProudct");
      let cart = JSON.parse(data);
      this.dataGiftsCart = cart;
    },

    updateCart(value) {
      this.dataGiftsCart = value;
    },

		openPopup(gift) {
			this.isShowPopup = true
			this.giftChosed = gift
		},

		closePopup() {
			this.isShowPopup = false
			this.giftChosed = null
		},

    async exchangePointoGifts() {
			this.isLoading = true
      const payload = {
        gift_id: this.giftChosed.id,
        customer_id: this.myAccount.id,
      };

      HTTP.post("gift/customer-gift/", payload).then((res) => {
          this.isShowPopup = false;
          this.isLoading = false;
          this.notiView = true;
          // this.logout = "noti-success";
          this.title = res.data.data
          this.getData()
        })
        .catch((err) => {
          this.isShowPopup = false;
          this.isLoading = false;
          this.notiView = true;
          // this.logout = "noti-error";
          this.title = err?.response?.data?.data || err?.message
        });
    },

    getData() {
      HTTP.get("v1/api/gift/?depth=1&gift_for_customer=true").then((res) => {
        this.dataGifts = res.data.results;
      });

			HTTP.get(`v1/api/customer/${this.myAccount.id}/`).then((res) => {
        this.myData = res.data;
      });


			this.getMyGift()
    },

		getMyGift() {
			HTTP.get(`v1/api/customergift/?depth=1&customer=${this.myAccount.id}&used=false`).then((res) => {
        this.dataMyGifts = res.data.results;
      });
		},

		formatNumberShow(value) {
			const val = value > 999 ? (value / 1000).toFixed(3) : value;
			return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },

  created() {
    this.getData();
    this.updateProductCart();
  },
};
</script>

<style scoped lang='scss'>
@import "ProductWebSite.scss";
</style>