@<template>
  <div v-if="this.$route.fullPath == '/quan-ly-customer'">
        <div class="container-category border-radius bg-white-1 mv1">
            <div class="container-category__body h4 mb1">
                Danh mục
            </div>
            <div 
                v-if="statusChat.length === 0"
                @click="chatStatus(false, '')"
                class="container-category__info f aic pb1 border-bottom cursor">
                <div class="icon icon--customer"></div>
                <div class="p5 brown">Khách hàng</div>
            </div>
            <div    
                v-else
                @click="chatStatus(false, '')"
                class="container-category__info f aic pb1 border-bottom cursor">
                <div class="icon icon--customer"></div>
                <div class="p6 gray">Khách hàng</div>
            </div>
            <div class="container-category__chat mt1 p5">
                <div class="h4 mt05">Chăm sóc khách hàng</div>
                <div
                    v-if="statusChat === 'Drmom'"
                    @click="chatStatus(true, 'Drmom')"
                    class="f aic mt05 brown">
                    <div class="icon--facebook"></div>
                    <div class="ml025 p5">Facebook</div>
                </div>
                <div
                    v-else
                    @click="chatStatus(true, 'Drmom')"
                    class="f aic mt05 gray">
                    <div class="icon--facebook"></div>
                    <div class="ml025 p6">Facebook</div>
                </div>
                <div
                    v-if="statusChat === 'Zalo'"
                    @click="chatStatus(true, 'Zalo')" 
                    class="f aic mt05 brown">
                    <div class="icon--zalo"></div>
                    <div class="ml025 p5">Zalo</div>
                </div>
                <div
                    v-else
                    @click="chatStatus(true, 'Zalo')" 
                    class="f aic mt05 gray">
                    <div class="icon--zalo"></div>
                    <div class="ml025 p6">Zalo</div>
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    props: {
        data : {
            type: Object,
            default() {
                return {}
            }
        },
        dataCategory : {
            type: [Object, Array],
            default() {
                return {}
            }
        },
    },

    data () {
        return {
            statusChat: ''
        }
    },

    methods : {
        chatStatus (value, status) {
            this.$emit('changeChatStatus', (value, status))
            this.statusChat = status
        }
    }
}
</script>

<style scoped lang='scss'>
    @import 'CategoryLayout1.scss';
</style>