@<template>
  <div class="detail-order__container container">
    <div class="f aic mb2">
      <div class="pr1 h2 border-right">Đơn hàng</div>
      <div class="p5 pr05 pl1 gray">Đơn hàng</div>
      <div class="f aic">
        <svg width="8" height="12" viewBox="0 0 8 12" class="mr05">
          <use
            xlink="http://www.w3.org/2000/svg"
            href="#next"
            x="0"
            y="0"
          ></use>
        </svg>
        <div class="h5 brown pl05">Chi tiết đơn hàng</div>
      </div>
    </div>

    <div class="detail-order__body">
      <div v-if="dataOrder" class="detail-order__info">
        <!-- Mã đơn hàng -->
        <div v-if="dataOrder.status_order && !listDLO.length" class="detail-order__btn f jce aic mb1">
          <div
            v-if="dataOrder.status_order.title_status_order !== 'Đã huỷ'"
            @click="cancelOrder"
            class="border-radius pt075 pb075 pl1 pr1 p5 bg-black white cursor"
          >
            Hủy đơn
          </div>
          <div
            v-else-if="dataOrder.status_order.title_status_order === 'Đã huỷ'"
            class="border-radius pt075 pb075 pl1 pr1 p5 bg-gray-1 white"
          >
            Hủy đơn
          </div>
          <div
            @click="deleteOrder"
            class="border-radius pt075 pb075 pl1 pr1 p5 ml1 bg-brown white cursor"
          >
            Xóa đơn
          </div>
        </div>
        <div class="detail-order__key px1 border-radius mb1 bg-white-1 shadow">
          <div class="key__header f jcb aic mb1">
            <div class="p5 brown">#{{ dataOrder.order_key }}</div>
            <div
              @click="
                changeStatus ? (changeStatus = false) : (changeStatus = true)
              "
              v-if="dataOrder.status_order"
              class="status_order p5 f aic red cursor relative"
            >
              <div
                v-if="
                  dataOrder.status_order.title_status_order === 'Chờ xác nhận'
                "
                class="f aic pt025 pb025 pl05 pr05 border-radius orange bg-orange-1"
              >
                {{ dataOrder.status_order.title_status_order }}
                <div class="icon--arrown ml05"></div>
              </div>
              <div
                v-if="
                  dataOrder.status_order.title_status_order === 'Đang xử lý'
                "
                class="f aic pt025 pb025 pl05 pr05 border-radius blue bg-blue-1"
              >
                {{ dataOrder.status_order.title_status_order }}
                <div class="icon--arrown ml05"></div>
              </div>
              <div
                v-if="
                  dataOrder.status_order.title_status_order === 'Thành công'
                "
                class="f aic pt025 pb025 pl05 pr05 border-radius green bg-green-1"
              >
                {{ dataOrder.status_order.title_status_order }}
                <div class="icon--arrown ml05"></div>
              </div>
              <div
                v-if="dataOrder.status_order.title_status_order === 'Đã huỷ'"
                class="f aic pt025 pb025 pl05 pr05 border-radius red bg-red-1"
              >
                {{ dataOrder.status_order.title_status_order }}
                <div class="icon--arrown ml05"></div>
              </div>

              <!-- Chuyển trạng thái đơn hàng -->
              <div
                v-if="changeStatus"
                class="change-status absolute border-radius bg-white-1 shadow"
              >
                <div
                  v-for="(item, index) in dataStatusOrder"
                  :key="index"
                  @click="handleChangeStatus(item.id)"
                  class="change-status__item px1 h6 gray-1-text"
                >
                  {{ item.title_status_order }}
                </div>
              </div>
            </div>
          </div>

          <div class="key__body f fdc ais">
            <div class="w100 f jcb aic">
              <div class="h6">Thời gian đặt hàng</div>
              <div class="h7">
                {{ convertTime(dataOrder.time_create_order) }}
              </div>
            </div>
            <!-- <div class="w100 f jcb aic">
              <div class="h6">Thời gian thanh toán</div>
              <div class="h7">Chưa có</div>
            </div>
            <div class="w100 f jcb aic">
              <div class="h6">Mua từ link Affiliate</div>
              <div class="h7">Chưa có</div>
            </div> -->
            <div v-if="dataOrder.user_create" class="w100 f jcb aic">
              <div class="h6">Nhân viên bán hàng</div>
              <div class="h7">{{ dataOrder.user_create.username }}</div>
            </div>

            <div v-if="dataOrder.packer" class="w100 f jcb aic">
              <div class="h6">Nhân viên đóng hàng</div>
              <div class="h7">{{ dataOrder.packer }}</div>
            </div>

            <div v-if="dataOrder.note" class="w100 f jcb aic">
              <div class="h6">Ghi chú đơn hàng</div>
              <div class="h7">{{ dataOrder.note }}</div>
            </div>
          </div>
        </div>

        <!-- Thông tin người mua -->
        <div
          class="detail-order__customer px1 mb1 border-radius bg-white-1 shadow"
        >
          <div class="customer__header f jcb aic mb1">
            <div class="p5 brown">Thông tin người mua</div>
          </div>

          <div v-if="dataOrder.customer" class="customer__body f fdc ais">
            <div class="w100 f jcb aic">
              <div class="h6">Họ và tên</div>
              <div class="h7">{{ dataOrder.customer.full_name }}</div>
            </div>
            <div class="w100 f jcb aic">
              <div class="h6">Số điện thoại</div>
              <div class="h7">{{ dataOrder.customer.phone }}</div>
            </div>
            <div class="w100 f jcb aic">
              <div class="h6">Email</div>
              <div v-if="dataOrder.customer.email !== ''" class="h7">
                {{ dataOrder.customer.email }}
              </div>
              <div class="h7" v-else>Chưa có dữ liệu</div>
            </div>
            <div class="w100 f jcb aic">
              <div class="h6">Địa chỉ dặt hàng</div>
              <div class="h7">{{ dataOrder.customer.address }}</div>
            </div>
            <div class="w100 f jcb aic">
              <div class="h6">Ghi chú</div>
              <div v-if="dataOrder.customer.note !== ''" class="h7">
                {{ dataOrder.customer.note }}
              </div>
              <div class="h7" v-else>Chưa có dữ liệu</div>
            </div>
          </div>
        </div>

        <!-- Lịch sử cập nhật đơn hàng -->
        <div v-if="dataHistoryOrder && dataHistoryOrder.length" class="px1 border-radius bg-white-1 mb1">
          <div class="payment__header p5 mb1 brown">Lịch sử cập nhật đơn hàng</div>
          <div v-for="(item, index) in dataHistoryOrder" :key="index" class="w100 f aic gap1">
            <span style="width: 4px; aspect-ratio: 1; border-radius: 50%; background: #000"></span>
            <div class="h6 gray-1-text">{{ formatDate(item.created_at, 'time') }} <span class="italic">({{ item.user_updated.username }})</span></div>
            <div class="h7">{{ item.title }}</div>
            <div v-if="item.old_value && item.new_value" class="h7">{{ item.old_value }} &nbsp; > &nbsp; {{ item.new_value }}</div>
          </div>
        </div>

        <div class="px1 border-radius bg-white-1 mb1">
          <div class="payment__header p5 brown">Thông tin thanh toán</div>
          <div class="">
            <div v-if="dataPayments && dataPayments.length">
              <div v-for="(item, index) in dataPayments" :key="index" class="">
                <p v-if="item.payment_method" class="h6">
                  <span class="">Phương thức thanh toán: </span>
                  <span class="h7">{{ item.payment_method.title }}</span>
                </p>
                <div v-if="item.media">
                  <p class="h6">Hình ảnh xác nhận:</p>
                  <img :src="item.media.file_media" alt="" style="width: 80px; aspect-ratio: 1; object-fit: cover; border-radius: 8px;">
                </div>
              </div>
            </div>
            <div v-else class="border-radius pt075 pb075 pl1 pr1 p5 bg-brown white cursor mt1" style="width: fit-content;" @click="isShowPopupAddPayment = true">
              Thêm thông tin thanh toán
            </div>
          </div>
        </div>


        <!-- Thông tin thanh toán -->
        <div class="detail-order__payment px1 border-radius bg-white-1 relative">
          <img v-if="dataOrder.paid" src="@/assets/paid.png" style="width: 60px; height: auto; object-fit: cover; top: 0.5em; right: 0.5em; transform: rotate(45deg);" class="absolute" />
          <div class="payment__header p5 mb1 brown">Thông tin thanh toán</div>
          <div class="payment__body">
            <div class="body__title">
              <div class="p6 gray-1-text align-l">Sản phẩm</div>
              <div class="p6 gray-1-text align-c">Số lượng</div>
              <div class="p6 gray-1-text align-c">Giá bán</div>
              <div class="p6 gray-1-text align-r">Thành tiền</div>
            </div>

            <div class="body__list">
              <div
                v-for="(item, index) in dataSubOrder"
                :key="index"
                class="body__item aic border-bottom pt1 pb1"
              >
                <div class="f aic">
                  <div>
                    <img
                      style="height: 48px; width: 48px; border-radius: 8px"
                      :src="
                        'https://f004.backblazeb2.com/file/media-drmom/' +
                        item.media_product[0].file_media
                      "
                      alt=""
                    />
                  </div>
                  <div class="f fdc ais jcb ml1">
                    <div class="h7 blue">{{ item.product.title_product }}</div>
                    <div class="h6 mt025 gray-1-text">
                      Tồn kho:
                      <span
                        >{{ item.product.inventory }}
                        {{ item.product.unit }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="h6 align-c">x{{ item.quantity_order }}</div>
                <div class="h6 align-c">
                  {{ convertMoney(item.price_product) }}
                </div>
                <div class="h7 align-r brown">
                  {{
                    totalMoneyProduct(item.quantity_order, item.price_product)
                  }}
                </div>
              </div>
            </div>

            <div class="body__total f fdc ais pt1 pb1 mb1 border-bottom">
              <div class="w100 f jcb aic">
                <div class="h6">Tổng tiền hàng</div>
                <div class="h7">{{ convertMoney(dataOrder.total_price) }}</div>
              </div>
              <div class="w100 f jcb aic">
                <div class="h6">Giảm giá</div>
                <div class="h7 gray-1-text">Chưa có mã giảm giá</div>
              </div>
            </div>

            <div class="f jcb aic">
              <div class="h6">Tổng đơn hàng</div>
              <div class="h3 brown">
                {{ convertMoney(parseInt(dataOrder.total_price)) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="detail-order__create-dlo bg-white-1 border-radius px1 w-full black f fdc gap1"
        :style="!listDLO.length && `margin-top: 4em;`"
        style="height: fit-content"
      >
        <p class="h5 brown">Thông tin đơn giao hàng</p>
        <template v-if="listDLO.length">
          <div v-for="(item, index) in listDLO" :key="index" class="f jcb gap1">
            <div class="f fdc gap05">
              <div class="f aic jcb gap1 w100">
                <p class="p7">Mã đơn giao:</p>
                <p class="h6 brown">{{ item.order_number }}</p>
              </div>
              <div class="f aic jcb gap1 w100">
                <p class="p7">Phí vận chuyển:</p>
                <p class="h6 brown">{{ convertMoney(item.money_total) }}</p>
              </div>
              <div class="f aic jcb gap1 w100">
                <p class="p7">Trọng lượng</p>
                <p class="h6 brown">{{ item.product_weight }}</p>
              </div>
              <div class="f aic jcb gap1 w100">
                <p class="p7">Kích thước (Dài, Rộng, Cao):</p>
                <p class="h6 brown">
                  {{
                    [
                      item.product_length,
                      item.product_width,
                      item.product_height,
                    ].join(" - ")
                  }}
                </p>
              </div>
              <div class="f aic jcb gap1 w100">
                <p class="p7">Giá trị hàng hoá:</p>
                <p class="h6 brown">{{ convertMoney(item.product_price) }}</p>
              </div>
              <div class="f aic jcb gap1 w100">
                <p class="p7">Tiền thu hộ:</p>
                <p class="h6 brown">
                  {{ convertMoney(item.money_collection) }}
                </p>
              </div>
              <div class="f aic jcb gap1 w100">
                <p class="p7">Ghi chú:</p>
                <p class="h6 brown">{{ item.note || "Không có ghi chú" }}</p>
              </div>
              <div class="f aic jcb gap1 w100">
                <p class="p7">Thông tin người gửi:</p>
                <p class="h6 brown align-r">
                  {{
                    [
                      item.sender_address,
                      item.sender_fullname,
                      item.sender_phone,
                    ].join(" - ")
                  }}
                </p>
              </div>
              <div class="f aic jcb gap1 w100">
                <p class="p7">Thông tin người nhận:</p>
                <p class="h6 brown align-r">
                  {{
                    [
                      item.receiver_address,
                      item.receiver_fullname,
                      item.receiver_phone,
                    ].join(" - ")
                  }}
                </p>
              </div>
              <div class="f aic jcb gap1 w100">
                <p class="p7">Người tạo</p>
                <p class="h6 brown">
                  {{
                    `${item.user_created.username}  (${convertTime(
                      item.created_at
                    )})`
                  }}
                </p>
              </div>
            </div>
						<div>
							<div
								class="transport-partner__list__item px05 border-radius bg-gray-2 f aic jcc fdc cursor"
								style="height: fit-content"
							>
								<img
									:src="
										listTransportPartner.find(
											(e) => e.code === item.tranport_partner_code
										).image
									"
									alt=""
									class="border-radius"
								/>
								<p class="h7 pv05">
									{{
										listTransportPartner.find(
											(e) => e.code === item.tranport_partner_code
										).title
									}}
								</p>
							</div>
							<div v-if="item.status" class="w100 mt1">
								<div
									:class="[
										item.status.code === 'NEW' && 'f aic jcc pv05 border-radius orange bg-orange-1',
										item.status.code === 'INPROCESS' && 'f aic jcc pv05 border-radius blue bg-blue-1',
										item.status.code === 'COMPLETE' && 'f aic jcc pv05 border-radius green bg-green-1',
										(item.status.code === 'CANCEL' || item.status.code === 'FAILED') && 'f aic jcc pv05 border-radius red bg-red-1',
										]"
								>
									{{ item.status.title }}
								</div>
							</div>
						</div>
          </div>
        </template>

        <template v-else-if="!isLoadingPage">
          <div class="f aic gap1 fdc">
            <div class="w100 f fdc gap05">
              <p class="h4">Thông tin người gửi</p>
              <div class="f gap1">
                <div class="w100">
                  <p class="p6">Họ tên <span class="red">*</span></p>
                  <input
                    v-model="dataSender.full_name"
                    class="w100"
                    type="text"
                    placeholder="Nhập Họ tên"
                  />
                </div>
                <div class="w100">
                  <p class="p6">Số điện thoại <span class="red">*</span></p>
                  <input
                    v-model="dataSender.phone"
                    class="w100"
                    type="text"
                    placeholder="Nhập số điện thoại"
                  />
                </div>
              </div>
              <div class="f aic gap05">
                <div class="w100">
                  <p class="p6">Tỉnh/Thành phố <span class="red">*</span></p>
                  <input
                    v-model="dataSender.province"
                    class="w100"
                    type="text"
                    placeholder="Nhập Tỉnh/Thành phố"
                  />
                  <p class="h6 gray-text">Vd: Hà Nội</p>
                </div>
                <div class="w100">
                  <p class="p6">Quận/Huyện <span class="red">*</span></p>
                  <input
                    v-model="dataSender.district"
                    class="w100"
                    type="text"
                    placeholder="Nhập Quận/Huyện"
                  />
                  <p class="h6 gray-text">Vd: Nam Từ Niêm</p>
                </div>
                <div class="w100">
                  <p class="p6">Xã/Phường <span class="red">*</span></p>
                  <input
                    v-model="dataSender.ward"
                    class="w100"
                    type="text"
                    placeholder="Nhập Xã/Huyện/Phường"
                  />
                  <p class="h6 gray-text">Vd: Trung Văn</p>
                </div>
              </div>
              <div>
                <p class="p6">
                  Địa chỉ chi tiết (Số nhà, đường) <span class="red">*</span>
                </p>
                <input
                  v-model="dataSender.address"
                  class="w100"
                  type="text"
                  placeholder="Nhập Địa chỉ chi tiết"
                  @blur="getListOrderSeviceVTP"
                />
                <p class="h6 gray-text">
                  Vd: 48 Tố Hữu, Trung Văn, Nam Từ Niêm, Hà Nội
                </p>
              </div>
            </div>

            <div class="w100 f fdc gap05">
              <p class="h4">Thông tin người nhận</p>
              <div class="f gap1">
                <div class="w100">
                  <p class="p6">Họ tên <span class="red">*</span></p>
                  <input
                    v-model="dataReceiver.full_name"
                    class="w100"
                    type="text"
                    placeholder="Nhập Họ tên"
                  />
                </div>
                <div class="w100">
                  <p class="p6">Số điện thoại <span class="red">*</span></p>
                  <input
                    v-model="dataReceiver.phone"
                    class="w100"
                    type="text"
                    placeholder="Nhập số điện thoại"
                  />
                </div>
              </div>
              <div class="f aic gap05">
                <div class="w100">
                  <p class="p6">Tỉnh/Thành phố <span class="red">*</span></p>
                  <input
                    v-model="dataReceiver.province"
                    class="w100"
                    type="text"
                    placeholder="Nhập Tỉnh/Thành phố"
                  />
                  <p class="h6 gray-text">Vd: Hà Nội</p>
                </div>
                <div class="w100">
                  <p class="p6">Quận/Huyện <span class="red">*</span></p>
                  <input
                    v-model="dataReceiver.district"
                    class="w100"
                    type="text"
                    placeholder="Nhập Quận/Huyện"
                  />
                  <p class="h6 gray-text">Vd: Nam Từ Niêm</p>
                </div>
                <div class="w100">
                  <p class="p6">Xã/Phường <span class="red">*</span></p>
                  <input
                    v-model="dataReceiver.ward"
                    class="w100"
                    type="text"
                    placeholder="Nhập Xã/Huyện/Phường"
                  />
                  <p class="h6 gray-text">Vd: Trung Văn</p>
                </div>
              </div>
              <div>
                <p class="p6">
                  Địa chỉ chi tiết (Số nhà, đường) <span class="red">*</span>
                </p>
                <input
                  v-model="dataReceiver.address"
                  class="w100"
                  type="text"
                  placeholder="Nhập Địa chỉ chi tiết"
                  @blur="getListOrderSeviceVTP"
                />
                <p class="h6 gray-text">
                  Vd: 48 Tố Hữu, Trung Văn, Nam Từ Niêm, Hà Nội
                </p>
              </div>
            </div>
          </div>

          <p class="h4">Đối tác giao hàng</p>
          <div class="transport-partner__list f aic gap1">
            <div
              v-for="(item, index) in listTransportPartner"
              :key="index"
              class="transport-partner__list__item px05 border-radius bg-gray-2 f aic jcc fdc cursor"
              :class="[
                partnerSelected.code === item.code &&
                  'transport-partner__list__item--active',
              ]"
              @click="handleSelectPartner(item)"
            >
              <img :src="item.image" alt="" class="border-radius" />
              <p class="h7 pv05">{{ item.title }}</p>
            </div>
          </div>

          <template v-if="partnerSelected.code === 'VTP'">
            <div>
              <p class="p6 mb05">
                Dịch vụ cộng thêm
              </p>
              <div class="f gap1" style="flex-wrap: wrap">
                <div
                  v-for="(service, indexService) in orderSevicesAddList"
                  :key="indexService"
                  class="px05 border-radius bg-gray-2 f aic gap05"
                >
                  <input
                    v-model="service.isChecked"
                    :id="`service-${indexService}`"
                    type="checkbox"
                    @change="getPriceOfService"
                  />
                  <label :for="`service-${indexService}`">{{
                    service.label
                  }}</label>
                </div>
              </div>
            </div>
            <div class="f aic gap1">
              <div class="w100">
                <p class="p6">Tiền thu hộ</p>
                <input-number
                  v-model="money_collection"
                  class="w100"
                  placeholder="Nhập tiền thu hộ"
                  @blur="getPriceOfService"
                >
                  <template #prefix>
                    <p class="gray-text-1">VNĐ</p>
                  </template>
                </input-number>
              </div>
            </div>
            <div class="f aic gap1">
              <div class="w100">
                <p class="p6">Giá trị hàng hoá <span v-if="requiredProductPrice" class="red">*</span></p>
                <input-number
                  v-model="packageData.price"
                  class="w100"
                  type="text"
                  placeholder="Nhập tiền thu hộ"
                >
                  <template #prefix>
                    <p class="gray-text-1">VNĐ</p>
                  </template>
                </input-number>
                <span v-if="requiredProductPrice" class="red">Dịch vụ: Giá trị cao yêu cầu nhập</span>
              </div>
              <div class="w100">
                <p class="p6">Trọng lượng (g) <span class="red">*</span></p>
                <input-number
                  v-model="packageData.weight"
                  type="text"
                  placeholder="Nhập trọng lượng"
                  class="w100"
                  @blur="getPriceOfService"
                />
              </div>
            </div>
            <div class="f aic gap1 w100">
              <div class="w100">
                <p class="p6">Chiều dài (cm)</p>
                <input-number
                  v-model="packageData.length"
                  type="text"
                  placeholder="Nhập chiều dài"
                  class="w100"
                  @blur="getPriceOfService"
                />
              </div>
              <div class="w100">
                <p class="p6">Chiều rộng (cm)</p>
                <input-number
                  v-model="packageData.width"
                  type="text"
                  placeholder="Nhập chiều dài"
                  class="w100"
                  @blur="getPriceOfService"
                />
              </div>
              <div class="w100">
                <p class="p6">Chiều cao (cm)</p>
                <input-number
                  v-model="packageData.height"
                  type="text"
                  placeholder="Nhập chiều dài"
                  class="w100"
                  @blur="getPriceOfService"
                />
              </div>
            </div>
            <template v-if="isRequriedSize">
              <p class="p5 red">
                Khối lượng lớn hơn 10.000g bắt buộc nhập kích thước
              </p>
            </template>
            <div>
              <p class="p6">Ghi chú</p>
              <input
                v-model="note"
                type="text"
                placeholder="Nhập ghi chú"
                class="w100"
              />
            </div>
            <div>
              <p class="h4">Thông tin thanh toán</p>
              <template v-if="isErrorOrderServiceVTP">
                <p class="red">
                  Lấy thông tin gói cước lỗi. Vui lòng chọn lại các option !
                </p>
              </template>
              <template v-if="isLoadingOrderSeviceVTP || orderService.length">
                <p class="h5">Chọn gói cước <span class="red">*</span></p>
                <div class="w100 f gap1">
                  <div
                    class="border border-gray-1 border-radius px1 f aic fdc gap1 w50"
                  >
                    <Loading :is-loading="isLoadingOrderSeviceVTP" />
                    <div
                      v-for="(service, orderServiceIndex) in orderService"
                      :key="orderServiceIndex"
                      class="f aic gap1 px05 border-radius bg-gray-2 w100"
                    >
                      <input
                        :id="`vtp-service-${orderServiceIndex}`"
                        type="radio"
                        name="orderService"
                        :value="service.MA_DV_CHINH"
                        @input="getPriceOfService(service)"
                      />
                      <label
                        :for="`vtp-service-${orderServiceIndex}`"
                        class="w100"
                      >
                        <p class="p6">{{ service.TEN_DICHVU }}</p>
                        <p class="p6">
                          Thời gian:
                          <span class="h6 brown">{{
                            convertMoney(service.THOI_GIAN)
                          }}</span>
                        </p>
                        <p class="p6">
                          Giá cước ước tính:
                          <span class="h6 brown">{{
                            convertMoney(service.GIA_CUOC)
                          }}</span>
                        </p>
                      </label>
                    </div>
                  </div>
                  <div
                    class="border border-gray-1 border-radius px1 f aic jcb fdc gap1 w50"
                  >
                    <div>
                      <Loading :is-loading="isLoadingGetPrice" />
                    </div>
                    <div v-if="priceTransportVTP" class="f aic jcb w100">
                      <p class="p5">Tổng cước:</p>
                      <p class="h5">
                        {{ convertMoney(priceTransportVTP.MONEY_TOTAL || 0) }}
                      </p>
                    </div>
                    <template v-if="isErorGetPrice">
                      <p class="red">
                        Lấy thông tin phí vận chuyển lỗi. Vui lòng chọn lại các
                        option !
                      </p>
                    </template>
                  </div>
                </div>
              </template>
              <p v-else class="p5">Chưa có thông tin gói cước</p>
            </div>
          </template>

          <template v-if="partnerSelected.code === 'GHTK'">
            <div>
              <p class="p6">Dịch vụ gia tăng <span class="red">*</span></p>
              <div class="f gap1" style="flex-wrap: wrap">
                <div
                  v-for="(service, indexService) in listIncreasedServices"
                  :key="indexService"
                  class="px05 border-radius bg-gray-2 f aic gap05"
                >
                  <input
                    v-model="service.isChecked"
                    :id="`service-${indexService}`"
                    type="checkbox"
                  />
                  <label :for="`service-${indexService}`">{{
                    service.label
                  }}</label>
                </div>
              </div>
            </div>
            <div class="f aic gap1">
              <div class="w100">
                <p class="p6">Tiền thu hộ</p>
                <input
                  v-model="money_collection"
                  class="w100"
                  type="text"
                  placeholder="Nhập tiền thu hộ"
                  @blur="getPriceOfService"
                />
              </div>
            </div>
            <div class="f aic gap1">
              <div class="w100">
                <p class="p6">Giá trị hàng hoá <span class="red">*</span></p>
                <input
                  v-model="packageData.price"
                  type="text"
                  placeholder="Nhập giá trị hàng hoá"
                  class="w100"
                />
              </div>
              <div class="w100">
                <p class="p6">Trọng lượng (g) <span class="red">*</span></p>
                <input
                  v-model="packageData.weight"
                  type="text"
                  placeholder="Nhập trọng lượng"
                  class="w100"
                  @blur="getPriceOfService"
                />
              </div>
            </div>
            <div class="f aic gap1 w100">
              <div class="w100">
                <p class="p6">Chiều dài (cm)</p>
                <input
                  v-model="packageData.length"
                  type="text"
                  placeholder="Nhập chiều dài"
                  class="w100"
                  @blur="getPriceOfService"
                />
              </div>
              <div class="w100">
                <p class="p6">Chiều rộng (cm)</p>
                <input
                  v-model="packageData.width"
                  type="text"
                  placeholder="Nhập chiều rộng"
                  class="w100"
                  @blur="getPriceOfService"
                />
              </div>
              <div class="w100">
                <p class="p6">Chiều cao (cm)</p>
                <input
                  v-model="packageData.height"
                  type="text"
                  placeholder="Nhập chiều cao"
                  class="w100"
                  @blur="getPriceOfService"
                />
              </div>
            </div>
            <div>
              <p class="p6">Ghi chú</p>
              <input
                v-model="note"
                type="text"
                placeholder="Nhập ghi chú"
                class="w100"
              />
            </div>
            <div
              class="border border-gray-1 border-radius px1 f aic jcb fdc gap1 w50"
            >
              <div>
                <Loading :is-loading="isLoadingGetPrice" />
              </div>
              <div v-if="priceTransportGHTK" class="f aic jcb w100">
                <p class="p5">Tổng cước:</p>
                <p class="h5">
                  {{ convertMoney(priceTransportGHTK.MONEY_TOTAL || 0) }}
                </p>
              </div>
              <template v-if="isErorGetPrice">
                <p class="red">
                  Lấy thông tin phí vận chuyển lỗi. Vui lòng chọn lại các option
                  !
                </p>
              </template>
            </div>
          </template>

          <template v-if="isErrorCreateDLO">
            <p class="red">
              Vui lòng kiểm tra lại các yêu cầu bắt buộc và thử lại.
            </p>
          </template>
          <div class="w100 f aic jce">
            <div
              @click="createDLO"
              class="border-radius pt075 pb075 pl1 pr1 p5 ml1 bg-brown white cursor"
            >
              Tạo đơn giao hàng
            </div>
          </div>
        </template>
      </div>
    </div>

    <Loading :is-loading="isLoadingCreateDLO || isLoadingPage" type="page" />

    <NotiView
      v-if="notiView"
      @closePopup="closePopup"
      :title="title"
      :logo="logo"
      @updateData="updateDataSuccess"
    />

    <Popup 
      :isShow="isShowPopupAddPayment"
      width="25vw"
      @close="isShowPopupAddPayment = false"
    >
      <div class="px1 f fdc gap1" style="user-select: none">
        <div class="h4 brown">
          Xác nhận thanh toán
        </div>
        <div class="f jcb gap1">
          <div class="f fdc gap05">
            <div v-for="(item, index) in dataPaymentMethods" :key="index" class="f aic">
              <input v-model="payment_method" :id="`method-${index}`" type="radio" name="group1" :value="item.id" />
              <label class="ml05 h7 gray" :for="`method-${index}`">{{ item.title }}</label>
            </div>
          </div>
          <div v-if="imagePreview">
            <label for="add-img-payment" class="cursor">
              <img
                :src="imagePreview.image"
                alt="product"
                style="width: 80px; aspect-ratio: 1; object-fit: cover; border-radius: 8px;"
              />
            </label>
          </div>
          <div v-else>
            <label for="add-img-payment" class="add-image f aic jcc cursor">
              <p class="h6 brown">Thêm ảnh</p>
            </label>
          </div>
          <div style="display: none">
            <input id="add-img-payment" type="file" name="group1" accept="image/*" @change="handleChoseImage" />
          </div>
        </div>
        <div class="w100 f aic gap1">
          <div
            class="w100 pt075 pb075 f jcc aic border-radius bg-white brown cursor p5 border"
            @click="isShowPopupAddPayment = false"
          >
            Huỷ
          </div>
          <button
            class="w100 pt075 pb075 f jcc aic border-radius bg-brown white cursor p5"
            :class="!payment_method || !imagePreview ? 'bg-brown-1' : 'bg-brown'"
            :disabled="!payment_method || !imagePreview"
            @click="handleAddPayment"
          >
            Xác nhận
          </button>
        </div>
      </div>
    </Popup>

    <div class="hide">
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="next"
          d="M6.90625 6.25L2.3125 10.875C2.125 11.0625 1.94792 11.0625 1.78125 10.875L1.15625 10.2812C0.989583 10.0938 0.989583 9.91667 1.15625 9.75L4.875 6L1.15625 2.25C0.989583 2.08333 0.989583 1.90625 1.15625 1.71875L1.78125 1.125C1.94792 0.9375 2.125 0.9375 2.3125 1.125L6.90625 5.75C7.07292 5.91667 7.07292 6.08333 6.90625 6.25Z"
          fill="#BABABA"
        />
      </svg>
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="bin"
          d="M8.375 13H9.125C9.3125 13 9.5 12.8438 9.5 12.625V5.875C9.5 5.6875 9.3125 5.5 9.125 5.5H8.375C8.15625 5.5 8 5.6875 8 5.875V12.625C8 12.8438 8.15625 13 8.375 13ZM13.5 2.5H10.9062L9.84375 0.75C9.59375 0.3125 9.09375 0 8.5625 0H5.40625C4.875 0 4.375 0.3125 4.125 0.75L3.0625 2.5H0.5C0.21875 2.5 0 2.75 0 3V3.5C0 3.78125 0.21875 4 0.5 4H1V14.5C1 15.3438 1.65625 16 2.5 16H11.5C12.3125 16 13 15.3438 13 14.5V4H13.5C13.75 4 14 3.78125 14 3.5V3C14 2.75 13.75 2.5 13.5 2.5ZM5.34375 1.59375C5.375 1.5625 5.4375 1.5 5.5 1.5C5.5 1.5 5.5 1.5 5.53125 1.5H8.46875C8.53125 1.5 8.59375 1.5625 8.625 1.59375L9.15625 2.5H4.8125L5.34375 1.59375ZM11.5 14.5H2.5V4H11.5V14.5ZM4.875 13H5.625C5.8125 13 6 12.8438 6 12.625V5.875C6 5.6875 5.8125 5.5 5.625 5.5H4.875C4.65625 5.5 4.5 5.6875 4.5 5.875V12.625C4.5 12.8438 4.65625 13 4.875 13Z"
          fill="#1A1A1A"
        />
      </svg>
      <svg
        width="19"
        height="17"
        viewBox="0 0 19 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="note"
          d="M12.5625 11.7812C12.5 11.8438 12.5 11.9062 12.5 11.9688V15.5H1.5V4.5H9.03125C9.09375 4.5 9.15625 4.5 9.21875 4.4375L10.2188 3.4375C10.375 3.28125 10.25 3 10.0312 3H1.5C0.65625 3 0 3.6875 0 4.5V15.5C0 16.3438 0.65625 17 1.5 17H12.5C13.3125 17 14 16.3438 14 15.5V10.9688C14 10.75 13.7188 10.625 13.5625 10.7812L12.5625 11.7812ZM17.4375 5.5C18.1562 4.78125 18.1562 3.625 17.4375 2.90625L16.0938 1.5625C15.375 0.84375 14.2188 0.84375 13.5 1.5625L5.3125 9.75L5 12.5938C4.90625 13.4062 5.59375 14.0938 6.40625 14L9.25 13.6875L17.4375 5.5ZM14.375 6.4375L8.5625 12.25L6.5 12.5L6.75 10.4375L12.5625 4.625L14.375 6.4375ZM16.375 3.96875C16.5312 4.09375 16.5312 4.28125 16.4062 4.4375L15.4375 5.40625L13.625 3.5625L14.5625 2.625C14.6875 2.46875 14.9062 2.46875 15.0312 2.625L16.375 3.96875Z"
          fill="#1A1A1A"
        />
      </svg>
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="more"
          d="M10.5 7.3125C10.5 7.14844 10.3359 6.98438 10.1719 6.98438H7.76562V4.57812C7.76562 4.41406 7.60156 4.25 7.4375 4.25H6.5625C6.37109 4.25 6.23438 4.41406 6.23438 4.57812V6.98438H3.82812C3.63672 6.98438 3.5 7.14844 3.5 7.3125V8.1875C3.5 8.37891 3.63672 8.51562 3.82812 8.51562H6.23438V10.9219C6.23438 11.1133 6.37109 11.25 6.5625 11.25H7.4375C7.60156 11.25 7.76562 11.1133 7.76562 10.9219V8.51562H10.1719C10.3359 8.51562 10.5 8.37891 10.5 8.1875V7.3125ZM13.7812 7.75C13.7812 4.00391 10.7461 0.96875 7 0.96875C3.25391 0.96875 0.21875 4.00391 0.21875 7.75C0.21875 11.4961 3.25391 14.5312 7 14.5312C10.7461 14.5312 13.7812 11.4961 13.7812 7.75ZM12.4688 7.75C12.4688 10.7852 10.0078 13.2188 7 13.2188C3.96484 13.2188 1.53125 10.7852 1.53125 7.75C1.53125 4.74219 3.96484 2.28125 7 2.28125C10.0078 2.28125 12.4688 4.74219 12.4688 7.75Z"
          fill="#2577C9"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import NotiView from "@/components/NotiView/NotiView.vue";
import Loading from "@/components/Loading/Loading.vue";
import InputNumber from "@/components/InputNumber/InputNumber.vue";
// import vueSelect from "vue-select";
import Popup from "@/components/Popup/index.vue";

import { HTTP } from "@/http-default";

export default {
  components: {
    NotiView,
    Loading,
    InputNumber,
    Popup
    // "v-select": vueSelect,
  },

  data() {
    return {
      dataOrder: {},
      dataSubOrder: [],
      dataStatusOrder: [],
      dataHistoryOrder: [],
      dataPayments: [],
      listDLO: [],
      notiView: false,
      changeStatus: false,

      isShowPopupAddPayment: false,
      dataPaymentMethods: [],
      payment_method: null,

      imagePreview: null,

      // vận chuyển
      listTransportPartner: [
        {
          code: "VTP",
          image: require("@/assets/dlo/viettel_post.png"),
          title: "Viettel Post",
          description: "Đi sâu hơn, đi xa hơn để con người gần nhau hơn",
        },
        // {
        //   code: 'JT',
        //   image: require('~/assets/img/dlo/jt.png'),
        //   title: 'J&T Express',
        //   description: 'Express your online business',
        // },
        {
          code: "GHTK",
          image: require("@/assets/dlo/ghtk.png"),
          title: "Giao hàng tiết kiệm",
          description: "Giao hàng nhanh và rẻ, tiết kiệm là hàng đầu",
        },
      ],
      partnerSelected: {},

      userPaymentOptions: [
        { title: "Người gửi", value: 1 },
        { title: "Người nhận", value: 2 },
      ],
      userPayment: null,

      getGoods: null,
      listGetGoods: [
        {
          code: "home",
          title: "Đến lấy hàng tại nhà",
        },
        {
          code: "post",
          title: "Gửi tại bưu cục",
        },
      ],
      listIncreasedServices: [],
      orderSevicesAddList: [],
      dataReceiver: {
        full_name: "",
        phone: "",
        province: "",
        district: "",
        ward: "",
        address: "",
      },
      dataSender: {
        full_name: "",
        phone: "",
        province: "",
        district: "",
        ward: "",
        address: "",
      },
      packageData: {
        price: null,
        width: null,
        height: null,
        weight: null,
        length: null,
      },
      orderService: [],
      orderServiceSelected: null,
      money_collection: null,
      note: "",

      isErrorOrderServiceVTP: false, // lỗi khi lấy giá dịch vụ của VTP
      isErorGetPrice: false, // lỗi khi tính giá cước
      isErrorCreateDLO: false, // lỗi khi tạo đơn vận chuyển

      // loading
      isLoadingOrderSeviceVTP: false,
      isLoadingGetPrice: false,
      isLoadingCreateDLO: false,
      isLoadingPage: true,

      // Tạm Tính phí vận chuyển
      priceTransportVTP: null,
      priceTransportGHTK: null,
    };
  },

  computed: {
    isRequriedSize() {
      return (
        this.partnerSelected?.code === "VTP" &&
        Number(this.packageData.weight) >= 10000
      );
    },

    orderSevicesAddListSelected() {
      return this.orderSevicesAddList.filter((e) => e.isChecked);
    },

    listIncreasedServicesSelected() {
      return this.listIncreasedServices.filter((e) => e.isChecked);
    },

    myAccount() {
      return JSON.parse(localStorage.getItem("myAccount") || {});
    },

    requiredProductPrice() {
      return this.orderSevicesAddList.find((e) => e.code === 'HGC')?.isSelected
    },

    userId() {
      return localStorage.getItem("idUser");
    },
  },

  // watch: {
  // 	packageData: {
  // 		handler() {
  // 			this.getPriceOfService()
  // 		},
  // 		deep: true
  // 	}
  // },

  methods: {
    convertTime(date, _type = 'time') {
      const newDate = new Date(date)
      const dformat =
        (_type ? [newDate.getHours(), newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes()].join(':') + ' ' : '')
        +
        [
          newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate(),
          newDate.getMonth() + 1 < 10 ? '0' + (newDate.getMonth() + 1) : newDate.getMonth() + 1,
          newDate.getFullYear(),
        ].join('/')

      return dformat
    },
    convertMoney(value) {
      var x = value;
      x = x.toLocaleString("it-IT", { style: "currency", currency: "VND" });
      return x;
    },

    totalMoneyProduct(quantity, price) {
      var x = parseInt(quantity) * parseInt(price);
      x = x.toLocaleString("it-IT", { style: "currency", currency: "VND" });
      return x;
    },

    closePopup() {
      this.notiView = false;
    },

    cancelOrder() {
      this.title = "Xác nhận hủy đơn?";
      this.logo = "noti-warning";
      this.notiView = true;
    },

    deleteOrder() {
      this.title = "Xóa đơn đồng thời sẽ hủy đơn. Bạn vẫn muốn tiếp tục?";
      this.logo = "noti-warning";
      this.notiView = true;
    },

    updateDataSuccess() {
      this.getData();
    },

    handleChangeStatus(value) {
      var data = JSON.stringify({
        status_order: value,
      });
      HTTP.patch("v1/api/order/" + this.$route.params.id + "/", data).then(
        () => {
          this.getData();
        }
      );
    },

    async getData() {
      await HTTP.get(
        "v1/api/order/" + this.$route.params.id + "/?depth=1"
      ).then((res) => {
        this.dataOrder = res.data;

        HTTP.get("v1/api/suborder/?depth=1").then((res) => {
          var data = res.data.results;
          this.dataSubOrder = data.filter(
            (item) => item.order.id === this.dataOrder.id
          );
        });

        HTTP.get(`v1/api/historyorder/?depth=1&order=${this.dataOrder.id}`).then((res) => {
          const data = res.data.results;
          this.dataHistoryOrder = data
        });

        this.getDataPayment()
      });

      await HTTP.get("v1/api/statusorder/").then((res) => {
        this.dataStatusOrder = res.data.results;
      });
    },

    // add payment method
    getDataPaymentMethods() {
      HTTP.get(`v1/api/paymentmethod/`).then((res) => {
        const data = res.data.results;
        this.dataPaymentMethods = data
      });
    },
    getDataPayment() {
      HTTP.get(`v1/api/orderpayment/?order=${this.dataOrder.id}&depth=1`).then((res) => {
        const data = res.data.results;
        this.dataPayments = data
      });
    },

    handleChoseImage(event) {
      const item = {
        image: URL.createObjectURL(event.target.files[0]),
        name: event.target.files[0].name,
        imageFile: event.target.files[0]
      }
      this.imagePreview = item
    },

    async handleAddPayment() {
      this.isShowPopupAddPayment = false
      this.isLoadingPage = true
      const dataMedia = new FormData();
      dataMedia.append("file_media", this.imagePreview.imageFile);
      dataMedia.append("user_created", this.userId);
      dataMedia.append(
        "title_media",
        Math.floor(Math.random() * 1000000000)
      );

      await HTTP.post("v1/api/mediaorderpayment/", dataMedia).then(
        async (res) => {
          const a = await res.data.id;
          const payload = {
            title: `Thanh toán đơn hàng ${this.dataOrder.order_key}`,
            order: this.dataOrder.id,
            media: a,
            payment_method: this.payment_method,
            user_created: this.userId,
          }
          HTTP.post("v1/api/orderpayment/", payload).then(()=> {
            this.isLoadingPage = false
            this.imagePreview = null
            this.getData()
          })
        }
      );
      this.isLoadingPage = false
    },

    // vận chuyển func
    handleSelectPartner(partner) {
      this.partnerSelected = partner;
      this.getListOrderTypeAdd();
      if (!this.priceTransportVTP || !this.priceTransportGHTK) {
        this.getPriceOfService();
      }
    },

    async getListOrderSeviceVTP() {
      if (this.partnerSelected.code === "VTP") {
        this.priceTransportVTP = null;
        this.isLoadingOrderSeviceVTP = true;
        const payload = {
          sender_address: this.dataSender.address,
          receiver_address: this.dataReceiver.address,
          product_price: this.packageData.price || 0,
          money_collection: this.money_collection || 0,
          product_weight: this.packageData.weight || 0,
          product_length: this.packageData.length || 0,
          product_width: this.packageData.width || 0,
          product_height: this.packageData.height || 0,
        };

        await HTTP.post("/order/get-list-service-vtp/", payload).then((res) => {
          this.isLoadingOrderSeviceVTP = false;
          const data = res?.data?.data;
          if (data?.RESULT?.length) {
            this.orderService = data?.RESULT;
          } else {
            this.isErrorOrderService = true;
          }
        });
      }
    },

    async getListOrderTypeAdd() {
      const payload = {
        transport_partner_code: this.partnerSelected?.code,
      };
      if (
        !this.orderSevicesAddList?.length ||
        !this.listIncreasedServices?.length
      )
        await HTTP.post("/order/get-list-order-service-add/", payload).then(
          (res) => {
            const data = res.data.data;
            this.partnerSelected?.code === "VTP" &&
              (this.orderSevicesAddList = data?.Document?.length
                ? data?.Document?.map((e) => ({ ...e, isSelected: false }))
                : []);
            this.partnerSelected?.code === "GHTK" &&
              (this.listIncreasedServices = data?.length
                ? data.map((e) => ({ ...e, isSelected: false }))
                : []);
          }
        );
    },

    async getPriceOfService(service) {
      if (service && this.partnerSelected?.code === "VTP") {
        this.orderServiceSelected = service;
      }
      this.isLoadingGetPrice = true;
      const payload = {
        company_code: "DRMOM",
        transport_partner_code: this.partnerSelected?.code,
        receiver_fullname: this.dataReceiver.full_name,
        receiver_address: this.dataReceiver.address,
        receiver_province: this.dataReceiver.province,
        receiver_district: this.dataReceiver.district,
        receiver_ward: this.dataReceiver.ward,
        receiver_phone: this.dataReceiver.phone,
        sender_fullname: this.dataSender.full_name,
        sender_address: this.dataSender.address,
        sender_province: this.dataSender.province,
        sender_district: this.dataSender.district,
        sender_ward: this.dataSender.ward,
        sender_phone: this.dataSender.phone,
        product_price: this.packageData.price || 0,
        product_weight: this.packageData.weight || 0,
        product_length: this.packageData.length || 0,
        product_width: this.packageData.width || 0,
        product_height: this.packageData.height || 0,
        ...(this.partnerSelected?.code === "VTP"
          ? {
              order_service:
                this.orderServiceSelected?.MA_DV_CHINH || undefined,
            }
          : null),
        money_collection: this.money_collection || 0,
        order_service_add:
          this.partnerSelected?.code === "VTP"
            ? this.orderSevicesAddListSelected?.length
              ? this.orderSevicesAddListSelected.map((e) => e.id)
              : []
            : this.listIncreasedServicesSelected?.length
            ? this.listIncreasedServicesSelected.map((e) => e.id)
            : [],
      };

      await HTTP.post("/order/get-price-transport/", payload)
        .then((res) => {
          this.isLoadingGetPrice = false;
          const data = res?.data?.data?.data;
          const error = res?.data?.data?.error || !res?.data?.data?.data;
          if (data) {
            this.partnerSelected?.code === "VTP" &&
              (this.priceTransportVTP = data);
            this.partnerSelected?.code === "GHTK" &&
              (this.priceTransportGHTK = data);
          }

          if (error) {
            this.partnerSelected?.code === "VTP" &&
              (this.priceTransportVTP = null);
            this.partnerSelected?.code === "GHTK" &&
              (this.priceTransportGHTK = null);
          }
          this.isErorGetPrice = error;
        })
        .catch(() => {
          this.partnerSelected?.code === "VTP" &&
            (this.priceTransportVTP = null);
          this.partnerSelected?.code === "GHTK" &&
            (this.priceTransportGHTK = null);
          this.isLoadingGetPrice = false;
          this.isErorGetPrice = true;
        });
    },

    // checkAddress() {
    // 	return this.
    // },

    async createDLO() {
      this.isLoadingCreateDLO = true;
      const payload = {
        company_code: "DRMOM",
        tranport_partner_code: this.partnerSelected.code,
        receiver_fullname: this.dataReceiver.full_name,
        receiver_address: this.dataReceiver.address,
        receiver_province: this.dataReceiver.province,
        receiver_district: this.dataReceiver.district,
        receiver_ward: this.dataReceiver.ward,
        receiver_phone: this.dataReceiver.phone,
        sender_fullname: this.dataSender.full_name,
        sender_address: this.dataSender.address,
        sender_province: this.dataSender.province,
        sender_district: this.dataSender.district,
        sender_ward: this.dataSender.ward,
        sender_phone: this.dataSender.phone,
        product_price: this.packageData.price || 0,
        product_weight: this.packageData.weight || 0,
        product_length: this.packageData.length || 0,
        product_width: this.packageData.width || 0,
        product_height: this.packageData.height || 0,
        ...(this.partnerSelected?.code === "VTP"
          ? {
              order_service:
                this.orderServiceSelected?.MA_DV_CHINH || undefined,
            }
          : null),
        money_collection: this.money_collection || 0,
        note: this.note,
        unit_mass: 2,
        order_service_add:
          this.partnerSelected?.code === "VTP"
            ? this.orderSevicesAddListSelected?.length
              ? this.orderSevicesAddListSelected.map((e) => e.id)
              : []
            : this.listIncreasedServicesSelected?.length
            ? this.listIncreasedServicesSelected.map((e) => e.id)
            : [],
      };

      payload.user_created = this.userId
      payload.order_code = this.dataOrder.order_key
      payload.order = this.dataOrder.id
      payload.product_price = this.dataOrder.total_price
      payload.list_items = this.dataSubOrder.map((item) => ({
        title: item.title_product,
        quantity: item.quantity_order,
        weight: item.product?.weight || 0,
      }))

      await HTTP.post("/order/dlo/", payload)
        .then((res) => {
          this.isLoadingCreateDLO = false;
          const data = res.data.data;
          this.isErrorCreateDLO = !data;

          this.title = "Tạo đơn vận chuyển thành công";
          this.logo = "noti-success";
          this.notiView = true;
        })
        .catch(() => {
          this.isErrorCreateDLO = true;
          this.isLoadingCreateDLO = false;
        });
    },

    async getDataDLO() {
      this.isLoadingPage = true;
      await HTTP.get(`/v1/api/orderdlo/?depth=1&order=${this.$route.params.id}`)
        .then((res) => {
          this.isLoadingPage = false;
          const data = res.data.results;
          console.log("getDataDLO", data);
          this.listDLO = data || [];
          if (this.listDLO?.length) {
            this.dataStatusOrder = this.dataStatusOrder.filter(
              (e) =>
                e?.title_status_order !== "Đã huỷ" &&
                e?.title_status_order !== "Chờ xác nhận"
            );
          }
        })
        .catch(() => {
          this.isLoadingPage = false;
        });
    },

    formatDate(date, _type) {
      const newDate = new Date(date)
      const dformat =
        (_type ? [newDate.getHours(), newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes()].join(':') + ' ' : '')
        +
        [
          newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate(),
          newDate.getMonth() + 1 < 10 ? '0' + (newDate.getMonth() + 1) : newDate.getMonth() + 1,
          newDate.getFullYear(),
        ].join('/')

      return dformat
    },
  },

  async created() {
    await this.getData();
    await this.getDataDLO();
    this.getDataPaymentMethods()
    const customer = this.dataOrder?.customer;
    this.dataReceiver = {
      full_name: customer.full_name,
      phone: customer.phone,
      province: "",
      district: "",
      ward: "",
      address: customer.address,
    };

    this.dataSender = {
      full_name: this.myAccount?.full_name,
      phone: this.myAccount?.phone,
      province: "",
      district: "",
      ward: "",
      address: this.myAccount?.address,
    };
    this.handleSelectPartner(this.listTransportPartner[0]);
    this.getListOrderSeviceVTP();
  },
};
</script>

<style scoped lang='scss'>
@import "DetailOrderAdmin.scss";

.add-image {
  width: 80px;
  aspect-ratio: 1;
  border-radius: 8px;
  border: 1px dashed #6F382B;
}
</style>