@<template>
    <div
        v-if="status" 
        class="popup popup-gift-require f jcc aic">
        <div class="gift-require__container border-radius bg-white-1">
            <div class="gift-require__header pt1 pl1 pr1 p3 brown">
                Thêm nhóm điều kiện mới
            </div>
            <div class="gift-require__body p1">

                <!-- Tên điều kiện -->
                <div class="f fdc mb1">
                    <label class="p4 mb025">Tên nhóm điều kiện</label>
                    <input 
                        v-model="requireGift.title_require"
                        type="text"
                        placeholder="Đặt tên nhóm điều kiện của bạn">
                </div>

                <!-- Điều kiện đối tượng -->
                <div class="mb1" style="display: grid">
                    <div class="p2 mb05">Điều kiện đối tượng <span class="p5 gray">(Chọn ít nhất 1)</span></div>
                    <div
                        @click="statusPopupCondition = true" 
                        class="f aic pt05 pb05 pl075 pr075 mb075 border-radius border cursor" style="width: fit-content">
                        <div class="icon--edit mr05"></div>
                        <div class="h7">Thêm đối tượng</div>
                    </div>
                    <div 
                        v-if="listUserGift.length === 0"
                        class="p4 gray">
                        Chưa chọn đối tượng nào
                    </div>
                    <div 
                        v-else
                        class="f aic p4">
                        <div 
                            v-for="(item, index) in listUserGift"
                            :key="index">
                            <div
                                class="f aic pt025 pb025 pl05 pr05 mr025 border-radius bg-gray-2"
                                v-if="item.checked">
                                <div class="p4"> {{item.title}} </div>
                                <div 
                                    @click="item.checked = false"
                                    class="icon--close ml025 cursor"></div>
                            </div>
                        </div>
                    </div> 
                </div>

                <!-- Điều kiện đáp ứng -->
                <div class="f fdc">
                    <div class="p5 mb1">
                        Điều kiện đáp ứng
                    </div>
                    <div class="f aic mb025">
                        <div class="f aic mr1">
                            <input 
                                type="radio" 
                                value="Hoặc"
                                v-model="condition"
                                checked="checked">
                            <label class="ml05 h6">Hoặc</label>
                        </div>
                        <div class="f aic">
                            <input 
                                type="radio"
                                value="Và"
                                v-model="condition">
                            <label class="ml05 h6">Và</label>
                        </div>
                    </div>
                    <div class="mb05">
                        <div
                            v-if="condition === 'Hoặc'"
                            class="p7 gray">
                            <span class="brown">Hoặc</span> - Đối tượng đáp ứng <span class="black">một</span> trong các điều kiện bên dưới để có thể nhận quà
                        </div>
                        <div
                            v-else-if="condition === 'Và'"
                            class="p7 bray">
                            <span class="brown">Và</span> - Đối tượng đáp ứng <span class="black">tất cả</span> các điều kiện bên dưới để có thể nhận quà
                        </div>
                    </div>
                    <div
                        @click="handleAddCondition"  
                        class="f aic pt05 pb05 pl075 pr075 mb05 border-radius border cursor" style="width: fit-content">
                        <div class="icon--edit mr05"></div>
                        <div
                            class="h7">Thêm điều kiện</div>
                    </div>
                    <div class="f fdc">
                        <div   
                            class="f aic" 
                            v-for="(item, index) in conditionData"
                            :key="index">
                            <div class="f aic w90 mb05">
                                <div
                                    @click="handleSelectCondition" 
                                    class="relative w50 f jcb aic border border-radius-s" style="padding: 12px">
                                    <div 
                                        v-if="item.condition === ''"
                                        class="h7 gray">Chọn một điều kiện</div>
                                    <div 
                                        v-else
                                        class="h7"> {{item.condition}} </div>
                                    <div class="icon--arrown ml05"></div>
                                    <div class="select__conditionList z10 absolute w100 border border-radius bg-white-1">
                                        <div
                                            v-for="(value, key) in conditionList"
                                            :key="key">
                                            <div
                                                @click="item.condition = value" 
                                                class="item__conditionList h7 pt05 pb05 pl075 pr075 gray cursor">
                                                {{value}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div 
                                    class="w50 ml1">
                                    <input 
                                        class="h6"
                                        type="number"
                                        placeholder="Nhập điểm tối thiểu"
                                        v-model="item.minPoint">    
                                </div>
                            </div>
                            <div
                                @click="handleDeleteCondition(index)" 
                                class="ml1 p4 red cursor">Gỡ bỏ</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="gift-require__btn f jce aic pt1 pb1 pl1 pr1 bg-gray-2">
                <div
                    @click="updateStatus" 
                    class="h5 pt075 pb075 pl15 pr15 border border-radius bg-white-1 cursor">Hủy bỏ</div>
                <div
                    @click="handleSubmitCondition" 
                    class="h5 pt075 pb075 pl15 pr15 ml1 border-radius bg-brown white cursor">Cập nhật</div>
            </div>
        </div>

        <PopupUserGift
            :listUserGift='listUserGift'
            @updateUserCondition='updateUserCondition'
            @updateStatusCondition='updateStatusCondition'
            :statusPopupCondition='statusPopupCondition'></PopupUserGift>
    </div>
</template>

<script>
import PopupUserGift from './PopupUserGift/PopupUserGift.vue'

export default {
    props : {
        status : Boolean
    },

    components : {
        PopupUserGift
    },

    data() {
        return {
            requireGift : {
                title_require : '',

            },

            condition : '',
            conditionList : [
                'Theo điểm Affiliate',
                'Theo điểm chia sẻ bài viết',
                'Theo đánh giá bài viết',
                'Theo tổng giá trị đơn hàng'
            ],
            conditionData : [
                {
                    condition : '',
                    minPoint : ''
                }
            ],

            listUserGift : [
                {
                    title : 'User Website',
                    checked : false
                },
                {
                    title : 'User Forum',
                    checked : false
                },
                {
                    title : 'User Affiliate',
                    checked : false
                },
                {
                    title : 'User khác',
                    checked : false
                }
            ],
            statusPopupCondition : false
        }
    },

    methods : { 

        // xóa phần tử trong list điều kiện
        handleDeleteCondition(index) {
            this.conditionData.splice(index, 1)
        },

        // thêm điều kiện
        handleAddCondition() {
            this.conditionData.push(
                {
                    condition : '',
                    minPoint : ''
                }
            )
        },

        // cập nhật trạng thái mở popup
        updateStatusCondition() {
            this.statusPopupCondition = false
        },

        // cập nhật điều kiện user
        updateUserCondition (value) {
            this.listUserGift = value
        },

        // handle drop down
        handleSelectCondition (event) {
            var button = event.target.parentElement
            var drop = button.getElementsByClassName('select__conditionList')[0];
            drop.classList.toggle('dropdown')
            console.log(button);
        },

        updateStatus () {
            this.$emit('updateStatus', false)
        },

        async handleSubmitCondition () {
            this.requireGift['condition'] = this.condition;
            this.requireGift['conditionData'] = this.conditionData
            this.requireGift['listUserGift'] = this.listUserGift

            this.$emit('updateCondition', this.requireGift)
            this.updateStatus()

        }
    }
}
</script>

<style scoped lang='scss'>
    @import 'RequireGift.scss';
</style>