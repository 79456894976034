@<template>
    <div class="list-post__container">
        <Layout2 
            :systemShow='systemShow'
            :data="data"
            :dataCategory="dataCategoryPost"
            :dataProps="dataItem"
            :dataField='dataField'
            :isMobile="isMobile"
        />
    </div>
</template>

<script>
import Layout2 from '@/components/Layout2/Layout2View.vue'
import { HTTP } from '@/http-default'
export default {
    components : {
        Layout2
    },

    props: {
        data : {
            type : Array,
            default() {
                return []
            }
        },
        dataCategory : {
            type: [Object, Array],
            default() {
                return {}
            }
        },
        dataProps : {
            type : Array,
            default() {
                return []
            }
        },
        dataField : {
            type : Object,
            default() {
                return {}
            }
        },
        systemShow : {
            type : [String, Number],
            default () {
                return ''
            }
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
    },

    created () {
        this.filterData(),
        this.getData()
    },

    watch : {
        checkDataProp : function () {
            this.dataItem = this.dataProps.filter(item => item.system_show[0]?.id == this.systemShow)
        },

        'systemShow': function () {
            this.dataItem = this.dataProps.filter(item => item.system_show[0]?.id == this.systemShow)
            console.log(this.dataItem);
        }
    },

    computed : {
        checkDataProp () {
            return this.dataProps.length
        }
    },

    data () {
        return {
            dataItem : [],
            dataCategoryPost : []
        }
    },

    methods : {
        filterData () {
            var data = [...this.dataProps]
            setTimeout(() => {
                this.dataItem = data.filter(item => item.system_show[0]?.id == this.systemShow)
            }, 1)
        },

        getData() {
            // setInterval(() => {
                HTTP.get('v1/api/categorypost/')
                .then((res) => {
                    this.dataCategoryPost = res.data.results
                })
            // }, 1000)
        }
    },

}
</script>

<style scoped lang='scss'>
    @import 'ListPost.scss';
</style>