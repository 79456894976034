<template>
  <div class="container container--forums">
    <div class="f list-article">
      <div class="list-article__wrapper">
        <h4 class="list-article__heading">Danh sách chuyên mục bài viết</h4>
        <div
            v-for="(item, index) in listArticle"
            :key="index"
            class="list-article__content-item"
        >
          <div class="f aic jcb list-article__article">
            <div class="f aic list-article__content">
              <div class="list-article__image">
                <router-link :to="{name: 'GroupArticle', params: {url: item.slug_category, id: item.id}}">
                  <img src="../../assets/forums/article.jpeg" alt="">
                </router-link>
              </div>
              <div class="ml1 list-article__info">
                <router-link :to="{name: 'GroupArticle', params: {url: item.slug_category, id: item.id}}">
                  <h5 class="blue">{{item.category_name}}</h5>
                </router-link>
                <p class="h6 gray-1-text">Bài viết: 1231231241</p>
              </div>
            </div>
            <div class="f list-article__description">
              <img class="list-article__thumbnail" src="../../assets/forums/person.png" alt="">
              <div class="f fdc ml1">
                <p class="p4 bold blue">Trải nghiệm KINGSTON FURY cảm giác th...</p>
                <div class="f jcb list-article__description-more">
                  <p class="gray-1-text">Thứ 5, 2022</p>
                  <p class="p4 blue">nguamatlentroihandoivodoi</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml2 list-article__sidebar">
        <div class="bg-white-1 list-article__block">
          <h4 class="list-article__block-title">Bài viết nổi bật</h4>
          <div 
            v-for="item in 5"
            :key="item"
            class="f list-article__block-item"
          >
            <div class="list-article__image">
              <img src="../../assets/forums/post.png" alt="">
            </div>
            <div class="f fdc jcb ml1 list-article__info">
              <div class="f jcb ist-article__info-title">
                <router-link :to="{ name: 'DetailArticle'}">
                  <h6 class="semibold">Kiến thức làm mẹ</h6>
                </router-link>
                <p class="h6">12.01.2019</p>
              </div>
              <div class="list-article__info-description">
                <router-link :to="{ name: 'DetailArticle', params: { userId: 123 }}">
                  <h5 class="h5">Tuyển dụng và giữ chân người tài trong doanh nghiệp</h5>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white-1 list-article__block">
          <h4 class="list-article__block-title">Bài viết mới</h4>
          <div 
            v-for="item in 5"
            :key="item"
            class="f list-article__block-item"
          >
            <div class="list-article__image">
              <img src="../../assets/forums/post.png" alt="">
            </div>
            <div class="ml1 list-article__info">
              <h6>Kiến thức làm mẹ</h6>
              <h5 class="h6">Tuyển dụng và giữ chân người tài trong doanh nghiệp</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '../../http-default'

export default {
  data () {
    return {
      listArticle: []
    }
  },
  methods: {
    getData () {
      HTTP.get('v1/api/categorypost/')
      .then(res => this.listArticle = res.data.results)
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
@import 'ListArticle.scss';
</style>
