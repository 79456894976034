@<template>
    <div class="popup popup-confirm-delete f jcc aic z100">
        <div class="confirm-noti__container pt1 pb1 pl1 pr1 border-radius bg-white-1">
            <div class="icon-noti f fdc jcc aic mb1">
                <div class="icon--noti-warning mb1"></div>
                <div class="h2 gray align-c">Thông báo</div>
            </div>
            <div class="p6 gray-1-text align-c mb05">Xác nhận loại bỏ người liên quan?</div>
            <div class="noti-create__action w100 f jcc aic">
                <div 
                    @click="handleClosePopup('cancel')"
                    class="pt075 pb075 pl1 pr1 h6 brown border-radius border cursor">
                    Huỷ bỏ
                </div>
                <div 
                    @click="handleSubmit"
                    class="pt075 pb075 pl1 pr1 ml1 h6 border-radius bg-brown white cursor">
                    Xác nhận
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        customerRelateDelete : {
            type : Object,
            default() {
                return {}
            }
        },
        dataCustomerRelate : {
            type : Array,
            default () {
                return []
            }
        }
    },

    methods : {
        handleSubmit () {
            var data = [...this.dataCustomerRelate]
            for (let i = 0; i < data.length; i++) {
                if (data[i].key_customer === this.customerRelateDelete.key_customer) {
                    data.splice(i , 1)
                }
            }
            console.log(data);
            this.$emit('updateCustomerRelate', data)

            this.handleClosePopup()
        },

        handleClosePopup () {
            this.$emit('closePopupConfirm')
        }
    }
}
</script>

<style scoped lang='scss'>
    @import 'PopupConfirm.scss';
</style>