@<template>
    <div class="popup popup-comment f jcc aic">
        <div class="list-comment__container relative border-radius bg-white-1">
            <div class="list-comment__header f jcb aic px1">
                <div class="p5 brown">Lịch sử bình luận</div>
                <div 
                    @click="handleClose"
                    class="icon--close cursor"></div>
            </div>
            <div class="list-comment__body px1">
                <div 
                    v-if="listComment.length !== 0"
                    class="comment__list">
                    <div
                        v-for="(item, index) in listComment"
                        :key="index">
                        <div 
                            class="mb1"
                            v-if="item.comment_parent === null">
                            <div class="comment__item-user f jcb aic mb05">
                                <div class="f aic">
                                    <div>
                                        <img 
                                            class="image_user border"
                                            :src="item.image_user" alt="">
                                    </div>
                                    <div class="ml05 f fdc jcb ais">
                                        <div class="p4"> {{item.user_customer.username}} </div>
                                        <div class="h6 gray-1-text"> {{convertTime(item.created_at)}} </div>
                                    </div>
                                </div>
                                <div class="f aic">
                                    <div class="icon--edit cursor"></div>
                                    <div 
                                        @click="deleteComment(item)"
                                        class="icon--delete ml05 cursor"></div>
                                </div>
                            </div>
                            <div 
                                v-if="item.user_customer.id === userId"
                                class="comment__item-content p6 px05 mb05 border-radius bg-black white">
                                {{item.content}}
                            </div>

                            <div 
                                v-else
                                class="comment__item-content p6 px05 mb05 border-radius bg-gray-2">
                                {{item.content}}
                            </div>
                            <div 
                                @click="commentResponseSelect = item"
                                class="comment__item-response f jce aic">
                                <div class="h7 blue cursor">
                                    Phản hồi bình luận
                                </div>
                            </div>

                            <div 
                                v-for="(value, key) in dataComment"
                                :key="key">
                                <div 
                                    class="pl5 mb2"
                                    v-if="value.comment_parent !== null && value.comment_parent.id === item.id">
                                    <div class="comment__item-user f aic mb05">
                                        <div>
                                            <img 
                                                class="image_user border"
                                                :src="value.image_user" alt="">
                                        </div>
                                        <div class="ml05 f fdc jcb ais">
                                            <div class="p4"> {{value.user_customer.username}} </div>
                                            <div class="h6 gray-1-text"> {{convertTime(value.created_at)}} </div>
                                        </div>
                                    </div>

                                    <div 
                                        v-if="value.user_customer.id === userId"
                                        class="comment__item-content p6 px05 mb05 border-radius bg-black white">
                                        {{value.content}}
                                    </div>

                                    <div 
                                        v-else
                                        class="comment__item-content p6 px05 mb05 border-radius bg-gray-2">
                                        {{value.content}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div 
                    v-else
                    class="w100 f fdc jcc aic">
                    <div class="icon--face-smile"></div>
                    <div class="h5 mt1">
                        Bài viết chưa có bình luận nào
                    </div>
                </div>
            </div>

            <div class="list-comment__input w100 px1 bg-white-1 border-top">
                <div
                    v-if="commentResponseSelect !== null"
                    class="mb05 h6">
                    Phản hồi bình luận của <span class="orange h5"> {{commentResponseSelect.user_customer.username}} </span>
                    <span 
                        @click="commentResponseSelect = null"
                        class="h5 blue cursor"> - huỷ </span>
                </div>
                <input 
                    @keypress="handlePostComment"
                    v-model="commentContent"
                    class="w100 border-radius"
                    placeholder="Nhập phản hồi của bạn tại đây"
                    type="text">
                <div class="h5 w100 align-r gray-1-text mt05">
                    Nhấn <span class="brown">Enter</span> để gửi phản hồi
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    props : {
        dataComment : {
            type : Array,
            default() {
                return []
            }
        }    
    },

    watch : {
        'dataComment' : function () {
            this.listComment = [...this.dataComment]
        }
    },

    mounted () {
        this.listComment = [...this.dataComment]
        this.userId = parseInt(localStorage.getItem('idUser'))
    },

    data () {
        return {
            listComment : [],
            commentContent : '',
            userId : null,
            commentResponseSelect : null,
        }
    },

    methods : {
        convertTime(value) {
            var time = value.split('T')
            return time[0]
        },

        handleClose () {
            this.$emit('closePopupComment')
        },

        deleteComment (item) {
            HTTP.delete('v1/api/commentpost/' + item.id + '/')
            .then((res) => {
                var index = this.listComment.indexOf(res.data)
                this.listComment.splice(index, 1)
            })
        },

        handlePostComment (event) {
            // var params = this.$route.params.id.split('-')
            var userId = localStorage.getItem('idUser')
            
            if (event.key === "Enter") {
                event.preventDefault();
                var data = {
                    "content": this.commentContent,
                    "status_comment": 5, // fix theo status db
                    "post": this.$route.params.id,
                    "user_customer": parseInt(userId),
                    "comment_parent": this.commentResponseSelect === null ? null : this.commentResponseSelect.id
                }

                // this.dataComment.push(data)
                const dataPost = JSON.stringify(data)

                HTTP.post('v1/api/commentpost/', dataPost)
                .then(() => {
                    this.commentContent = ''
                    HTTP.get('v1/api/commentpost/?depth=1&post=' + this.$route.params.id)
                    .then((res) => {
                        this.listComment = res.data.results
                        this.$emit('updateListCommnet', res.data.results)
                    })
                })
            }
        },
    }
}
</script>

<style scoped lang='scss'>
    @import 'ListComment.scss';
</style>