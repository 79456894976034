import { render, staticRenderFns } from "./CustomerRelate.vue?vue&type=template&id=24b5fdac&scoped=true"
import script from "./CustomerRelate.vue?vue&type=script&lang=js"
export * from "./CustomerRelate.vue?vue&type=script&lang=js"
import style0 from "./CustomerRelate.vue?vue&type=style&index=0&id=24b5fdac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24b5fdac",
  null
  
)

export default component.exports