import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { Chart, registerables } from 'chart.js';
import VComp, { createApp } from '@vue/composition-api';
import VueCookies from 'vue-cookies';

// import Antd from 'ant-design-vue';
// import "ant-design-vue/dist/antd.css";

import AOS from 'aos'
import 'aos/dist/aos.css'
 
Vue.use(VueCookies); 

Chart.register(...registerables);
Vue.use(VComp);

Vue.config.productionTip = false

const app = createApp(App);
app.config.productionTip = false;

// app.use(Antd)

new Vue({
  router,
  store,
  render: h => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')
