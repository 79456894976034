@<template>
    <div class="my-order__container">
        <div class="my-order__filter f aic mb1">
            <div class="p4 gray-1-text pr05 border-right">Bộ lọc</div>
            <div class="h6 ml05">Trạng thái:</div>
            <div class="w50 ml05">
                <v-select 
                class=""
                v-model="statusOrderSelect"
                :reduce="title_status_order => title_status_order.id"
                label='title_status_order'
                :options='statusOrder'></v-select>
            </div>
        </div>
        <div class="my-order__list">
            <div class="mb1">
                <div class="h3 gray-1-text">Tổng số lượng đơn hàng <span class="red">({{dataOrder.length}})</span></div>
            </div>
            <div class="f fdc ais" style="gap: 1em">
                <router-link 
                    class="w100"
                    :to="{name : 'body-detail' , params : { id : item.id } }"
                    v-for="(item, index) in dataOrder"
                    :key="index">
                    <div class="border border-radius">
                        <div class="f jcb aic border-bottom pl1 pr1 pt075 pb075">
                            <div class="h5 red">
                                {{item.order_key}}
                            </div>
                            <div
                                class="h7 border-radius pt025 pb025 pl05 pr05 orange bg-orange-1"
                                v-if="item.status_order.title_status_order === 'Đang xử lý'">
                                {{item.status_order.title_status_order}}
                            </div>
                            <div
                                v-if="item.status_order.title_status_order === 'Chờ xác nhận'"
                                class="pt025 pb025 pl05 pr05 border-radius orange bg-orange-1">
                                {{ item.status_order.title_status_order }}
                            </div>
                            <div
                                v-if="item.status_order.title_status_order === 'Thành công'"
                                class="pt025 pb025 pl05 pr05 border-radius green bg-green-1">
                                {{ item.status_order.title_status_order }}
                            </div>
                            <div
                                v-if="item.status_order.title_status_order === 'Đã huỷ'"
                                class="pt025 pb025 pl05 pr05 border-radius red bg-red-1">
                                {{ item.status_order.title_status_order }}
                            </div>
                        </div>
                        <div class="f fdc mb1 pt1 pr1 pl1">
                            <div class="f jcb aic mb05">
                                <h6 class="gray">Tên người mua</h6>
                                <div class="p4 black">{{item.customer['full_name']}}</div>
                            </div>
                            <div class="f jcb aic mb05">
                                <h6 class="gray">Số điện thoại</h6>
                                <div class="p4 black">{{item.customer['phone']}}</div>
                            </div>
                            <div class="f jcb aic mb05">
                                <h6 class="gray">Tổng sản phẩm</h6>
                                <div class="p4 black">{{item.total_quantity}}</div>
                            </div>
                            <div class="f jcb aic">
                                <h6 class="gray">Tổng đơn hàng</h6>
                                <div class="p4 brown">{{formatMoney(item.total_price)}}</div>
                            </div>
                        </div>
                        <div class="card-item__total border-radius-down f jcb aic pt1 pb1 pr1 pl1 bg-gray-2">
                            <div class="f fdc">
                                <div class="h7 mb05">Tổng giá trị quà</div>
                                <div class="p3 brown">{{formatMoney(item.total_price)}}</div>
                            </div>
                            <div class="h5 pt075 pb075 pl1 pr1 border border-radius bg-white-1">
                                Xem chi tiết
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
import vSelect from 'vue-select'

export default {
    components : {
        'v-select' : vSelect
    },

    data () {
        return {
            statusOrder : [],
            dataOrder : [],
            statusOrderSelect : 'Tất cả'
        }
    },

    methods : {
        // formatMoney
        formatMoney(value) {
            var x = value;
            x = x.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
            return x
        },
        async getData () {
            await HTTP.get('v1/api/statusorder/')
            .then((res) => {
                this.statusOrder = res.data.results
            })

            await HTTP.get('v1/api/order/?depth=1')
            .then((res) => {
                this.dataOrder = res.data.results
            })
        }
    },

    created () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'MyOrder.scss';
</style>