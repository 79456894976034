@<template>
    <div class="">
        <HeaderType2 />
        <div class="banner">
            <img 
                class="w100"
                src="../../assets/bg-myaccount.png" alt="">
        </div>

        <div class="my-account__container">

            <!-- left -->
            <div class="my-account__left f fdc ais">
                
                <!-- Danh mục -->
                <div class="my-account__category w100 border border-radius pl1 pr1">
                    <div class="category__header pt1">
                        <div class="p5">
                            Danh mục
                        </div>
                    </div>
                    <div class="category__body">
                        <div 
                            v-for="(item, index) in dataCategory"
                            :key="index"
                            class="category__item pt1 pb1 border-top">
                            <router-link
                                class="f aic"
                                :to="{ name : 'body-view' ,params : { path : item.path} }"
                                v-if="$route.params.path === item.path">
                                <div v-bind:class="item.logo + '-1'"></div>
                                <div class="ml05 p5 brown">{{item.title}}</div>
                            </router-link>
                            <router-link
                                class="f aic"
                                :to="{name : 'body-view', params : { path : item.path} }"
                                v-if="$route.params.path !== item.path">
                                <div v-bind:class="item.logo + '-2'"></div>
                                <div class="ml05 p6 gray-1-text">{{item.title}}</div>
                            </router-link>
                        </div>
                    </div>
                </div>

                <!-- Cấp độ khách hàng -->
                <div class="my-account__level border border-radius w100 px1">
                    <div class="level-customer__header f jcb aic mb1">
                        <div class="h5">Cấp độ khách hàng</div>
                    </div>
                    <div class="level-customer__body">
                        <div class="f aic jcb p4 mb075">
                            <div>Tiềm năng</div>
                            <div class="w20">Thường</div>
                            <div>VIP</div>
                        </div>
                        <div 
                            class="f aic jcb mb075" style="height: 12px">
                            <div class="w100 border-radius bg-gray-5" style="height: 6px; background-color: #C7C9D9;">
                                <div 
                                    v-if="myAccount.level_customer__title_level_customer === 2"
                                    class="level-bar border-radius relative">
                                    <div class="circle"></div>
                                </div>
                                <div 
                                    v-if="myAccount.level_customer__title_level_customer ===  'tiềm năng' "
                                    class="level-bar border-radius relative" style="width : 10%">
                                    <div class="circle"></div>
                                </div>
                            </div>
                        </div>
                        <div 
                            v-if="myCustomer"
                            class="h5" style="color:#FF8A00;">
                            Còn {{myCustomer.point}} điểm
                        </div>
                    </div>
                </div>

                <!-- Điểm chia sẻ -->
                <div class="my-account__point">

                </div>
            </div>

            <div
                v-if="!$route.params.id">
                <!-- THông tin tài khoản -->
                <InfoAccount 
                    v-if="$route.params.path === 'myAccount'"
                    :myAccount='myAccount'/>

                <MyOrder 
                    v-if="$route.params.path === 'myOrder'"/>
                    
                <MyAffiliate 
                    v-if="$route.params.path === 'myAffiliate'" />
            </div>
            <div v-if="$route.params.id">
                <MyOrderDetail 
                    v-if="$route.params.path === 'myOrder'"/>
            </div>
        </div>

        <FooterSection />
    </div>
</template>

<script>
import HeaderType2 from '@/components/HeaderType2/HeaderType2.vue'
import FooterSection from '@/components/FooterSection/FooterSection.vue'

import InfoAccount from './InfoAccount/InfoAccount.vue'
import MyOrder from './MyOrder/MyOrder.vue'
import MyAffiliate from './MyAffiliate/MyAffiliate.vue'

import MyOrderDetail from './MyOrderDetail/MyOrderdetail.vue'
import { HTTP } from '@/http-default'
export default {
    components : {
        HeaderType2,
        FooterSection,
        InfoAccount,
        MyOrder,
        MyOrderDetail,
        MyAffiliate
    },

    data () {
        return {
            dataCategory : [
                {
                    title : 'Tài khoản của tôi',
                    path : 'myAccount',
                    logo : 'icon--account'
                },
                {
                    title : 'Đơn hàng',
                    path : 'myOrder',
                    logo : 'icon--order'
                },
                {
                    title : 'Báo cáo Affiliate',
                    path : 'myAffiliate',
                    logo : 'icon--affiliate'
                }
            ],
            myAccount : {},
            myCustomer : {}
        }
    }, 

    methods : {
        getDataAccount () {
            var account = localStorage.getItem('myAccount')
            this.myAccount = JSON.parse(account)

            HTTP.get('v1/api/customer/')
            .then((res) => {
                var data = res.data.results
                this.myCustomer = data.find(item => item.id === this.myAccount.id)
            })
        },
    },

    created () {
        this.getDataAccount()
    }
}
</script>

<style scoped lang='scss'>
    @import 'MyAccount.scss';
</style>