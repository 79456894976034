<template>
  <div class="course-web__container bg-gray-2">
    <HeaderType2
      v-if="!isMobile"
      class="z10"
      @updateProductCart="updateProductCart"
      :dataProductCart="dataProductCart"
    ></HeaderType2>
    <HeaderLanding v-else></HeaderLanding>

    <img
      class="img__bg-1"
      loading="lazy"
      src="@/components/Landingpage/imgLanding/intro_img_1.png"
      alt=""
    />
    <img
      class="img__bg-2"
      loading="lazy"
      src="@/components/Landingpage/imgLanding/intro_img_2.jpg"
      alt=""
    />
    <img
      class="img__bg-3"
      loading="lazy"
      src="@/components/Landingpage/imgLanding/intro_img_3.jpg"
      alt=""
    />

    <div class="container relative z10">
      <div class="f aic jcb">
        <div class="container-nav relative f jcb mb2 w100">
          <div class="search-icon absolute">
            <div class="icon icon--search"></div>
          </div>
          <input
            v-model="searchCourse"
            class="pl2 pt075 pb075 pr075"
            type="text"
            :style="`width: ${isMobile ? '100%' : '417px'} `"
            :placeholder="'Tìm kiếm'"
          />
        </div>
      </div>

      <div class="list-course">
        <Loading :is-loading="isLoading" />

        <div
          v-for="(item, index) in dataCourses.filter((e) =>
            e.title.includes(searchCourse)
          )"
          :key="index"
          class="course-item bg-white-1 px1 f gap1 cursor"
          @click="
            () => {
              if (
                dataUserCourses.find((elm) => elm.course.id === item.id) &&
                dataUserCourses.find((elm) => elm.course.id === item.id)
                  .is_confirm
              ) {
                $router.push(`/course-website/${item.id}`);
              }
            }
          "
        >
          <div>
            <img v-if="item.media" :src="item.media" alt="" />
            <img v-else src="@/assets/imgUser.png" alt="" />
          </div>
          <div class="f fdc w100">
            <p class="h4 brown">{{ item.title }}</p>
            <p class="gray-1-text p6 course-item-description">
              {{ item.description }}
            </p>
            <p class="gray-1-text p6">
              Số lượng học viên:
              <span class="black h7">{{ item.user_course_count }}</span>
            </p>
            <p class="gray-1-text p6">
              Thời lượng: <span class="black h7">{{ item.duration }}</span>
            </p>
            <p class="gray-1-text p6">
              Giá:
              <span class="black h7">{{ formatMoney(item.price || 0) }}</span>
            </p>
            <p class="gray-1-text p6">
              Thời gian tạo:
              <span class="black h7">{{ formatDate(item.created_at) }}</span>
            </p>
            <p v-if="item.user_created" class="gray-1-text p6">
              Người tạo:
              <span class="black h7">{{ item.user_created.username }}</span>
            </p>
            <p class="gray-1-text p6">
              Thời gian tạo:
              <span class="black h7">{{ formatDate(item.created_at) }}</span>
            </p>
            <div
              v-if="!dataUserCourses.find((elm) => elm.course.id === item.id)"
              class="h7 green align-r"
              @click.stop="registerCourse(item.id)"
            >
              Đăng kí
            </div>
            <template v-else>
              <div class="f aic gap05 jce">
                <template
                  v-if="
                    dataUserCourses.find((elm) => elm.course.id === item.id) &&
                    dataUserCourses.find((elm) => elm.course.id === item.id)
                      .is_confirm
                  "
                >
                  <p class="h7 green align-r">Đã đăng kí</p>
                  <img
                    src="@/assets/complate.png"
                    alt=""
                    style="width: 16px; height: 16px; object-fit: cover"
                  />
                </template>
                <template v-else>
                  <p class="h7 red">Chờ xác nhận</p>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <NotiView v-if="notiView" @closePopup="notiView = false" :title="title" />
  </div>
</template>

<script>
import { HTTP } from "../../http-default";

import axios from "axios";

import HeaderType2 from "@/components/HeaderType2/HeaderType2.vue";
import Loading from "@/components/Loading/Loading.vue";
import NotiView from "@/components/NotiView/NotiView.vue";
import HeaderLanding from '@/components/Landingpage/HeaderLanding/HeaderLanding.vue'

export default {
  name: "CourseWebsitePage",

  components: {
    Loading,
    HeaderType2,
    NotiView,
    HeaderLanding,
  },

  data() {
    return {
      dataCourses: [],
      dataUserCourses: [],
      isLoading: true,

      notiView: false,
      logo: "noti-success",
      title: "",

      searchCourse: "",
      dataProductCart: [],
    };
  },

  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  created() {
    this.getData();
    this.getDataUserCourse();
  },

  methods: {
    async getData() {
      await axios
        .get("https://api.drmom.store/course/course-no-auth/")
        .then((res) => {
          this.dataCourses = res?.data?.data || [];
          this.isLoading = false;
        });
    },

    async getDataUserCourse() {
      await HTTP.get("course/user-course/").then((res) => {
        this.dataUserCourses = res?.data?.data || [];
      });
    },

    formatDate(date, _type) {
      const newDate = new Date(date);
      const dformat =
        (_type
          ? [
              newDate.getHours(),
              newDate.getMinutes() < 10
                ? "0" + newDate.getMinutes()
                : newDate.getMinutes(),
            ].join(":") + " "
          : "") +
        [
          newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate(),
          newDate.getMonth() + 1 < 10
            ? "0" + (newDate.getMonth() + 1)
            : newDate.getMonth() + 1,
          newDate.getFullYear(),
        ].join("/");

      return dformat;
    },

    updateProductCart() {
      let data = localStorage.getItem("cartProudct");
      let cart = JSON.parse(data);
      this.dataProductCart = cart;
    },

    async registerCourse(courseId) {
      const payload = {
        course: courseId,
        user_id: this.userId,
      };

      await HTTP.post(`course/user-course/`, payload)
        .then((res) => {
          this.title = res.data.data;
          this.notiView = true;
          this.getDataUserCourse();
        })
        .catch((err) => {
          this.logo = "noti-error";
          this.title = err.message;
          this.isLoading = false;
        });
    },

    formatMoney(value) {
      var x = value;
      x = x.toLocaleString("it-IT", { style: "currency", currency: "VND" });
      return x;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-course {
  display: grid;
  gap: 2em;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
}

.search-icon {
  top: 0.85em;
  left: 0.3em;
}

.qcont:first-letter {
  text-transform: capitalize;
}

.course-item {
  border-radius: 8px;
  overflow: auto;

  & img {
    width: 200px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
  }

  &-description {
    height: 73px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
  }
}

.course-web__container {
  background-color: #f1b49343;
  position: relative;
  min-height: 100vh;
  height: auto;
}

.img__bg-1 {
  mix-blend-mode: multiply;
  opacity: 0.2;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}

.img__bg-2 {
  mix-blend-mode: multiply;
  opacity: 0.2;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 1;
}

.img__bg-3 {
  mix-blend-mode: multiply;
  opacity: 0.2;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
}

@media screen and (max-width: 678px) {
  .course-item {
    flex-direction: column;
    width: 100%;

    & img {
      width: 100%;
      height: 120px;
      object-fit: cover;
      border-radius: 8px;
    }
  }

  .list-course {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
}
</style>