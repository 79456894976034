@<template>
    <div class="comment-history__container ">
        <div class="file__header f jcb aic mb15">
            <div class="p2 brown">Lịch sử bình luận</div>
        </div>
        <!-- <div class="file__search relative mb15">
            <div class="absolute" style="top: 35%; left: 2%">
                <svg width="15" height="15" viewBox="0 0 15 15" class="mr05">
                    <use xlink="http://www.w3.org/2000/svg" href="#search" x="0" y="0"></use>
                </svg>
            </div>
            <input 
                id="myInput"
                type="text" 
                placeholder="Nhập mã hồ sơ bệnh lý" 
                v-model="textSearch"
                class="w80 pt1 pb1 pr1 pl2">
        </div> -->
        <div 
            v-if="dataComment.length !== 0"
            class="file__list">
            <div 
                class="comment__item cursor"
                v-for="(item, index) in dataComment" :key="index">
                <div class="file-item pt1 pb1 pr1 pl1 mb1 border-radius bg-white-1">
                    <div class="file-item__header f jcb aic">
                        <div class="f aic">
                            <img :src="data.avatar" alt="" class="img-logo mr1">
                            <div class="f fdc jcb ">
                                <div class="p4 blue">{{ data.full_name }}</div>
                                <div class="h6 gray">#{{ data.key_customer }}</div>
                            </div>
                        </div>
                        <!-- <div class="f aic">
                            <div @click="handleAddMedical('view', item)">
                                <div class="icon--eye cursor"></div>
                            </div>
                            <div @click="handleAddMedical('edit', item)" class="ml1">
                                <div class="icon--edit cursor"></div>
                            </div>
                        </div> -->
                    </div>
                    <div class="file-item__time f jcb aic mt1">
                        <div class="h6 gray">Thời gian khởi tạo</div>
                        <div class="p4 orange">{{convertTime(item.created_at)}}</div>
                    </div>
                    <div class="file-item__text p4 mt1">
                        {{ item.content}}
                    </div>
                    <!-- <div class="file-item__img mt1">
                        <div class="h6 mb05 gray ">Ảnh đính kèm</div>
                        <div>
                            <img 
                                class="img-logo"
                                :src="item.image_care" alt="">
                        </div>
                    </div> -->
                </div>
            </div>
        </div>

        <div
            v-else
            class="f fdc aic jcc px1 border-radius w100 shadow bg-white-1">
            <div class="icon--face-smile"></div>
            <div class="p5 mt1">Khách hàng chưa có bình luận nào</div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    props : {
        data : {
            type : Object,
            default() {
                return {}
            }
        }
    },

    data () {
        return {
            dataComment : []
        }
    },

    methods : {
        convertTime(value) {
            var time = value.split('T')
            return time[0] + '-' + time[1]
        },

        getData () {
            HTTP.get('v1/api/commentpost/')
            .then((res) => {
                var data = res.data.results
                this.dataComment = data.filter(item => item.user_customer === this.data.user.id)
            })
        }
    },

    mounted () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'CommentHistory.scss';
</style>