<template>
  <div class="input-number__container relative">
    <input
      :value="valueInput"
      type="text"
      class="w100"
      :class="inputClasses"
      :placeholder="placeholder"
      @input="onEmit($event)"
      @blur="onBlur($event)"
      @change="onChange($event)"
    />
    <div class="absolute center" style="right: 1em;">
      <slot name="prefix" />
    </div>
  </div>
</template>

<script>
export default {
  name: "InputNumber",

  props: {
    value: {
      type: [Number, String],
      default: "",
    },

    placeholder: {
      type: String,
      default: "Nhập...",
    },

    inputClasses: {
      type: String,
      default: "",
    },
  },

  data () {
    return {
      valueOfComponent: null,
    }
  },

  computed: {
    valueInput() {
      return this.formatNumberShow(this.valueOfComponent || this.value);
    },

    dataEmit() {
      return Number(`${this.valueOfComponent || 0}`.replace(/\D/g, ""));
    },
  },

  watch: {
    value: {
      handler(data) {
        this.valueOfComponent = data
      },
      deep: true
    },
  },

  created() {
    this.valueOfComponent = this.value
  },

  methods: {
    formatNumberShow(value) {
      if (/^[0-9]+$/.test(value)) {
        const val = value > 999 ? (value / 1000).toFixed(3) : value;
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      } else {
        const val = Number(`${this.valueOfComponent || 0}`.replace(/\D/g, ""))
        this.formatNumberShow(val)
      }
    },

    onEmit(event) {
      this.valueOfComponent = event.target.value
      this.$emit("input", this.dataEmit);
    },

    onBlur(event) {
      this.valueOfComponent = event.target.value
      this.$emit("blur", this.dataEmit);
    },

    onChange(event) {
      this.valueOfComponent = event.target.value
      this.$emit("change", this.dataEmit);
    },
  },
};
</script>

<style scoped lang="scss">
.center {
  top: 50%;
  transform: translateY(-50%);
}
</style>