@<template>
    <div class="info-account__container f fdc ais">
        <div class="info-account__form w100 border border-radius px1">
            <div class="form__header mb1">
                <div class="p2 brown">Thông tin tài khoản</div>
            </div>
            <div class="form__body">
                <div class="user-name__input f fdc ais mb1">
                    <label class="h7" for="">Tên đăng nhập</label>
                    <div class="w100 mt025 px075 border border-radius bg-gray-2">
                        {{myAccount.user__username}}
                    </div>
                </div>
                <div class="user-name__input f fdc ais">
                    <div class=" w100 f jcb aic">
                        <label class="h7" for="">Mật khẩu</label>
                        <div 
                            @click="changePassword = true"
                            class="h7 blue cursor">Đổi mật khẩu</div>
                    </div>
                    <div class="w100 mt025 px075 border border-radius bg-gray-2">
                        *****************
                    </div>
                </div>
            </div>
        </div>

        <div class="info-personal__form w100 border border-radius px1">
            <div class="form__header mb1">
                <div class="p2 brown">Thông tin cá nhân</div>
            </div>
            <div class="form__img-user f fdc jcc aic">
                <div class="relative">
                    <img 
                        :src="dataUser.avatar" alt="">
                    <div class="change-img__btn absolute cursor">
                        <input 
                            class="hide"
                            type="file" 
                            accept="image/*" 
                            ref="file"
                            @change="uploadImage($event)" 
                            id="file-input">
                        <div 
                            @click="uploadImg"
                            class="bg-brown px05">
                            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24"><path fill="#FFFFFF" d="M12.29,5.21l1.5,1.5a1,1,0,0,0,1.42,0,1,1,0,0,0,.13-1.21H19a1,1,0,0,0,0-2H15.34a1,1,0,0,0-.13-1.21,1,1,0,0,0-1.42,0l-1.5,1.5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76A1,1,0,0,0,12.29,5.21ZM22.92,9.12a1,1,0,0,0-.21-.33l-1.5-1.5a1,1,0,0,0-1.42,0,1,1,0,0,0-.13,1.21H16a1,1,0,0,0,0,2h3.66a1,1,0,0,0,.13,1.21,1,1,0,0,0,1.42,0l1.5-1.5a1,1,0,0,0,.21-.33A1,1,0,0,0,22.92,9.12ZM11,10a4,4,0,1,0,4,4A4,4,0,0,0,11,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,11,16Zm9-3a1,1,0,0,0-1,1v5a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H6a1,1,0,0,0,1-.69l.54-1.62A1,1,0,0,1,8.44,7H10a1,1,0,0,0,0-2H8.44A3,3,0,0,0,5.59,7.06L5.28,8H4a3,3,0,0,0-3,3v8a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V14A1,1,0,0,0,20,13Z"/></svg>
                        </div>
                    </div>
                </div>
                <div class="h6 mt05">
                    Kích thước tối đa nhỏ hơn 2MB
                </div>
            </div>

            <div class="form__body f fdc ais mb1">
                <div class="w100 f fdc ais">
                    <label class="h7" for="">Họ và tên </label>
                    <input 
                        class="w100 mt025 border-radius"
                        placeholder="Nhập họ và tên"
                        v-model="dataUser.full_name"
                        type="text" name="" id="">
                </div>
                <div class="w100 f aic jcb">
                    <div class="f fdc ais w50">
                        <label class="h7" for="">Email</label>
                        <input 
                            class="w100 mt025 border-radius"
                            placeholder="Nhập email"
                            v-model="dataUser.email"
                            type="text" name="" id="">
                    </div>
                    <div class="f fdc ais w50 ml1">
                        <label class="h7" for="">Số điện thoại </label>
                        <input 
                            class="w100 mt025 border-radius"
                            placeholder="Nhập số điện thoại"
                            v-model="dataUser.phone"
                            type="text" name="" id="">
                    </div>
                </div>
                <div class="w100 f ais jcb">
                    <div class="f fdc ais w50">
                        <label class="h7" for="">Giới tính</label>
                        <v-select 
                            class="mt025"
                            v-model="dataUser.gender"
                            :options='dataGender'></v-select>
                    </div>
                    <div class="f fdc ais w50 ml1">
                        <label class="h7" for="">Ngày sinh</label>
                        <input 
                            class="w100 mt025 px075 border-radius"
                            placeholder="Nhập số điện thoại"
                            v-model="dataUser.birthday"
                            type="date" name="" id="">
                    </div>
                </div>
                <div class="w100 f fdc ais">
                    <label class="h7" for="">Địa chỉ</label>
                    <input 
                        class="w100 mt025 border-radius"
                        placeholder="Nhập địa chỉ"
                        v-model="dataUser.address"
                        type="text" name="" id="">
                </div>
            </div>

            <div class="form__action w100 f jce aic cursor">
                <div 
                    @click="handleUpdate"
                    class="h5 px075 border-radius align-c bg-brown white">
                    Cập nhật
                </div>
            </div>
        </div>

        <ChangePassword 
            v-if="changePassword"
            @closePopup='closePopup'/>
        <NotiView 
            v-if="showNotiview"
            :title="titleNotiView"
            :logo="logo"
            @closePopup='closeNotiView'/>
    </div>
</template>

<script>
import ChangePassword from './ChangePassword/ChangePassword.vue'
import NotiView from '@/components/NotiView/NotiView.vue'
import { HTTP } from '@/http-default'
import vSelect from 'vue-select'
export default {
    props : {
        myAccount : {
            type : Object,
            default () {
                return {}
            }
        },
    },

    components : {
        ChangePassword,
        NotiView,
        'v-select' : vSelect
    },

    data () {
        return {
            dataUser : {},
            dataGender : [
                'Nam' , 'Nữ'
            ],
            avatar : null,

            changePassword : false,

            showNotiview : false,
            logo: '',
            titleNotiView: '',
        }
    },

    methods : {
        closePopup(value) {
            if (value === 'changePassword') {
                this.changePassword = false
            }
        },

        closeNotiView() {
            this.showNotiview = false
        },

        uploadImg () {
            var input = document.getElementById('file-input')
            input.click()
        },

        uploadImage(event) {
            var url = URL.createObjectURL(event.target.files[0]);
            this.dataUser.avatar = url
            this.avatar = event.target.files[0]
        },

        handleUpdate () {
            var data = new FormData()
            data.append('full_name', this.dataUser.full_name)
            
            if (this.avatar !== null) {
                data.append('avatar', this.avatar)
            }

            data.append('email', this.dataUser.email)
            data.append('phone', this.dataUser.phone)
            data.append('gender', this.dataUser.gender)
            data.append('birthday', this.dataUser.birthday)
            data.append('address', this.dataUser.address)

            HTTP.patch('v1/api/customer/' + this.dataUser.id + '/', data)
            .then( () => {
                this.titleNotiView = 'Sửa thông tin thành công'
                this.logo = 'noti-success'
                this.showNotiview = true
            })
            .catch( (err) => {
                console.error(err)
                this.titleNotiView = 'Sửa thông tin thất bại'
                this.logo = 'noti-warning'
                this.showNotiview = true
            })
        },

        getData () {
            if (this.myAccount.is_customer) {
                HTTP.get('v1/api/customer/?depth=1')
                .then((res) => {
                    var data = res.data.results
                    var userId = parseInt(localStorage.getItem('idUser'))
                    var filter = data.filter(item => item.user.id === userId)
                    this.dataUser = filter[0]
                })
            }
        }
    },

    mounted () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'InfoAccount.scss';
</style>