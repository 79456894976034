@<template>
    <div class="popup popup-add-role f jcc aic z10">
        <div class="add-role__container pt1 pb1 pl1 pr1 border-radius bg-white-1">
            <div class="add-role__header mb1">
                <div class="p2 brown">Thêm quan hệ mới</div>
            </div>
            <div class="add-role__body">
                <div class="role-default mb15">
                    <div class="role-default__title h5 mb05">
                        Mặc định
                    </div>
                    <div class="role-default__body">
                        <div 
                            v-for="(item, index) in dataRole"
                            :key="index"> 
                            <div 
                                v-if="item.id !== 0"
                                class="role-default__item mb025 h6">
                                {{index + 1}}. {{item.title_role}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="role-custome">
                    <div class="role-custome__header f jcb aic mb1">
                        <div class="relative f aic">
                            <div class="role-custome__title h5">
                                Tùy chỉnh
                            </div>
                            <div class="icon--info icon-info__hover ml025 cursor"></div>
                            <div class="info__container absolute pt05 pb05 pl075 pr075 border-radius p6 bg-brown white">
                                Quan hệ khách hàng khi thêm mới sẽ không thể xóa được
                            </div>
                        </div>
                        <div 
                            @click="listRoleAdd.push({
                                d : Math.floor(Math.random() * 1000000000000),
                                title_role: '',
                                error : false
                            })"
                            class="role-custome__add cursor f aic">
                            <div></div>
                            <div class="h7 blue">
                                Thêm quan hệ mới
                            </div>
                        </div>
                    </div>
                    <div class="role-custome__body mb1">
                        <div
                            v-for="(item, index) in listRoleAdd"
                            :key="index">
                            <div class="role-custome__add-item mb1">
                                <div class="h7">Mối quan hệ {{index + 1}}</div>
                                <div class="w100 mt025">
                                    <input 
                                        v-if="!item.error"
                                        @keyup="validateInput(item)"
                                        class="w100"
                                        v-model="item.title_role"
                                        placeholder="Mỗi vai trò cách nhau bởi dấu “/”. Tối đa 2 vai trò"
                                        type="text">
                                    <input 
                                        v-if="item.error"
                                        @keyup="validateInput(item)"
                                        v-model="item.title_role"
                                        class="w100 border-error"
                                        placeholder="Mỗi vai trò cách nhau bởi dấu “/”. Tối đa 2 vai trò"
                                        type="text">
                                    <div 
                                        v-if="item.error"
                                        class="h7 red mt025">Mỗi vai trò cách nhau bởi dấu “/”. Tối đa 2 vai trò</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="add-role__action w100 f jcc aic">
                <div 
                    @click="handleClosePopup"
                    class="pt075 pb075 pl1 pr1 h6 brown border-radius border cursor">
                    Huỷ bỏ
                </div>
                <div 
                    @click="handleAddRole"
                    class="pt075 pb075 pl1 pr1 ml1 h6 border-radius bg-brown white cursor">
                    Lưu lại
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props : {
        dataRole : {
            type : Array,
            default () {
                return []
            }
        }
    },

    data () {
        return {
            listRoleAdd : [
                {
                    id : Math.floor(Math.random() * 1000000000000),
                    title_role: '',
                    error : false
                }
            ]
        }
    },

    methods : {
        handleClosePopup () {
            this.$emit('closePopup', 'add-role__container')
        },

        validateInput (item) {
            var title = item.title_role.split('')
            var input = title.filter(value => value === '/')
            if (input.length >= 2) {
                item.error = true
            } else if (input.length < 2 ) {
                item.error = false
            }
        },
        
        handleAddRole () {
            this.$emit('updateListRole', this.listRoleAdd)
            this.handleClosePopup()
        }
    }
}
</script>

<style scoped lang='scss'>
    @import 'AddRole.scss';
</style>