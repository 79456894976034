@<template>
    <div>
        <div class="container-header f aic mb2">
          <div class="qcont h2 pr1 border-right">
            {{data.props.titlecontent}}
          </div>
          <div 
            v-if="$route.params.path === 'quan-ly-post'"
            class="h5 pr05 pl1 brown">
            {{data.props.titlecontent}} {{systemShow == 1 ? 'Forum' : 'Website' }}
          </div>
          <div 
            v-else
            class="h5 pr05 pl1 brown">
            {{data.props.titlecontent}}
          </div>
        </div>
        <div class="container-nav relative f jcb mb2">
            <div class="search-icon absolute">
                <div class="icon icon--search"></div>
            </div>
            <input 
                class="pl2 pt075 pb075 pr075" 
                type="text" 
                style="width: 417px" 
                :placeholder="'Nhập ' + data.props.titlecontent +  ' bạn muốn tìm'">
        </div>
    </div>
</template>

<script>
export default {
  props: {
    data: {
      type : Object,
      default() {
        return {}
      }
    },

    systemShow : {
      type : [String, Number],
      default() {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
    @import 'HeaderLayout2.scss';
</style>