<template>
  <div class="container f fdc gap1">
    <template v-if="dataCourse && dataCourseUnit">
      <div class="bg-white-1 px1 border-radius">
        <p class="p3">
          Khoá học: <span class="h3 brown">{{ dataCourse.title }}</span>
        </p>
        <p class="p4">
          Bài giảng: <span class="h4 brown">{{ dataCourseUnit.title }}</span>
        </p>
      </div>

      <div>
        <p class="p5">Video bài giảng:</p>
        <video v-if="dataCourseUnit.video" :src="dataCourseUnit.video.video_file" autoplay width="100%" height="500px" controls></video>
        <video v-else :src="null" autoplay width="100%" height="500px"></video>
      </div>

      <div class="course-unit-content bg-white-1 px1 border-radius">
        <p class="p5 brown">Thông tin bài giảng</p>
        <p class="p4 gray-1-text">{{ dataCourseUnit.description }}</p>
        <div v-html="dataCourseUnit.content"></div>
        <p class="p6 gray-1-text">
          Thời gian tạo:
          <span class="h7 black">{{ formatDate(dataCourseUnit.created_at) }}</span>
        </p>
        <p v-if="dataCourseUnit.user_created" class="p6 gray-1-text">
          Người tạo:
          <span class="h7 black">{{ dataCourseUnit.user_created.username }}</span>
        </p>
        <div class="f aic gap1">
          <p class="p6 gray-1-text">
            Lượt xem:
            <span class="h7 black">{{ dataCourseUnit.total_click }}</span>
          </p>
          <p class="p6 gray-1-text">
            Lượt chia sẻ:
            <span class="h7 black">{{ dataCourseUnit.total_share }}</span>
          </p>
        </div>
      </div>
    </template>

    <Loading :is-loading="isLoading" type="page" />
  </div>
</template>

<script>
import { HTTP } from "../../http-default";

import Loading from "@/components/Loading/Loading.vue";

export default {
  name: "CourseUnitDetailsPage",
  components: {
    Loading,
  },

  data() {
    return {
      dataCourseUnit: null,
      dataCourse: null,
      isLoading: true,
    };
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      await HTTP.get(`course/course-unit/${this.$route.params.id}`)
        .then((res) => {
          this.dataCourseUnit = res?.data?.data;
          this.dataCourse = this.dataCourseUnit?.course;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
      this.isLoading = false;
    },

    formatDate(date, _type) {
      const newDate = new Date(date);
      const dformat =
        (_type
          ? [
              newDate.getHours(),
              newDate.getMinutes() < 10
                ? "0" + newDate.getMinutes()
                : newDate.getMinutes(),
            ].join(":") + " "
          : "") +
        [
          newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate(),
          newDate.getMonth() + 1 < 10
            ? "0" + (newDate.getMonth() + 1)
            : newDate.getMonth() + 1,
          newDate.getFullYear(),
        ].join("/");

      return dformat;
    },
  },
};
</script>

<style>
</style>