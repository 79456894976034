<template>
  <div class="post-view-component f fdc gap05">
    <div class="f aic gap1 ph05" style="position: sticky; top: 0; background: white; z-index: 1000;">
      <img
        v-if="data.image_user"
        class="user-avatar"
        :src="data.image_user"
        loading="lazy"
        alt="image user"
      />
      <img
        v-else
        class="user-avatar"
        :src="'@/assets/user-default-avatar.png'"
        loading="lazy"
        alt="image user"
      />
      <div class="">
        <p class="p4">
          {{ data.user_created.username }}
        </p>
        <p class="h6 gray-1-text">Đã đăng: {{ convertTime(data.time_published) }}</p>
      </div>
    </div>
    <div ref="content" class="post-content p6 ph05">
      {{ data.content }}
    </div>
    <p v-if="showMore" class="p4 blue" style="cursor: pointer">Xem thêm</p>
    <div v-if="data.media">
      <img
        class="post-image"
        :src="data.media[0].file_media"
        alt="post-image"
        loading="lazy"
      />
    </div>
    <div>
      <div class="post-detail__action f aic gap05 ph05">
        <!-- Thả tim bài viết -->
        <div
          v-if="!checkReactPost"
          @click="handleLikePost"
          class="post-detail__like px05 f aic border border-radius cursor"
        >
          <div class="icon--love f jcc aic">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1219_41179)">
                <path
                  d="M7.90234 0C6.03858 0 4.25115 0.740379 2.93326 2.05826C1.61538 3.37615 0.875 5.16358 0.875 7.02734C0.875 8.89108 1.61538 10.6786 2.93326 11.9965C4.25115 13.3143 6.03858 14.0547 7.90234 14.0547C9.76608 14.0547 11.5536 13.3143 12.8715 11.9965C14.1893 10.6786 14.9297 8.89108 14.9297 7.02734C14.9297 5.16358 14.1893 3.37615 12.8715 2.05826C11.5536 0.740379 9.76608 0 7.90234 0Z"
                  fill="url(#paint0_linear_1219_41179)"
                />
                <path
                  d="M10.0735 3.51367C8.14277 3.51367 7.9012 5.11591 7.9012 5.11591C7.9012 5.11591 7.66052 3.51367 5.72975 3.51367C3.87278 3.51367 3.33167 5.46552 3.5583 6.50908C4.15563 9.26731 7.9012 11.1998 7.9012 11.1998C7.9012 11.1998 11.6476 9.26731 12.245 6.50908C12.4707 5.46552 11.9287 3.51367 10.0735 3.51367Z"
                  fill="white"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_1219_41179"
                  x1="7.90234"
                  y1="0"
                  x2="7.90234"
                  y2="14.0547"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#757575" />
                  <stop offset="1" stop-color="#757575" />
                </linearGradient>
                <clipPath id="clip0_1219_41179">
                  <rect
                    width="14.0547"
                    height="14.0547"
                    fill="white"
                    transform="translate(0.875)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="p4 ml05 gray-1-text">{{ data.react.length }}</div>
        </div>
        <div
          v-if="checkReactPost"
          @click="handleDistLikePost"
          class="post-detail__like px05 f aic border border-radius cursor"
          style="border: 1px solid #ff6680"
        >
          <div class="icon--love f jcc aic">
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1219_41179)">
                <path
                  d="M7.90234 0C6.03858 0 4.25115 0.740379 2.93326 2.05826C1.61538 3.37615 0.875 5.16358 0.875 7.02734C0.875 8.89108 1.61538 10.6786 2.93326 11.9965C4.25115 13.3143 6.03858 14.0547 7.90234 14.0547C9.76608 14.0547 11.5536 13.3143 12.8715 11.9965C14.1893 10.6786 14.9297 8.89108 14.9297 7.02734C14.9297 5.16358 14.1893 3.37615 12.8715 2.05826C11.5536 0.740379 9.76608 0 7.90234 0Z"
                  fill="url(#paint0_linear_1219_41179)"
                />
                <path
                  d="M10.0735 3.51367C8.14277 3.51367 7.9012 5.11591 7.9012 5.11591C7.9012 5.11591 7.66052 3.51367 5.72975 3.51367C3.87278 3.51367 3.33167 5.46552 3.5583 6.50908C4.15563 9.26731 7.9012 11.1998 7.9012 11.1998C7.9012 11.1998 11.6476 9.26731 12.245 6.50908C12.4707 5.46552 11.9287 3.51367 10.0735 3.51367Z"
                  fill="white"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_1219_41179"
                  x1="7.90234"
                  y1="0"
                  x2="7.90234"
                  y2="14.0547"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF6680" />
                  <stop offset="1" stop-color="#E61739" />
                </linearGradient>
                <clipPath id="clip0_1219_41179">
                  <rect
                    width="14.0547"
                    height="14.0547"
                    fill="white"
                    transform="translate(0.875)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="p4 ml05 gray-1-text">{{ data.react.length }}</div>
        </div>

        <div
          class="f aic jcc border border-radius cursor"
          :class="showComment && 'show-comment'"
          style="width: 40px; height: 40px"
          @click="showComment = !showComment"
        >
          <img
            v-if="showComment"
            src="@/assets/icons/comment-active.svg"
            alt=""
            style="width: 15px; height: 15px"
          />
          <img
            v-else
            src="@/assets/icons/comment.svg"
            alt=""
            style="width: 15px; height: 15px"
          />
          <p v-if="dataComment" class="p4 ml05 gray-1-text">
            {{ dataComment.length }}
          </p>
        </div>

        <!-- chia sẻ -->
        <span
          class="f aic jcc border border-radius cursor"
          style="height: 40px; width: 40px"
          type="button"
          role="button"
          title="Share on facebook"
          target="_blank"
          rel="noopener"
          @click="handleShare"
        >
          <div class="icon--share"></div>
        </span>
      </div>
    </div>

    <div class="post-comment ph05">
      <!-- Bình luận -->
      <div class="post-detail__comment">
        <div v-if="userId" class="comment__input">
          <label class="h7 gray-1-text" for="">Thêm bình luận</label>
          <input
            @keypress.enter="handlePostComment"
            v-model="commentContent"
            placeholder="Đăng bình luận"
            class="w100 mt025 mb05 border border-radius"
            type="text"
          />
          <div class="w100 f jcb aic gray-1-text">
            <div>
              <div class="h6" v-if="commentResponseSelect !== null">
                Phản hồi comment của
                <span class="p4 brown"
                  >{{ commentResponseSelect.user_customer.username }}
                </span>
                <span
                  @click="commentResponseSelect = null"
                  class="p4 blue cursor"
                  >- Huỷ</span
                >
              </div>
            </div>
            <div class="p4">
              Nhấn <span class="brown"> Enter </span> để gửi phản hồi
            </div>
          </div>
        </div>
        <div v-else class="mb1">Đăng bình luận</div>
        <div class="comment__list">
          <div v-for="(item, index) in dataComment" :key="index">
            <div class="mb1" v-if="item.comment_parent === null">
              <div class="comment__item-user f mb05">
                <div>
                  <img
                    class="image_user border"
                    :src="item.image_user"
                    alt=""
                  />
                </div>
                <div>
                  <div
                    class="ml05 f fdc jcb ais bg-gray-2 border-radius px05"
                    :class="item.user_customer && item.user_customer.id === userId && 'border'"
                    style="border-color: #2577c9"
                  >
                    <div class="p4">{{ item.user_customer.username }}</div>
                    <div class="comment__item-content p6 border-radius">
                      {{ item.content }}
                    </div>
                  </div>
                  <div class="h6 gray-1-text pl05 f aic gap1">
                    {{ convertTime(item.created_at) }}
                    <div
                      @click="commentResponseSelect = item"
                      class="comment__item-response f jce aic"
                    >
                      <div class="h7 blue cursor">Phản hồi bình luận</div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-for="(value, key) in dataComment" :key="key">
                <div
                  class="ml2 pl2 mb2"
                  style="border-left: 1px solid #ECF9FF;"
                  v-if="
                    value.comment_parent !== null &&
                    value.comment_parent.id === item.id
                  "
                >
                  <div class="comment__item-user f mb05">
                    <div>
                      <img
                        class="image_user border"
                        :src="value.image_user"
                        alt=""
                      />
                    </div>
                    <div>
                      <div
                        class="ml05 f fdc jcb ais bg-gray-2 border-radius px05"
                        :class="value.user_customer.id === userId && border"
                        style="border-color: #2577c9"
                      >
                        <div class="p4">{{ value.user_customer.username }}</div>
                        <div class="comment__item-content p6 border-radius">
                          {{ value.content }}
                        </div>
                      </div>
                      <div class="h6 gray-1-text pl05 f aic gap1">
                        {{ convertTime(value.created_at) }}
                        <!-- <div
                          @click="commentResponseSelect = value"
                          class="comment__item-response f jce aic"
                        >
                          <div class="h7 blue cursor">Phản hồi bình luận</div>
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/http-default";

export const MAX_LINE = 3;
export const MAX_COMMENT = 5;

export default {
  name: "PostViewComponent",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showMore: false,
      showComment: false,
      showAllComment: false,
      commentResponseSelect: null,
      commentContent: "",
      dataComment: [],
    };
  },

  computed: {
    checkReactPost() {
      var userId = localStorage.getItem("idUser");
      var dataReact = this.data.react.filter(
        (item) => parseInt(item.id) === parseInt(userId)
      );

      if (dataReact.length !== 0) {
        return true;
      } else {
        return false;
      }
    },

    userId() {
      return localStorage.getItem("idUser");
    },

    shareUrl() {
      const production = 'https://drmom.com.vn'
      const idSharing = this.data.id
      const dataUser = JSON.parse(localStorage.getItem("myAccount"))
      const key_affiliate = dataUser?.code_affiliate || ''
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`${production}/#/forums?post_id=${idSharing}&key_affiliate=${key_affiliate}`)}`
    },
  },

  watch: {
    data: {
      handler() {
        this.dataComment = this.data.dataComment
      },
      deep: true,
    },
  },

  mounted() {
    this.checkContentLine();
    this.dataComment = this.data.dataComment
  },

  methods: {
    convertTime(value) {
      var time = value.split("T");
      return time[0];
    },

    checkContentLine() {
      const ref = this.$refs.content;
      const lineHeight = parseFloat(window.getComputedStyle(ref).lineHeight);
      const height = ref.offsetHeight;
      const line = Math.ceil(height / lineHeight);
      this.showMore = line >= MAX_LINE;
    },

    handleLikePost() {
      var userId = localStorage.getItem("idUser");
      const data = {
        ...this.data,
      };
      data.react.push({ id: parseInt(userId) });

      this.handlePutData(data);
    },

    handleDistLikePost() {
      var userId = localStorage.getItem("idUser");
      const data = { ...this.data };

      for (let i = 0; i < data.react.length; i++) {
        if (data.react[i].id === parseInt(userId)) {
          data.react.splice(i, 1);
        }
      }

      this.handlePutData(data);
    },

    handlePutData(data) {
      const payload = {
        permalink: this.data.permalink,
        system_show: this.data.system_show.map((e) => e.id),
        media: this.data.media.map((e) => e.id),
        react: this.data.react.map((e) => e.id),
        user_created: this.data.user_created.id,
      };
      HTTP.put("v1/api/post/" + data.id + "/", JSON.stringify(payload));
    },

    convertUrl(value) {
      var url = value.replaceAll("/", "%2F");
      url = url.replaceAll("?", "%3F");
      url = url.replaceAll("=", "%3D");
      return url;
    },

    handlePostComment(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        var data = {
          content: this.commentContent,
          status_comment: 5, // fix theo status db
          post: this.data.id,
          user_customer: parseInt(this.userId),
          comment_parent:
            this.commentResponseSelect === null
              ? null
              : this.commentResponseSelect.id,
        };

        // this.dataComment.push(data)
        const dataPost = JSON.stringify(data);

        HTTP.post("v1/api/commentpost/", dataPost).then(() => {
          this.commentContent = "";
          HTTP.get("v1/api/commentpost/?depth=1&post=" + this.data.id).then(
            (res) => {
              this.dataComment = res.data.results.filter(
                (e) => e.user_customer
              );
            }
          );
        });
      }
    },

    async handleShare() {
      window.open(this.shareUrl, "_blank");
      if (this.userId) {
        const payload = {
          user_id: this.userId,
          shared_link: window.location.href,
          post_id:  this.data.id,
        };

        await HTTP.post("/sharepoint/create/", payload)
      }
    },
  },
};
</script>

<style scoped lang="scss">
.post-view-component {
  background: #fff;
  padding: 1em 0;
  border-radius: 0.5em;
}

.user-avatar {
  height: 40px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 50%;
}

.post-image {
  max-height: 400px;
  max-width: 400px;
  height: auto;
  object-fit: cover;
  border-radius: 0.25em;
  padding: 0 0.5em;

  @media screen and (max-width: 768px) {
    width: 100%;
    border-radius: 0;
    padding: 0;
  }
}

.post-detail__action {

}

.post-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.show {
  overflow: auto;
}

.show-comment {
  border-color: #2577c9;

  & path {
    fill: #2577c9;
  }
}

.image_user {
  width: 30px !important;
  height: 30px !important;
  object-fit: cover;
}
</style>