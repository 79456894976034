@<template>
    <div class="relative f jcb aic">
        <div class="w100 my-affiliate__container">
            <div class="my-affiliate__header f jcb aic mb1">
                <div class="h3">
                    Điểm Affiliate <span class="orange">({{dataAffiliate.length}})</span>
                </div>
                <div>
                    <input 
                        v-model="timeFilterAffiliate"
                        type="month">
                </div>
            </div>
            <div class="my-affiliate__body">
                <div class="my-affiliate__title mb1">
                    <div class="p6 gray-1-text">STT</div>
                    <div class="p6 gray-1-text align-c">Loại điểm</div>
                    <div class="p6 gray-1-text align-c">Điểm cộng</div>
                    <div class="p6 gray-1-text align-c">Chiết khấu</div>
                    <div class="p6 gray-1-text align-c">Thời gian tạo</div>
                </div>

                <div class="my-affiliate__list f fdc ais">
                    <div 
                        v-for="(item, index) in dataAffiliate"
                        :key="index"
                        class="w100 my-affiliate__item">
                        <div class="p6 gray-1-text">{{index + 1}}</div>
                        <div class="p5 blue align-c">Affiliate {{item.type_data}}</div>
                        <div class="p5 gray-1-text align-c"> + {{item.point}} điểm</div>
                        <div class="p5 gray-1-text align-c">{{convertMoney(item.money)}}</div>
                        <div class="p5 gray-1-text align-c">{{convertTime(item.created_at)}}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="my-affiliate__total px1 border border-radius absolute">
            <div class="p5 brown mb1">
                Kết quả affiliate
            </div>
            <div class="f jcb aic pb05 border-bottom">
                <div class="h7 gray-1-text">
                    Tổng điểm affiliate
                </div>
                <div class="h7 blue">
                    {{totalPoint}}
                </div>
            </div>
            <div class="f jcb aic mt05">
                <div class="h7 gray-1-text">
                    Tổng chiết khấu
                </div>
                <div class="h7 blue">
                    {{totalMoney}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
export default {
    data () {
        return {
            dataAffiliate : [],
            timeFilterAffiliate : ''
        }
    },
    watch : {
        'timeFilterAffiliate' : function () {
            let customer = JSON.parse(localStorage.getItem('myAccount'))

            if (this.timeFilterAffiliate !== '') {
                var time = this.timeFilterAffiliate.split('-')
                HTTP.get('v1/api/affiliateview/?customer=' + customer.id + '&month=' + time[1] + '&year=' + time[0])
                .then((res) => {
                    this.dataAffiliate = res.data.results
                })
            } else {
                HTTP.get('v1/api/affiliateview/?customer=' + customer.id)
                .then((res) => {
                    this.dataAffiliate = res.data.results
                })
            }
        }
    },

    computed : {
        totalMoney () {
            const totalMoney = this.dataAffiliate.reduce((total, item) => {
                return total + item.money
            }, 0)

            return this.convertMoney(totalMoney)
        },

        totalPoint () {
            const totalPoint = this.dataAffiliate.reduce((total, item) => {
                return total + item.point
            }, 0)

            return totalPoint
        }
    }, 

    methods : {
        convertMoney (value) {
            var x = value;
            x = x.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
            return x
        },

        convertTime (value) {
            var time = value.split('T')
            return time[0]
        },

        getData () {
            let customer = JSON.parse(localStorage.getItem('myAccount'))
            HTTP.get('v1/api/affiliateview/?customer=' + customer.id)
            .then((res) => {
                this.dataAffiliate = res.data.results
            })
        }
    },

    created () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'MyAffiliate.scss';
</style>