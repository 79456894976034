@<template>
  <div class="all-category__container container">
    <div class="all-category__header f aic mb2">
      <div class="pr1 border-right">
        <div class="qcont h2">
          {{ data.props.titlecontent }}
        </div>
      </div>
      <div class="pr05 pl1">
        <div class="qcont h6 gray">
          {{ data.props.titlecontent }}
        </div>
      </div>
      <div class="icon-rotate icon--arrown"></div>
      <div class="h5 ml05 brown">Tất cả danh mục</div>
    </div>

    <!-- tìm kiếm -->
    <div class="all-category__search relative f jcb mb2">
      <div class="search-icon absolute">
        <div class="icon icon--search"></div>
      </div>
      <input
        v-model="search"
        class="h6 pl2 pt075 pb075 pr075"
        type="text"
        style="width: 417px"
        :placeholder="'Nhập danh mục bạn muốn tìm'"
      />
    </div>

    <!-- danh sách danh mục -->
    <div class="all-category__list">
      <div class="category-header f jcb mb2">
        <div class="category-header__title p3 mb1">
          Danh mục {{ data.props.titlecontent }}
          <span v-if="$route.params.path != 'quan-ly-post'" class="orange"
            >({{ dataProps.length }})</span
          >
        </div>
        <div
          class="category-btn f jcc aic pt075 pb075 pl1 pr1 border-radius bg-brown"
        >
          <svg width="16" height="12" viewBox="0 0 16 12">
            <use
              xlink="http://www.w3.org/2000/svg"
              href="#folder"
              x="0"
              y="0"
            ></use>
          </svg>
          <div @click="handleAddFolder" class="h5 ml05 cursor white">
            Tạo mới
          </div>
        </div>
      </div>
      <div class="category-body">
        <div
          v-if="$route.params.path != 'quan-ly-post'"
          class="category-list f fw aic w100"
        >
          <router-link
            :to="{
              name: 'detail-category',
              params: { id: item.id, type: 'category' },
            }"
            v-for="(item, index) in dataProps.filter(
              (e) =>
                (e.category_name && e.category_name.includes(search.trim())) ||
                (e.title_funnel && e.title_funnel.includes(search.trim())) ||
                (e.title && e.title.includes(search.trim()))
            )"
            :key="index"
            class="category__item f aic pt075 pb075 pl1 pr1 border border-radius bg-white-1"
          >
            <svg width="16" height="12" viewBox="0 0 16 12">
              <use
                xlink="http://www.w3.org/2000/svg"
                href="#folderBrown"
                x="0"
                y="0"
              ></use>
            </svg>

            <div v-if="$route.params.path.includes('quan-ly-funnel')" class="ml05 h7">
              {{ item.title_funnel }}
            </div>
            <div
              v-if="$route.params.path === 'quan-ly-product'"
              class="ml05 h7"
            >
              {{ item.category_name }}
            </div>
          </router-link>
        </div>

        <div v-else class="f w100 fdc gap1">
          <TreeCategory :dataTree="dataProps" />
        </div>
      </div>
    </div>

    <SvgView></SvgView>
  </div>
</template>

<script>
import SvgView from "@/components/SvgView/SvgView.vue";
import TreeCategory from "@/components/AllCategory/TreeCategory.vue";

import { HTTP } from "@/http-default";

export const WEBSITE = 2
export const FORUM = 1

export default {
  components: {
    SvgView,
    TreeCategory,
  },

  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    // dataProps: {
    //     type : Array,
    //     default() {
    //         return []
    //     }
    // },
  },

  data() {
    return {
      dataProps: [],
      search: "",
			dataPost: [],
    };
  },

  methods: {
    handleAddFolder() {
      var popup = document.getElementsByClassName("pop-up-add-folder")[0];
      var CustomerView = document.getElementsByClassName("Cus-container")[0];
      popup.classList.add("popup-active");
      CustomerView.classList.add("popup-flow");
    },

    async getData() {
      if (this.$route.params.path === "quan-ly-post") {
				await HTTP.get(`v1/api/post/?system_show=${WEBSITE}`).then((res) => {
          this.dataPost = res.data.results
        });
        HTTP.get("v1/api/categorypost/").then((res) => {
          this.dataProps = this.renderTree(res.data.results, this.dataPost);
        });
      }
      if (this.$route.params.path === "quan-ly-product") {
        HTTP.get("v1/api/categoryproduct/").then((res) => {
          this.dataProps = res.data.results;
        });
      }
      if (this.$route.params.path === "quan-ly-funnel") {
        HTTP.get("v1/api/funnel/").then((res) => {
          this.dataProps = res.data.results;
        });
      }
    },

    renderTree(data, dataPost = []) {
      const map = {};
      const roots = [];

      data.forEach((item) => {
        map[item.id] = { 
					...item, 
					children: [],
					listPost: dataPost.filter(e => e.category?.includes(item.id)),
				};
      });

      data.forEach((item) => {
        if (item.category_parent) {
          map[item.category_parent]?.children.push(map[item.id]);
        } else {
          roots.push(map[item.id]);
        }
      });
      return roots;
    },
  },

  created() {
    this.getData();
  },
};
</script>

<style scoped lang="scss">
@import "AllCategory.scss";
</style>