<template>
        <div class="add-medical z10">

            <!-- thêm mới bênh lý -->
            <div
                v-if="statusPopup == 'create'" 
                class="add-medical__view f jcc aic w100 h100">
                <div class="add-medical__pop-up border-radius">
                    <div class="medical__text w100 f fdc ais mb1">
                        <h5 class="mb05 brown">Bổ sung hồ sơ bệnh lý</h5>
                        <textarea v-model="title" class="medical-title border border-radius w100 h7 pt075 pb 075 pr075 pl075" name="" id="" cols="30" rows="10" placeholder="Giúp bạn chăm sóc khách hàng tốt hơn"></textarea>
                    </div>
                    <!-- <div class="medical__img mb15">
                        <div class="w100 f mt05 aic pt1 pb1 pl1 pr1 border-radius bg-gray-2" style="border: 1px dashed #D9DDE8;">
                            <div class="h7 pt05 pb05 pr1 pl1 mr1 border-radius bg-white-1" style="border: 1px solid #D9DDE8;">
                                Tải ảnh lên
                            </div>
                            <div>
                                <div class="p4">Bạn có thể thêm nhiều ảnh cùng lúc(tối đa: 10 ảnh)</div>
                                <h6 class="gray">Dung lượng ảnh tối đa: 2MB</h6>
                            </div>
                        </div>
                    </div> -->
                    <div class="medical__img mb1">
                        <div class="h7">Tải ảnh lên</div>
                        <div class="w100 f mt05 aic pt1 pb1 pl1 pr1 border-radius bg-gray-3" style="border: 1px dashed #D9DDE8;">
                            <div
                                @click="uploadImg" 
                                class="h7 pt05 pb05 pr1 pl1 mr1 border-radius border cursor">
                                <input 
                                    type="file" 
                                    accept="image/*" 
                                    ref="file"
                                    class="hide"
                                    @change="uploadImage($event)" 
                                    id="file-input">
                                <i>Tải ảnh lên</i>
                            </div>
                            <div>
                                <div class="p4">Bạn có thể thêm nhiều ảnh cùng lúc(tối đa: 10 ảnh)</div>
                                <div class="h6 gray">Dung lượng ảnh tối đa: 2MB</div>
                            </div>
                        </div>
                        <div id="list_img-relate" class="list__img f aic mt05">
                            <div
                                class="img-upload__item relative"
                                v-for="(item, index) in avatar"
                                :key="index">
                                <div 
                                    @click="handleDeleteImg(item)"
                                    class="img-upload__delete absolute jcc aic white cursor">x</div>
                                <img class="img-upload__img border border-radius mr05" :src="item" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="f jcc aic">
                        <button @click="handleClose" class="btn btn-close h5 border-radius mr1">Đóng</button>
                        <button @click="handleAdd" class="btn btn-add h5 border-radius">Thêm mới</button>
                    </div>
                </div>
            </div>

            <!-- Xem bệnh lý -->
            <div 
                v-else-if="statusPopup == 'view'"
                class="view-medical__view f jcc aic w100 h100">
                <div class="add-medical__pop-up border-radius">
                    <div class="medical__text w100 f fdc ais mb1">
                        <div class="f jcb aic w100 mb1">
                            <div class="h5 brown">Hồ sơ bệnh lý</div>
                            <div
                                @click="handleEdit" 
                                class="icon--edit cursor"></div>
                        </div>
                        <div class="w100 pt15 pb15 pl15 pr15 border-radius bg-gray-2">
                            {{dataPopup.content_care}}
                        </div>
                    </div>
                    <div id="list_img-relate" class="list__img f aic">
                        <div
                            class="img-upload__item relative"
                            v-for="(item, index) in avatar"
                            :key="index">
                            <div 
                                @click="handleDeleteImg(item)"
                                class="img-upload__delete absolute jcc aic white cursor">x</div>
                            <img class="img-upload__img border border-radius mr05" :src="item" alt="">
                        </div>
                    </div>
                    <div class="f jcc aic">
                        <button @click="handleClose" class="btn btn-close h5 border-radius">Đóng</button>
                    </div>
                </div>
            </div>

            <!-- Sửa bệnh lý -->
            <div 
                v-else-if="statusPopup == 'edit'"
                class="edit-medical__view f jcc aic w100 h100">
                <div class="add-medical__pop-up border-radius">
                    <div class="medical__text w100 f fdc ais mb1">
                        <div class="f jcb aic w100 mb1">
                            <div class="h5 brown">Sửa hồ sơ bệnh lý</div>
                            <div 
                                @click="handleDelete"
                                class="f aic cursor">
                                <div class="icon--delete"></div>
                                <div class="ml05 p5 blue">Xóa</div>
                            </div>
                        </div>
                        <textarea v-model="dataTitle" class="medical-title border border-radius w100 h7 pt075 pb 075 pr075 pl075" name="" id="" cols="30" rows="10" placeholder="Giúp bạn chăm sóc khách hàng tốt hơn"></textarea>
                    </div>
                    <div class="medical__img mb1">
                        <div class="h7">Tải ảnh lên</div>
                        <div class="w100 f mt05 aic pt1 pb1 pl1 pr1 border-radius bg-gray-3" style="border: 1px dashed #D9DDE8;">
                            <div
                                @click="uploadImg" 
                                class="h7 pt05 pb05 pr1 pl1 mr1 border-radius border cursor">
                                <input 
                                    type="file" 
                                    accept="image/*" 
                                    ref="file"
                                    class="hide"
                                    @change="uploadImage($event)" 
                                    id="file-input">
                                <i>Tải ảnh lên</i>
                            </div>
                            <div>
                                <div class="p4">Bạn có thể thêm nhiều ảnh cùng lúc(tối đa: 10 ảnh)</div>
                                <div class="h6 gray">Dung lượng ảnh tối đa: 2MB</div>
                            </div>
                        </div>
                        <div id="list_img-relate" class="list__img f aic mt05">
                            <div
                                class="img-upload__item relative"
                                v-for="(item, index) in avatar"
                                :key="index">
                                <div 
                                    @click="handleDeleteImg(item)"
                                    class="img-upload__delete absolute jcc aic white cursor">x</div>
                                <img 
                                    class="img-upload__img border border-radius mr05" 
                                    :src="item" 
                                    alt="">
                            </div>
                        </div>
                    </div>
                    <div class="f jcc aic">
                        <button @click="handleClose" class="btn btn-close h5 border-radius mr1">Đóng</button>
                        <button @click="handleUpdate" class="btn btn-add h5 border-radius">Cập nhật</button>
                    </div>
                </div>
            </div>
      </div>
</template>

<script>
import { HTTP } from '@/http-default';
export default {

    props : {
        dataPopup : {
            type : Object,
            default() {
                return {}
            }
        },
        statusPopup: String
    },

    created () {
        if (Object.keys(this.dataPopup).length !== 0) {
            this.dataTitle = this.dataPopup.content_care
            this.avatar.push(this.dataPopup.image_care)
        }
    },

    data() {
        return {
            title : [],
            dataTitle : '',
            avatar : [],
            dataImg: []
        }
    },
    methods: {
        handleDelete() {
            HTTP.delete('v1/api/customercare/' + this.dataPopup.id + '/')
            .then(() => {
                this.$emit('updateMedical')
                this.handleClose()

                var popup = document.getElementsByClassName('popup-noti')[0]
                popup.classList.toggle('f')
            })
        },

        // upload img
        uploadImg () {
            var input = document.getElementById('file-input')
            input.click()
        },

        uploadImage(event) {
            this.avatar = []
            var url = URL.createObjectURL(event.target.files[0]);
            this.avatar.push(url)
            this.dataImg.push(event.target.files[0])
        },

        handleEdit() {
            // eslint-disable-next-line vue/no-mutating-props
            this.$emit('changeStatusPopup', 'edit')
        },

        handleClose() {
            this.$emit('closePopup')

            this.dataTitle = '',
            this.title = '',
            this.avatar = []
        },
        handleAdd() {
            // var data  = JSON.stringify({
            //     "content_care" : this.title,
            //     "customer" : this.$route.params.id,
            //     "user_created": 2
            // })
            const idUser = JSON.parse(localStorage.getItem('idUser'))

            var data = new FormData()

            data.append('content_care', this.title)
            data.append('customer', this.$route.params.id)
            data.append('user_created', idUser)
            this.dataImg[0] && data.append('image_care', this.dataImg[0])

            HTTP.post('v1/api/customercare/', data)
            .then(() => {
                this.$emit('updateMedical')
                this.avatar = []
                this.handleClose()
            })
        },
        
        handleUpdate () {

            var data = new FormData()

            data.append('content_care', this.dataTitle)
            data.append('customer', this.$route.params.id)
            this.dataImg[0] && data.append('image_care', this.dataImg[0])

            HTTP.put('v1/api/customercare/' + this.dataPopup.id + '/',data)
            .then(() => {
                this.$emit('updateMedical')
                this.avatar = []
                this.handleClose()
            })
        }
    }
}
</script>

<style lang="scss">
@import "AddMedical.scss"
</style>