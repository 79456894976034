import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import CustomerView from '../views/CustomerView.vue'
import DetailItem from '@/components/DetailItem/DetailItem.vue'
// import ListCustomerView from '../components/CustomerView/ListCustomerView.vue'
// import AddCustomer from '../components/CustomerView/AddCustomer/AddCustomer.vue'
import DetailCustomer from '../components/CustomerView/DetailCustomer/DetailCustomerView.vue'
// import GiftView from '../components/GiftView/GiftView.vue'
// import ProductView from '../components/ProductView/ProductView.vue'
// import FolderAllView from '../components/ProductView/FolderAllView/FolderAllView.vue'
// import FolderDetailView from '../components/ProductView/FolderDetailView/FolderDetailView.vue'
// import OrderView from '@/components/OrderView/OrderView.vue'
import ForumsView from '@/views/ForumsView/ForumsView.vue'
import ListArticle from '@/components/ListArticle/ListArticle.vue'
import GroupArticle from '@/components/GroupArticle/GroupArticle.vue'

// Web site
import Landingpage from '@/components/Landingpage/LandingPage.vue'

// Tối ưu
import BodyView from '@/views/BodyView/BodyView.vue'
import AddNewView from '@/components/AddNew/AddNewView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'website',
    component: Landingpage
  },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: LoginView
  // },
  {
    path: '/home',
    name: 'home1',
    component: CustomerView,
    children: [
      {
        path: "/:path",
        name: 'body-view',
        component: BodyView,
        // children : [
        //   {
        //     path: "/:path/:website",
        //     name: 'website-view',
        //     component: DetailCustomer,
        //   },
        // ]
      },
      {
        path: "/:path/category/all-category",
        name: 'all-category',
        component: BodyView,
      },
      {
        path: "/:path/:id/:type",
        name: 'detail-category',
        component: BodyView,
      },
      {
        path: "/:path/:id",
        name: 'body-detail',
        component: DetailItem,
      },
      {
        path: "/:path/:id/:menu",
        name: 'body-detail-menu',
        component: DetailCustomer,
      },
      {
        path: "/:path/Add/AddNew/Thêm-mới",
        name: 'add-new',
        component: AddNewView,
      },
      {
        path: "/:path/Add/Post/Bài-viết/:typePost",
        name: 'add-new-post',
        component: AddNewView,
      },
    ]
  },
  {
    path: '/forums',
    name: 'Forums',
    component: ForumsView,
    children: [
      {
        path: '/forums',
        name: 'Forums',
        component: ListArticle
      },
      {
        path: '/forums/:id',
        name: 'Forums',
        component: GroupArticle
      },
    ]
  },
  // {
  //   path: '/fbChat/facebook/1/23',
  //   name: 'FB-chat',
  //   component: FBChat
  // },
]

const router = new VueRouter({
  routes
})

// const role = localStorage.getItem('role')
// const isAdmin = await bcrypt.compare('admin', role)

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'website' && !authenticated) next({ name: 'website' })
//   // else if (to.name === 'website' && authenticated ) next ({ path: "/quan-ly-funnel" })
//   else next()
// })

export default router
