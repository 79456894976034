@<template>
    <div class="detail-user__container container">
        
        <!-- header -->
        <div class="detail-user__header">
            <div class="container-header f aic mb2">
                <div class="qcont h2 pr1 border-right">
                    User
                </div>
                <div 
                    class="h5 pr05 pl1 gray">
                    User
                </div>
                <div class="icon--arrown-left"></div>
                <div 
                    class="h5 pr05 pl1 brown">
                    Chi tiết user
                </div>
            </div>
        </div>

        <!-- body -->
        <div class="detail-user__body">

            <!-- left -->
            <div class="detail-user__left">
                <div class="f aic mb15">
                    <div class="p4 gray-1-text pr05 border-right">Bộ lọc</div>
                    <div class="f aic h6 gray-1-text pl05">
                        Trạng thái: 
                        <span class="p4 brown">Tất cả</span>
                        <div class="icon--arrown ml05"></div>
                    </div>
                </div>
                <div class="h3 gray-1-text mb1">
                    Số quà tặng đã nhận <span class="red">({{0}})</span>
                </div>

                <div class="w100 f fdc aic jcc px2 border-radius bg-white-1 shadow">
                    <div class="icon--face-smile"></div>
                    <div class="h5 mt2">Người dùng chưa được nhận được quà</div>
                </div>
            </div>

            <!-- right -->
            <div class="f fdc aic jcc">
                
                <div class="detail-user__right">
                    <!-- Thống kê tài khoản -->
                    <div class="px1 border-radius w100 bg-white-1 shadow mb1     ">
                        <div class="p2 brown mb15">
                            Thông kê tài khoản
                        </div>

                        <!-- tổng quan -->
                        <div class="statistical mb1">
                            <div class="statistical__header p2 mb05">
                                Tổng quan
                            </div>
                            <div class="f jcb aic">
                                <div class="f fdc ais">
                                    <div class="h6 mb025">Điểm thành viên</div>
                                    <div class="p4 brown"> {{dataUser.point}} Điểm</div>
                                </div>
                                <div>
                                    <div 
                                        @click="popupPointLog = true"
                                        class="p4 blue cursor align-r">Xem thống kê điểm thành viên</div>
                                    <div 
                                        @click="popupAffiliate = true"
                                        class="p4 blue cursor align-r">Xem thống kê Affiliate</div>
                                </div>
                            </div>
                        </div>

                        <div class="forum">
                            <div class="statistical__header p2 mb05">
                                Hoạt động trên Forum
                            </div>
                            <div class="f jcb aic mb1">
                                <div class="f fdc ais">
                                    <div class="h6 mb025">Số bài viết</div>
                                    <div class="p4 brown"> {{dataPost.length}} bài</div>
                                </div>
                                <!-- <div class="p4 blue cursor">Lịch sử đăng bài</div> -->
                            </div>
                            <div class="f jcb aic">
                                <div class="f fdc ais">
                                    <div class="h6 mb025">Số tương tác</div>
                                    <div class="p4 brown"> {{dataPostReact.length}} lượt</div>
                                </div>
                                <!-- <div class="p4 blue cursor">Lịch sử tương tác</div> -->
                            </div>
                        </div>
                    </div>

                    <!--Thông tin tài khoản-->
                    <div class="info-account__form w100 border-radius px1 mb1 bg-white-1">
                        <div class="form__header f jcb aic mb1">
                            <div class="p2 brown">Thông tin tài khoản</div>
                            <!-- <div 
                                @click="popupSetting = true"
                                class="px05 h7 f aic border-radius bg-brown white cursor">
                                <div class="icon--setting-white mr05"></div>
                                Cài đặt
                            </div> -->
                        </div>
                        <div class="form__body">
                            <div 
                                class="f fdc ais w100 mb1">
                                <label class="h7" for="">Loại user </label>
                                <v-select 
                                    v-if="errorInput.role"
                                    class="mt025 border-error"
                                    placeholder="Chọn loại user"
                                    :reduce='title => title.id'
                                    label='title'
                                    v-model="dataInput.role"
                                    :options='dataRole'></v-select>
                                <v-select 
                                    v-if="!errorInput.role"
                                    class="mt025"
                                    placeholder="Chọn loại user"
                                    :reduce='title => title.id'
                                    label='title'
                                    v-model="dataInput.role"
                                    :options='dataRole'></v-select>
                            </div>
                            <div class="f ais jcb mb1">
                                <div class="user-name__input w50 f fdc ais">
                                    <label class="h7" for="">Mã user</label>
                                    <div class="w100 blue h6 bg-gray-2 border border-radius px075">
                                        #{{dataUser.key_customer}}
                                    </div>
                                </div>
                                <div class="user-name__input w50 ml1 f fdc ais">
                                    <label class="h7" for="">Mã Affilliate</label>
                                    <div class="w100 blue h6 bg-gray-2 border border-radius px075">
                                        #{{dataUser.code_affiliate}}
                                    </div>
                                </div>
                            </div>
                            <div class="f ais jcb">
                                <div class="user-name__input w50 f fdc ais">
                                    <label class="h7" for="">Tên đăng nhập </label>
                                    <div class="w100 blue h6 bg-gray-2 border border-radius px075">
                                        #{{userAccount.username}}
                                    </div>
                                </div>
                                <div class="user-name__input w50 ml1 f fdc ais">
                                    <label class="h7" for="">Mật khẩu </label>
                                    <div class="w100 blue h6 bg-gray-2 border border-radius px075">
                                        **********
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Thông tin cá nhân -->
                    <div class="info-personal__form w100 border-radius px1 mb1 bg-white-1 shadow">
                        <div class="form__header mb1">
                            <div class="p2 brown">Thông tin cá nhân</div>
                        </div>
                        <div class="form__img-user f fdc jcc aic">
                            <div class="relative">
                                <img 
                                    :src="dataUser.avatar" alt="">
                                <div class="change-img__btn absolute cursor">
                                    <input 
                                        class="hide"
                                        type="file" 
                                        accept="image/*" 
                                        ref="file"
                                        @change="uploadImage($event)" 
                                        id="file-input">
                                    <div 
                                        @click="uploadImg"
                                        class="bg-brown px05">
                                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24"><path fill="#FFFFFF" d="M12.29,5.21l1.5,1.5a1,1,0,0,0,1.42,0,1,1,0,0,0,.13-1.21H19a1,1,0,0,0,0-2H15.34a1,1,0,0,0-.13-1.21,1,1,0,0,0-1.42,0l-1.5,1.5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76A1,1,0,0,0,12.29,5.21ZM22.92,9.12a1,1,0,0,0-.21-.33l-1.5-1.5a1,1,0,0,0-1.42,0,1,1,0,0,0-.13,1.21H16a1,1,0,0,0,0,2h3.66a1,1,0,0,0,.13,1.21,1,1,0,0,0,1.42,0l1.5-1.5a1,1,0,0,0,.21-.33A1,1,0,0,0,22.92,9.12ZM11,10a4,4,0,1,0,4,4A4,4,0,0,0,11,10Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,11,16Zm9-3a1,1,0,0,0-1,1v5a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H6a1,1,0,0,0,1-.69l.54-1.62A1,1,0,0,1,8.44,7H10a1,1,0,0,0,0-2H8.44A3,3,0,0,0,5.59,7.06L5.28,8H4a3,3,0,0,0-3,3v8a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V14A1,1,0,0,0,20,13Z"/></svg>
                                    </div>
                                </div>
                            </div>
                            <div class="h6 mt05">
                                Kích thước tối đa nhỏ hơn 2MB
                            </div>
                        </div>

                        <div class="form__body f fdc ais mb1">
                            <div class="w100 f fdc ais">
                                <label class="h7" for="">Họ và tên <span class="red">*</span>  </label>
                                <input 
                                    class="w100 mt025 border-radius"
                                    placeholder="Nhập họ và tên"
                                    v-model="dataInput.full_name"
                                    type="text" name="" id="">
                            </div>
                            <div class="w100 f aic jcb">
                                <div class="f fdc ais w50">
                                    <label class="h7" for="">Email  <span class="red">*</span> </label>
                                    <input 
                                        class="w100 mt025 border-radius"
                                        placeholder="Nhập email"
                                        v-model="dataInput.email"
                                        type="text" name="" id="">
                                </div>
                                <div class="f fdc ais w50 ml1">
                                    <label class="h7" for="">Số điện thoại  <span class="red">*</span>  </label>
                                    <input 
                                        class="w100 mt025 border-radius"
                                        placeholder="Nhập số điện thoại"
                                        v-model="dataInput.phone"
                                        type="text" name="" id="">
                                </div>
                            </div>
                            <div class="w100 f ais jcb">
                                <div class="f fdc ais w50">
                                    <label class="h7" for="">Giới tính</label>
                                    <v-select 
                                        class="mt025"
                                        placeholder="Chọn"
                                        v-model="dataInput.gender"
                                        :options='dataGender'></v-select>
                                </div>
                                <div class="f fdc ais w50 ml1">
                                    <label class="h7" for="">Ngày sinh</label>
                                    <input 
                                        class="w100 mt025 px075 border-radius"
                                        placeholder="Nhập số điện thoại"
                                        v-model="dataInput.birthday"
                                        type="date" name="" id="">
                                </div>
                            </div> 
                        </div>

                        <div class="form__action w100 f jce aic cursor">
                            <div 
                                @click="handlePutData"
                                class="h5 px075 border-radius align-c bg-brown white">
                                Lưu lại
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            v-if="popupAffiliate"
            class="popup f jcc aic">
            <MyAffiliate
                @closePopup='closePopup'
                :dataUser='dataUser'/>
        </div>

        <div 
            v-if="popupPointLog"
            class="popup f jcc aic">
            <MyPointLog
                @closePopup='closePopup'
                :dataUser='dataUser'/>
        </div>

        <PermissionView 
            v-if="popupSetting"
            @closePopup='closeSetting'
            @updateData='updateData'
            :dataUser='dataUser'/>
        <NotiView 
            v-if="showNotification"
            :title="titleNotiview"
            :logo="logo"
            @closePopup='closeNoti'
        />
    </div>
</template>

<script>
import { HTTP } from '@/http-default'
import vSelect from 'vue-select'
import MyAffiliate from './AffiliateResult/AffiliateResult.vue'
import MyPointLog from './MyPointLog/MyPointLog.vue'
import PermissionView from './Permission/PermissionView.vue'
import NotiView from '@/components/NotiView/NotiView.vue'
export default {
    data () {
        return {
            popupAffiliate : false,
            popupSetting : false,
            popupPointLog: false,

            userAccount : {},
            dataUser : {},
            dataPost : [],
            dataPostReact : [],

            avatar : null,

            dataInput : {
                avatar : null,
                full_name : '',
                email : '',
                phone : '',
                gender : '',
                birthday : '',
                role : ''
            },

            errorInput : {
                full_name : false,
                email : false,
                phone : false,
                role : false,
                username : false,
                password : false,
            },

            dataRole : [],

            dataGender : [
                'Nam' , 'Nữ' , 'Giới tính khác'
            ],

            username : '',
            password : '',
            showNotification : false,
            titleNotiview : '',
            logo : '',
        }
    },

    components : {
        'v-select' : vSelect,
        MyAffiliate,
        MyPointLog,
        PermissionView,
        NotiView
    },

    methods : {
        uploadImg () {
            var input = document.getElementById('file-input')
            input.click()
        },

        uploadImage(event) {
            var url = URL.createObjectURL(event.target.files[0]);
            this.dataUser.avatar = url
            this.avatar = event.target.files[0]
        },

        closePopup () {
            this.popupAffiliate = false
            this.popupPointLog = false
        },

        closeNoti () {
            this.showNotification = false
        },


        closeSetting () {
            this.popupSetting = false
        },

        updateData (notifyData) {
            this.showNotification = notifyData.isShow;
            this.titleNotiview = notifyData.title;
            this.logo = notifyData.logo;
            this.getData()
        },

        getData () {
            HTTP.get('v1/api/user/' + this.$route.params.id + '/')
            .then((res) => {
                this.userAccount = res.data
            })

            HTTP.get('v1/api/customer/?depth=1')
            .then((res) => {
                const data = res.data.results
                this.dataUser = data.find(item => item.user.id == this.$route.params.id)

                for (let item in this.dataInput) {
                    this.dataInput[item] = this.dataUser[item]
                }
            })

            HTTP.get('v1/api/post/')
            .then((res) => {
                const data = res.data.results
                this.dataPost = data.filter(item => item.user_created == this.$route.params.id)
                data.forEach(item => {
                    var filter = item.react.indexOf(parseInt(this.$route.params.id))
                    if (filter !== -1) {
                        this.dataPostReact.push(item)
                    }
                })
            })

            HTTP.get('v1/api/roleuser/')
            .then((res) => {
                const data = res.data.results
                this.dataRole = data
            })
        },

        handlePutData () {
            const data = new FormData()

            data.append("full_name", this.dataInput.full_name)
            if (this.avatar !== null) {
                data.append("avatar", this.avatar)
            }
            data.append("phone", this.dataInput.phone)
            data.append("email", this.dataInput.email)
            data.append("gender", this.dataInput.gender)
            data.append("birthday", this.dataInput.birthday)
            data.append("role", this.dataInput.role?.id || this.dataInput.role)

            HTTP.patch('v1/api/customer/' + this.dataUser.id + '/', data)
            .then((res) => {
                if(res){
                    this.showNotification = true;
                    this.titleNotiview = 'Cập nhật thành công';
                    this.logo = 'noti-success';
                }
            })
            .catch(() => {
                this.showNotification = true;
                this.titleNotiview = 'Cập nhật thất bại';
                this.logo = 'noti-warning';
            })
            
        }
    },

    created () {
        this.getData()
    }
}
</script>

<style scoped lang='scss'>
    @import 'DetailUser.scss';
</style>