@<template>
    <div>
        <div class="file__contaienr">
            <div class="file__header f jcb aic mb15">
                <div class="p2 brown">Hồ sơ bệnh lý</div>
                <button @click="handleAddMedical('create')" class="h7 pt05 pb05 pl075 pr075 border-radius bg-brown white">Bổ sung hồ sơ</button>
            </div>
            <div class="file__search relative mb15">
                <div class="absolute" style="top: 35%; left: 2%">
                    <svg width="15" height="15" viewBox="0 0 15 15" class="mr05">
                        <use xlink="http://www.w3.org/2000/svg" href="#search" x="0" y="0"></use>
                    </svg>
                </div>
                <input 
                    @keyup="handleFilter"
                    id="myInput"
                    type="text" 
                    placeholder="Nhập mã hồ sơ bệnh lý" 
                    v-model="textSearch"
                    class="w80 pt1 pb1 pr1 pl2">
            </div>
            <div class="file__list">
                <div v-for="(item, index) in dataItemFilter" :key="index">
                    <div class="file-item pt1 pb1 pr1 pl1 mb1 border-radius bg-white-1">
                        <div class="file-item__header f jcb aic">
                            <div class="f aic">
                                <img :src="data.avatar" alt="" class="img-logo mr1">
                                <div class="f fdc jcb ">
                                    <div class="p4 blue">{{ data.full_name }}</div>
                                    <div class="h6 gray">#{{ data.key_customer }}</div>
                                </div>
                            </div>
                            <div class="f aic">
                                <div @click="handleAddMedical('view', item)">
                                    <div class="icon--eye cursor"></div>
                                </div>
                                <div @click="handleAddMedical('edit', item)" class="ml1">
                                    <div class="icon--edit cursor"></div>
                                </div>
                            </div>
                        </div>
                        <div class="file-item__time f jcb aic mt1">
                            <div class="h6 gray">Thời gian khởi tạo</div>
                            <div class="p4 orange">{{ item.created_at[0] }} - {{item.created_at[1][0]}}</div>
                        </div>
                        <div class="file-item__text p4 mt1">
                            {{ item.content_care}}
                        </div>
                        <div class="file-item__img mt1">
                            <div class="h6 mb05 gray ">Ảnh đính kèm</div>
                            <div>
                                <img 
                                    class="img-logo"
                                    :src="item.image_care" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
export default {
    props : {
        data : {
            type : Object,
            default () {
                return {}
            }
        }
    },

    data () {
        return {
            dataMedical : [],
            textSearch : null,
            dataItemFilter : []
        }
    },

    watch : {
        checkUpdate : function () {
            this.handleFilter()
        }
    },

    computed : {
        checkUpdate () {
            var length = 0
            if (this.data.customer_care) {
                length = this.data.customer_care.length
            }
            return length
        }
    },

    mounted () {
        this.dataItemFilter = this.data.customer_care
    },

    methods : {

        handleAddMedical(value, item) {
            this.$emit('upateStatusPopup', value, item)
        },

        handleFilter() {
            var input = document.getElementById("myInput");
            var filter = input.value.toUpperCase();
            // this.filterKey = filter;
            this.dataItemFilter = [];
            // this.personnelWait = [];
            if(!filter) {
                this.dataItemFilter = this.data.customer_care
            }
            else {
                for (let i = 0; i < this.data.customer_care.length; i++) {
                    if (this.data.customer_care[i].content_care.toUpperCase().indexOf(filter) > -1) {
                        this.dataItemFilter.push(this.data.customer_care[i])
                    }
                }
            }
        },

    },
}
</script>

<style scoped lang='scss'>
    @import 'MedicalRecords.scss';
</style>