<template>
  <div v-if="isMobile" class="w100 f fdc gap1">
    <template v-if="$route.params.path === 'quan-ly-post'">
      <router-link
        :to="{ name: 'body-detail', params: { id: item.id } }"
        v-for="(item, index) in dataProps"
        :key="index"
        class="w100 p5 pt1 pb1 pr1 pl1 mb1 border-radius bg-white-1"
      >
        <div class="w100">
          <div class="post-key__item f aic jcb">
            <div class="f aic">
              <div class="mr1">
                <img :src="item.media[0].file_media" alt="" />
              </div>
              <div class="f fdc ais jcb">
                <div class="p4 blue mb025">{{ item.title_post }}</div>
                <div class="h6 gray-1-text">
                  Tạo bởi:
                  <span class="p4 black">{{ item.user_created.username }}</span>
                </div>
              </div>
            </div>
            <div
              v-if="item.approved_status"
              class="post-key__item blue h7 f jcc aic cursor"
              style="min-width: 15vw;"
            >
              <!-- <div
                v-if="item.status_post.title_status_post === 'Đã đăng'"
                class="f jcc aic pt05 pb05 w80 border-radius bg-green-1">
                <div class="p4 green">{{item.status_post.title_status_post}}</div>
              </div>
              <div
                v-if="item.status_post.title_status_post === 'Chưa duyệt'"
                class="f jcc aic pt05 pb05 w80 border-radius bg-red-1">
                <div class="p4 red">{{item.status_post.title_status_post}}</div>
              </div>
              <div
                v-if="item.status_post.title_status_post === 'Đã gỡ'"
                class="f jcc aic pt05 pb05 w80 border-radius bg-orange-1">
                <div class="p4 orange">{{item.status_post.title_status_post}}</div>
              </div>
              <div
                v-if="item.status_post.title_status_post === 'Lưu nháp'"
                class="f jcc aic pt05 pb05 w80 border-radius bg-gray-2">
                <div class="p4 gray-1-text">{{item.status_post.title_status_post}}</div>
              </div> -->
              <div
                v-if="item.approved_status === 'draft'"
                class="f jcc aic pt05 pb05 ph05 w80 border-radius bg-gray-2"
              >
                <div class="p4 gray-1-text">Chưa duyệt</div>
              </div>

              <div
                v-if="item.approved_status === 'canceled'"
                class="f jcc aic pt05 pb05 ph05 w80 border-radius bg-red-1"
              >
                <div class="p4 gray-1-text">Đã huỷ</div>
              </div>
              <div
                v-if="item.approved_status === 'approved'"
                class="f jcc aic pt05 pb05 ph05 w80 border-radius bg-green-1"
              >
                <div class="p4 gray-1-text">Đã duyệt</div>
              </div>
            </div>
          </div>
          <div>
            <div class="post-key__item">
              <span class="gray-1-text h6">Ngày đăng: </span> {{ formatTime(item.created_at) }}
            </div>
            <div v-if="item.category[0]" class="post-key__item">
             <span class="gray-1-text h6">Danh mục: </span> {{ item.category[0].category_name || 'Không tìm thấy dữ liệu'}}
            </div>
            <div class="post-key__item"><span class="gray-1-text h6">Lượt xem: </span>{{ item.total_click }}</div>
            <div class="post-key__item"><span class="gray-1-text h6">Lượt chia sẻ: </span>{{ item.total_share }}</div>
          </div>
        </div>
      </router-link>
    </template>
  </div>
  <div v-else class="w100">
    <!-- title landing -->
    <div
      v-if="$route.params.path === 'quan-ly-funnel'"
      class="header w100 f aic p5 p5 pt1 pb1 pr1 pl1"
    >
      <div class="index-grid f aic jcb" style="width: 5%">
        <div class="p5 align-c">STT</div>
      </div>
      <div class="title-key__grid w100 pl5">
        <div class="title-key__item">Tên trang Landing</div>
        <div class="title-key__item">Ngày khởi tạo</div>
        <div class="title-key__item">Lượt phản hồi</div>
        <div class="title-key__item">Người khởi tạo</div>
        <div class="title-key__item"></div>
      </div>
    </div>

    <!-- title sản phẩm -->
    <div
      v-else-if="$route.params.path === 'quan-ly-product'"
      class="header w100 f aic p5 p5 pt1 pb1 pr1 pl1"
    >
      <div class="index-grid f aic jcb" style="width: 5%">
        <div class="p5 align-c">STT</div>
      </div>
      <div class="product-key__grid w100 pl5">
        <div class="product-key__item">Sản phẩm</div>
        <div class="product-key__item">Loại sản phẩm</div>
        <div class="product-key__item">Giá sản phẩm</div>
        <div class="product-key__item">Giá khuyến mãi</div>
        <div class="product-key__item">Tồn kho</div>
        <div class="product-key__item">Đơn hàng đã bán</div>
        <div class="product-key__item">Trạng thái</div>
      </div>
    </div>

    <!-- title bài viết -->
    <div
      v-else-if="$route.params.path === 'quan-ly-post'"
      class="header w100 f aic p5 p5 pt1 pb1 pr1 pl1"
    >
      <div class="index-grid f aic jcb" style="width: 5%">
        <div class="p5 align-c">STT</div>
      </div>
      <div class="post-key__grid w100 pl5">
        <div class="post-key__item">Tiêu đề bài viết</div>
        <div class="post-key__item">Ngày khởi tạo</div>
        <div class="post-key__item">Chuyên mục</div>
        <div class="post-key__item">Lượt xem</div>
        <div class="post-key__item">Lượt chia sẻ</div>
        <div class="post-key__item">Trạng thái</div>
      </div>
    </div>

    <!-- title -->
    <div v-else class="header w100 f aic p5 p5 pt1 pb1 pr1 pl1">
      <div class="f aic jcb" style="width: 5%">
        <div class="p5 align-c">STT</div>
      </div>
      <div v-for="(item, key) in data.props.listfield" :key="key">
        <div
          class="title-form__item align-c p5 qcont"
          v-bind:style="{ width: wid + 'px' }"
          v-if="
            key == 'time_published' ||
            key == 'category' ||
            key == 'total_click' ||
            key == 'total_share' ||
            key == 'status_post'
          "
        >
          {{ item.text }}
        </div>
        <div
          class="title-form__item align-c p5 qcont"
          v-bind:style="{ width: wid + 'px' }"
          v-else-if="key == 'title_post'"
        >
          {{ item.text }}
        </div>
      </div>
    </div>

    <!-- body item san phẩm -->
    <div v-if="$route.params.path === 'quan-ly-product'" class="body">
      <router-link
        :to="{ name: 'body-detail', params: { id: item.id } }"
        v-for="(item, index) in dataProps"
        :key="index"
        class="item-list w100 f aic p5 pt1 pb1 pr1 pl1 mb1 border-radius bg-white-1"
      >
        <div class="index-grid f aic jcb" style="width: 5%">
          <div class="p5 align-c">{{ index + 1 }}</div>
        </div>
        <div v-if="item.type_product" class="item-product__grid w100 pl5">
          <div class="item-product__item">
            <div class="f aic">
              <img :src="item.media[0].file_media" alt="" />
              <div class="ml075 f fdc jcb">
                <div class="h7 two-line">{{ item.title_product }}</div>
                <div class="h6 mt025 gray-1-text">{{ item.sku }}</div>
              </div>
            </div>
          </div>
          <div class="item-product__item">
            {{ item.type_product.title_type_product }}
          </div>
          <div class="item-product__item">
            {{ formatMoney(item.price_product) }}
          </div>
          <div class="item-product__item brown p4">
            {{ formatMoney(item.price_sale) }}
          </div>
          <div class="item-product__item">
            {{ item.inventory }} {{ item.unit }}
          </div>
          <div class="item-product__item">
            {{ item.inventory }} {{ item.unit }}
          </div>
          <div class="item-product__item">
            <div class="pt05 pb05 w80 p4 border-radius bg-green-1 green">
              Đang bán
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <!-- body item landing -->
    <div v-else-if="$route.params.path === 'quan-ly-funnel'" class="body">
      <div
        v-for="(item, index) in dataProps"
        :key="index"
        class="item-list w100 f aic p5 pt1 pb1 pr1 pl1 mb1 border-radius bg-white-1"
      >
        <div class="index-grid f aic jcb" style="width: 5%">
          <div class="p5 align-c">{{ index + 1 }}</div>
        </div>
        <div class="item-key__grid w100 pl5">
          <div class="item-key__item">
            {{ item.title_landing }}
          </div>
          <div class="item-key__item">
            {{ formatTime(item.created_at) }}
          </div>
          <div class="item-key__item">Chưa có</div>
          <div class="item-key__item">
            {{ item.user_created }}
          </div>
          <router-link
            :to="{ name: 'body-detail', params: { id: item.id } }"
            style="text-align: center"
            class="item-key__item align-r blue h7 cursor"
          >
            Xem chi tiết
          </router-link>
        </div>
      </div>
    </div>

    <!-- body item post -->
    <div v-else-if="$route.params.path === 'quan-ly-post'" class="body">
      <router-link
        :to="{ name: 'body-detail', params: { id: item.id } }"
        v-for="(item, index) in dataProps"
        :key="index"
        class="post-list w100 f aic p5 pt1 pb1 pr1 pl1 mb1 border-radius bg-white-1"
      >
        <div class="index-grid f aic jcb" style="width: 5%">
          <div class="p5 align-c">{{ index + 1 }}</div>
        </div>
        <div class="post-key__grid w100 pl5 aic">
          <div class="post-key__item f aic relative">
            <template v-if="item.pin">
              <img src="@/assets/icons/pin.svg" alt="" class="absolute" style="width: 12px; height: auto; top: 0; right: 0; border: none;" />
            </template>
            <div class="mr1">
              <img :src="item.media[0].file_media" alt="" />
            </div>
            <div class="f fdc ais jcb">
              <div class="p4 blue mb025">{{ item.title_post }}</div>
              <div class="h6 gray-1-text">
                Tạo bởi:
                <span class="p4 black">{{ item.user_created.username }}</span>
              </div>
            </div>
          </div>
          <div class="post-key__item">
            {{ formatTime(item.created_at) }}
          </div>
          <div v-if="item.category[0]" class="post-key__item p4 gray-1-text">
            {{ item.category[0].category_name }}
          </div>

          <div v-else class="post-key__item p4 gray-1-text">
            chưa cập nhật
          </div>

          <div class="post-key__item">{{ item.total_click }} lượt</div>
          <div class="post-key__item">{{ item.total_share }} lượt</div>
          <div
            v-if="item.approved_status"
            class="post-key__item blue h7 f jcc aic cursor"
          >
            <!-- <div
                v-if="item.status_post.title_status_post === 'Đã đăng'"
                class="f jcc aic pt05 pb05 w80 border-radius bg-green-1">
                <div class="p4 green">{{item.status_post.title_status_post}}</div>
              </div>
              <div
                v-if="item.status_post.title_status_post === 'Chưa duyệt'"
                class="f jcc aic pt05 pb05 w80 border-radius bg-red-1">
                <div class="p4 red">{{item.status_post.title_status_post}}</div>
              </div>
              <div
                v-if="item.status_post.title_status_post === 'Đã gỡ'"
                class="f jcc aic pt05 pb05 w80 border-radius bg-orange-1">
                <div class="p4 orange">{{item.status_post.title_status_post}}</div>
              </div>
              <div
                v-if="item.status_post.title_status_post === 'Lưu nháp'"
                class="f jcc aic pt05 pb05 w80 border-radius bg-gray-2">
                <div class="p4 gray-1-text">{{item.status_post.title_status_post}}</div>
              </div> -->
            <div
              v-if="item.approved_status === 'draft'"
              class="f jcc aic pt05 pb05 w80 border-radius bg-gray-2"
            >
              <div class="p4 gray-1-text">Chưa duyệt</div>
            </div>
            <div
                v-if="item.approved_status === 'canceled'"
                class="f jcc aic pt05 pb05 ph05 w80 border-radius bg-red-1"
              >
                <div class="p4 gray-1-text">Đã huỷ</div>
              </div>
            <div
              v-if="item.approved_status === 'approved'"
              class="f jcc aic pt05 pb05 w80 border-radius bg-green-1"
            >
              <div class="p4 gray-1-text">Đã duyệt</div>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <!-- body item -->
    <div v-else class="body">
      <div
        v-for="(item, index) in dataProps"
        :key="index"
        class="item-list w100 f aic p5 pt1 pb1 pr1 pl1 mb1 border-radius bg-white-1"
      >
        <div class="f aic jcb" style="width: 5%">
          <div class="p5 align-c">{{ index + 1 }}</div>
        </div>
        <div v-for="(item, key) in item" :key="key" class="align-c h6 gray">
          <div
            v-bind:style="{ width: wid + 'px' }"
            v-if="
              key == 'time_published' ||
              key == 'category' ||
              key == 'total_click' ||
              key == 'total_share' ||
              key == 'status_post'
            "
          >
            {{ item }}
          </div>

          <div
            v-bind:style="{ width: wid + 'px' }"
            v-else-if="key == 'title_post'"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleSelect: String,
    dataProps: {
      type: Array,
      default() {
        return [];
      },
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    function: { type: Function },
    handleInfo: { type: Function },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataSelect: [],
      wid: "",
    };
  },
  watch: {
    titleSelect: function () {
      this.dataSelect = [];
      this.handleFilter();
      setTimeout(() => {
        this.function();
        this.handleInfo();
      }, 1);
    },
  },
  methods: {
    formatTime(value) {
      const time = value.split("T");

      if (this.$route.params.path === "quan-ly-post") {
        return time[0];
      }

      time[1] = time[1].split("Z");

      return time[0];
    },

    formatMoney(value) {
      var x = value;
      if (x !== undefined) {
        x = x.toLocaleString("it-IT", { style: "currency", currency: "VND" });
        return x;
      }
    },

    handleStatus() {
      var title = document.getElementsByClassName("title-form__item");
      this.wid = 1320 / title.length;
    },
    handleFilter() {
      if (this.titleSelect == "Tất cả") {
        for (let i = 0; i < this.data.length; i++) {
          this.dataSelect.push(this.data[i]);
        }
      } else {
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i]["Trạng thái"] == this.titleSelect) {
            this.dataSelect.push(this.data[i]);
          }
        }
      }
    },
  },
  created() {
    setTimeout(() => {
      this.handleStatus();
    }, 1000);
  },
};
</script>

<style scoped lang="scss">
@import "Item.scss";
</style>