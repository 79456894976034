@<template>
    <div class="detail__container">
        <DetailCustomer 
            v-if="$route.params.path === 'quan-ly-customer'"
            :dataField='dataField'/>

        <DetailPostAdmin 
            v-if="$route.params.path === 'quan-ly-post'"
            :isMobile="$attrs.isMobile"
        />

        <DetailUser 
            v-if="$route.params.path === 'quan-ly-user'"/>

        <DetailOrderAdmin 
            v-if="$route.params.path === 'quan-ly-order'"/>

        <DetailProductAdmin 
            v-if="$route.params.path === 'quan-ly-product'"/>

        <DetailLanding 
            v-if="$route.params.path === 'quan-ly-funnel'"
            :dataField='dataField'/>

        <CourseUnitDetailPage
            v-if="
                $route.params.path.includes('course-unit')
            "
        />
        <CourseDetailPage
            v-else-if="
                $route.params.path.includes('course')
            "
        />
    </div>
</template>

<script>
import DetailCustomer from '@/components/CustomerView/DetailCustomer/DetailCustomerView.vue'
import DetailPostAdmin from './DetailPostAdmin/DetailPostAdmin.vue'
import DetailUser from './DetailUserAdmin/DetailUser.vue'
import DetailOrderAdmin from './DetailOrderAdmin/DetailOrderAdmin.vue'
import DetailProductAdmin from './DetailProductAdmin/DetailProductAdmin.vue'
import DetailLanding from './DetailLanding/DetailLanding.vue'
import CourseDetailPage from '@/components/Course/CourseDetailPage.vue'
import CourseUnitDetailPage from '@/components/Course/CourseUnitDetailPage.vue'

export default {
    props : {
        dataField : {
            type : Object,
            default() {
                return {}
            }
        },
    },

    components : {
        DetailCustomer,
        DetailPostAdmin,
        DetailUser,
        DetailOrderAdmin,
        DetailProductAdmin,
        DetailLanding,
        CourseDetailPage,
        CourseUnitDetailPage,
    }
}
</script>

<style scoped lang='scss'>
    @import 'DetailItem.scss';
</style>